import { Chip, Skeleton } from "@mantine/core";
import "@mantine/core/styles.css";
import React from "react";
import { determinePadding } from "../orchestry-chip/orchestry-chip.utils";
import { OrchestrySVGIcon } from "../orchestry-svg-icon/orchestry-svg-icon.component";
/**
 * A component that displays a badge either with an icon and a title or with an SVG icon.
 * Different than a Chip because the badge is always selected.
 */
export var OrchestryBadge = function (_a) {
    var _b;
    var onClick = _a.onClick, icon = _a.icon, svgIcon = _a.svgIcon, title = _a.title, value = _a.value, size = _a.size, isLoading = _a.isLoading, children = _a.children, color = _a.color, svgSize = _a.svgSize, fontWeight = _a.fontWeight, lineHeight = _a.lineHeight, skeletonWidth = _a.skeletonWidth;
    var onBadgeClicked = function () { return value !== undefined && onClick && onClick(value); };
    var isClickable = !!onClick;
    if (isLoading)
        return React.createElement(Skeleton, { height: 24, width: skeletonWidth !== null && skeletonWidth !== void 0 ? skeletonWidth : 85, radius: 16 });
    return (React.createElement(Chip, { fw: fontWeight, lh: lineHeight, color: color, wrapperProps: {
            "data-padding": determinePadding(Boolean(icon), false, false),
            "data-size": size !== null && size !== void 0 ? size : "sm",
            "data-clickable": isClickable
        }, variant: color ? "light" : value, icon: React.createElement(React.Fragment, null), checked: true, onClick: onBadgeClicked },
        icon && React.createElement("i", { className: icon }),
        !!svgIcon && React.createElement(OrchestrySVGIcon, { size: svgSize, iconNeutral: (_b = svgIcon === null || svgIcon === void 0 ? void 0 : svgIcon.iconNeutral) !== null && _b !== void 0 ? _b : "", iconSelected: svgIcon === null || svgIcon === void 0 ? void 0 : svgIcon.iconSelected }),
        title,
        children));
};
