import { Group, Popover, Skeleton, Stack, Text } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryButton } from "appv2/components/orchestry-button/orchestry-button.component";
import { OrchestryDateFilter } from "appv2/components/orchestry-date-filter/orchestry-date-filter.component";
import { DATE_RANGE_FULLY_SELECTED_ITEMS_COUNT } from "appv2/components/orchestry-date-picker-input/orchestry-date-picker-input.component";
import { OrchestryClosableFilterCount } from "appv2/components/orchestry-filter-selector/orchestry-filter-count/orchestry-closable-filter-count.component";
import { OrchestryFilterCount } from "appv2/components/orchestry-filter-selector/orchestry-filter-count/orchestry-filter-count.component";
import { OrchestryFilterItems } from "appv2/components/orchestry-filter-selector/orchestry-filter-items/orchestry-filter-items.component";
import { useOrchestryFilterSelector } from "appv2/components/orchestry-filter-selector/use-orchestry-filter-selector.hook";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import "./orchestry-tasks-filter-selector.scss";
export var OrchestryTasksFilterSelector = React.forwardRef(function (props, ref) {
    var onApply = props.onApply, onClear = props.onClear, isLoading = props.isLoading, taskTypeFilters = props.taskTypeFilters, actionFilters = props.actionFilters, selectedDate = props.selectedDate, onSelectedDateChange = props.onSelectedDateChange, customDateRange = props.customDateRange, onDateRangePickerChange = props.onDateRangePickerChange, onClearActionFilters = props.onClearActionFilters, onClearTaskTypeFilters = props.onClearTaskTypeFilters, onClearCreatedDateFilter = props.onClearCreatedDateFilter;
    var intl = useIntl();
    var _a = useState(false), showPopover = _a[0], setShowPopover = _a[1];
    var _b = useOrchestryFilterSelector(taskTypeFilters), selectorTaskTypeFilters = _b.filters, selectorSetTaskTypeFilters = _b.setFilters, taskTypeFilterSelectedCount = _b.selectedCount, updateSelectorTaskTypeFilterState = _b.updateFilterState, taskTypeOnClearAllButtonClicked = _b.onClearAllButtonClicked, taskTypeFilterGroups = _b.filterGroups;
    var _c = useOrchestryFilterSelector(actionFilters), selectorActionFilters = _c.filters, selectorSetActionFilters = _c.setFilters, actionFilterSelectedCount = _c.selectedCount, updateSelectorActionFilterState = _c.updateFilterState, actionOnClearAllButtonClicked = _c.onClearAllButtonClicked, actionFilterGroups = _c.filterGroups;
    var createdDateFilterCount = customDateRange.filter(function (f) { return !!f; }).length < DATE_RANGE_FULLY_SELECTED_ITEMS_COUNT ? 0 : 1;
    var filterCount = taskTypeFilterSelectedCount + actionFilterSelectedCount + createdDateFilterCount;
    useEffect(function () {
        selectorSetTaskTypeFilters(taskTypeFilters);
    }, [selectorSetTaskTypeFilters, taskTypeFilters]);
    useEffect(function () {
        selectorSetActionFilters(actionFilters);
    }, [selectorSetActionFilters, actionFilters]);
    if (isLoading) {
        return React.createElement(Skeleton, { height: 42, width: 98 });
    }
    return (React.createElement("div", { ref: ref },
        React.createElement(Popover, { shadow: "xl", position: "bottom-end", opened: showPopover, onChange: function (opened) { return setShowPopover(opened); } },
            React.createElement(Popover.Target, null,
                React.createElement("div", null,
                    React.createElement(OrchestryButton, { className: "orchestry-tasks-filter-selector-button", size: "sm", onClick: function () { return setShowPopover(function (show) { return !show; }); }, variant: "filter" },
                        React.createElement(Text, { size: "sm" }, intl.formatMessage({ id: "controls.filters", defaultMessage: "Filters" })),
                        React.createElement(OrchestryFilterCount, { count: filterCount }),
                        React.createElement("i", { className: ORCH_ICONS.filterFunnel02 })))),
            React.createElement(Popover.Dropdown, { className: "orchestry-tasks-filter-selector-content" },
                React.createElement(Stack, { gap: 8 },
                    React.createElement(Group, { gap: 8, h: 28 },
                        React.createElement(Text, { fz: "14px", lh: "18px", fw: "500", c: "text-secondary.0" }, intl.formatMessage({ id: "pages.home.my_tasks.filters.type", defaultMessage: "Type" })),
                        React.createElement(OrchestryClosableFilterCount, { count: taskTypeFilterSelectedCount, onClick: function () {
                                taskTypeOnClearAllButtonClicked();
                                onClearTaskTypeFilters();
                            } })),
                    React.createElement(OrchestryFilterItems, { hideGroupHeaders: true, filters: selectorTaskTypeFilters, onClick: updateSelectorTaskTypeFilterState, groups: taskTypeFilterGroups })),
                React.createElement(Stack, { gap: 8 },
                    React.createElement(Group, { justify: "space-between", mr: 8 },
                        React.createElement(Group, { gap: 8, h: 28 },
                            React.createElement(Text, { fz: "14px", lh: "18px", fw: "500", c: "text-secondary.0" }, intl.formatMessage({ id: "pages.home.my_tasks.filters.action", defaultMessage: "Action" })),
                            React.createElement(OrchestryClosableFilterCount, { count: actionFilterSelectedCount, onClick: function () {
                                    actionOnClearAllButtonClicked();
                                    onClearActionFilters();
                                } }))),
                    React.createElement(OrchestryFilterItems, { hideGroupHeaders: true, filters: selectorActionFilters, onClick: updateSelectorActionFilterState, groups: actionFilterGroups })),
                React.createElement(Stack, { gap: 8 },
                    React.createElement(Group, { gap: 8, h: 28 },
                        React.createElement(Text, { fz: "14px", lh: "18px", fw: "500", c: "text-secondary.0" }, intl.formatMessage({ id: "pages.home.my_tasks.filters.date_created", defaultMessage: "Date Created" })),
                        React.createElement(OrchestryClosableFilterCount, { count: createdDateFilterCount, onClick: onClearCreatedDateFilter })),
                    React.createElement(OrchestryDateFilter, { isLoading: isLoading, selectedDate: selectedDate, onSelectedDateChange: onSelectedDateChange, customDateRange: customDateRange, onDateRangePickerChange: onDateRangePickerChange })),
                React.createElement("div", { className: "footer" },
                    React.createElement(OrchestryButton, { variant: "tertiary", onClick: function () {
                            taskTypeOnClearAllButtonClicked();
                            actionOnClearAllButtonClicked();
                            onClear();
                        } }, intl.formatMessage({ id: "controls.clear_all", defaultMessage: "Clear All" })),
                    React.createElement(OrchestryButton, { variant: "primary", onClick: function () {
                            onApply(selectorTaskTypeFilters, selectorActionFilters);
                            setShowPopover(false);
                        } }, intl.formatMessage({ id: "controls.apply", defaultMessage: "Apply" })))))));
});
