var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import clsx from "clsx";
import { useMantineReactTable } from "mantine-react-table";
import React from "react";
import { OrchestryFontIcon } from "../orchestry-font-icon/orchestry-font-icon.component";
import styles from "./orchestry-data-table.module.scss";
export function useCustomMantineReactTable(tableOptions, isEmpty) {
    var _a;
    if (isEmpty === void 0) { isEmpty = false; }
    var defaultStyles = {
        mantinePaperProps: {
            className: styles.orchestry_data_table__paper
        },
        mantineTableHeadCellProps: { className: styles.orchestry_data_table__table_head_cell },
        mantineTableBodyRowProps: { className: styles.orchestry_data_table__table_body_row },
        mantineTableBodyCellProps: { className: styles.orchestry_data_table__table_body_cell },
        mantineTableContainerProps: {
            className: clsx(styles.orchestry_data_table__table_container, (_a = {}, _a[styles.orchestry_data_table__table_container_no_rows] = isEmpty, _a))
        }
    };
    var defaultOptions = {
        enableColumnActions: false,
        enableColumnFilters: false,
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        enablePagination: false,
        enableRowActions: false,
        enableBottomToolbar: false,
        enableColumnOrdering: false,
        enableTopToolbar: false,
        manualSorting: true,
        enableStickyHeader: true,
        //Only needed if a table has over 12 columns.
        //enableColumnVirtualization: true,
        //These were causing a bug with the partner tools table using mantineTableBodyRowProps.
        //But removing the virtualization, the tables still seem pretty fast. So disabling for now.
        // and enabling on an individual basis.
        //Only needed if a table has well over 100 rows.
        //enableRowVirtualization: true,
        //rowVirtualizerOptions: { overscan: 12 },
        columns: [],
        data: []
    };
    var icons = {
        IconSortAscending: function (props) { return (React.createElement(OrchestryFontIcon, __assign({ onClick: function () { }, fontWeight: 900, fontSize: 16, lineHeight: "16px", color: "var(--mantine-color-gray-5)", iconClassName: ORCH_ICONS.arrowUp }, props))); },
        IconSortDescending: function (props) { return (React.createElement(OrchestryFontIcon, __assign({ onClick: function () { }, fontWeight: 900, fontSize: 16, lineHeight: "16px", color: "var(--mantine-color-gray-5)", iconClassName: ORCH_ICONS.arrowDown }, props))); },
        IconArrowsSort: function (props) { return (React.createElement(OrchestryFontIcon, __assign({ onClick: function () { }, fontWeight: 900, fontSize: 16, lineHeight: "16px", color: "var(--mantine-color-gray-5)", iconClassName: ORCH_ICONS.chevronsUpDown }, props))); }
    };
    var mergedProps = __assign(__assign(__assign({}, defaultOptions), tableOptions), { mantineTableBodyCellProps: __assign(__assign({}, defaultStyles.mantineTableBodyCellProps), tableOptions.mantineTableBodyCellProps), mantinePaperProps: __assign(__assign({}, defaultStyles.mantinePaperProps), tableOptions.mantinePaperProps), mantineTableHeadCellProps: __assign(__assign({}, defaultStyles.mantineTableHeadCellProps), tableOptions.mantineTableHeadCellProps), 
        // the default styles mantineTableBodyRowProps does not have a paramter, but the tableOptions.mantineTableBodyRowProps does.
        // and we do not know the type of the parameter here.
        mantineTableBodyRowProps: function (params) { return (__assign(__assign({}, defaultStyles.mantineTableBodyRowProps), (typeof tableOptions.mantineTableBodyRowProps === "function" ? tableOptions.mantineTableBodyRowProps(params) : {}))); }, mantineTableContainerProps: __assign(__assign({}, defaultStyles.mantineTableContainerProps), tableOptions.mantineTableContainerProps), icons: icons });
    return useMantineReactTable(mergedProps);
}
