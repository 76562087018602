import { Accordion, Group, Popover, Skeleton, Text } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryFilterCount } from "appv2/components/orchestry-filter-selector/orchestry-filter-count/orchestry-filter-count.component";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { OrchestryAccordion } from "../../components/orchestry-accordion/orchestry-accordion.component";
import { OrchestryButton } from "../../components/orchestry-button/orchestry-button.component";
import { DATE_RANGE_FULLY_SELECTED_ITEMS_COUNT, OrchestryDatePickerInput } from "../../components/orchestry-date-picker-input/orchestry-date-picker-input.component";
import { OrchestryFilterItems } from "../../components/orchestry-filter-selector/orchestry-filter-items/orchestry-filter-items.component";
import { useOrchestryFilterSelector } from "../../components/orchestry-filter-selector/use-orchestry-filter-selector.hook";
import "./partner-tools-filter-selector.scss";
export var PartnerToolsFilterSelector = React.forwardRef(function (props, ref) {
    var installDateFilter = props.installDateFilter, renewalDateFilter = props.renewalDateFilter, onInstallDateChange = props.onInstallDateChange, onRenewalDateChange = props.onRenewalDateChange, onApply = props.onApply, onClear = props.onClear, isLoading = props.isLoading, licenseFilters = props.licenseFilters, insightFilters = props.insightFilters;
    var intl = useIntl();
    var _a = useState(false), showPopover = _a[0], setShowPopover = _a[1];
    var _b = useOrchestryFilterSelector(licenseFilters), selectorLicenseFilters = _b.filters, selectorSetLicenseFilters = _b.setFilters, licenseFilterSelectedCount = _b.selectedCount, updateSelectorLicenseFilterState = _b.updateFilterState, licenseOnClearAllButtonClicked = _b.onClearAllButtonClicked, licenseFilterGroups = _b.filterGroups;
    var _c = useOrchestryFilterSelector(insightFilters), selectorInsightFilters = _c.filters, selectorSetInsightFilters = _c.setFilters, insightFilterSelectedCount = _c.selectedCount, updateSelectorInsightFilterState = _c.updateFilterState, insightOnClearAllButtonClicked = _c.onClearAllButtonClicked, insightFilterGroups = _c.filterGroups;
    var installDateFilterCount = installDateFilter.filter(function (f) { return !!f; }).length < DATE_RANGE_FULLY_SELECTED_ITEMS_COUNT ? 0 : 1;
    var renewalDateFilterCount = renewalDateFilter.filter(function (f) { return !!f; }).length < DATE_RANGE_FULLY_SELECTED_ITEMS_COUNT ? 0 : 1;
    var filterCount = licenseFilterSelectedCount + installDateFilterCount + renewalDateFilterCount + insightFilterSelectedCount;
    useEffect(function () {
        selectorSetLicenseFilters(licenseFilters);
    }, [selectorSetLicenseFilters, licenseFilters]);
    useEffect(function () {
        selectorSetInsightFilters(insightFilters);
    }, [selectorSetInsightFilters, insightFilters]);
    if (isLoading) {
        return React.createElement(Skeleton, { height: 42, width: 98 });
    }
    return (React.createElement("div", { ref: ref },
        React.createElement(Popover, { shadow: "xl", position: "bottom-end", opened: showPopover, onChange: function (opened) { return setShowPopover(opened); }, trapFocus: false, closeOnEscape: false, closeOnClickOutside: false },
            React.createElement(Popover.Target, null,
                React.createElement("div", null,
                    React.createElement(OrchestryButton, { className: "partner-tools-filter-selector-button", size: "sm", onClick: function () { return setShowPopover(function (show) { return !show; }); }, variant: "filter" },
                        React.createElement(Text, { size: "sm" }, "Filters"),
                        React.createElement(OrchestryFilterCount, { count: filterCount }),
                        React.createElement("i", { className: ORCH_ICONS.filterFunnel02 })))),
            React.createElement(Popover.Dropdown, { className: "partner-tools-filter-selector-content" },
                React.createElement(OrchestryAccordion, { defaultValue: "filters" },
                    React.createElement(Accordion.Item, { key: "license-filters", value: "license-filters" },
                        React.createElement(Accordion.Control, { ff: "lexend", fz: "14px", fw: 400, lh: "20px", c: "text-secondary.0" },
                            React.createElement(Group, { gap: 8 },
                                React.createElement(Text, { fz: "14px", lh: "18px", fw: "500" }, "License"),
                                React.createElement(OrchestryFilterCount, { variant: "gray", count: licenseFilterSelectedCount }))),
                        React.createElement(Accordion.Panel, null,
                            React.createElement(OrchestryFilterItems, { hideGroupHeaders: true, filters: selectorLicenseFilters, onClick: updateSelectorLicenseFilterState, groups: licenseFilterGroups }))),
                    React.createElement(Accordion.Item, { key: "installDate", value: "installDate" },
                        React.createElement(Accordion.Control, { ff: "lexend", fz: "14px", fw: 400, lh: "20px", c: "text-secondary.0" },
                            React.createElement(Group, { gap: 8 },
                                React.createElement(Text, { fz: "14px", lh: "18px", fw: "500" }, "Install Date"),
                                React.createElement(OrchestryFilterCount, { variant: "gray", count: installDateFilterCount }))),
                        React.createElement(Accordion.Panel, null,
                            React.createElement(OrchestryDatePickerInput, { type: "range", clearable: true, placeholder: intl.formatMessage({ id: "controls.date_input.range_placeholder", defaultMessage: "Select Date Range" }), value: installDateFilter, onChange: onInstallDateChange, closeOnChange: false, clearButtonProps: {
                                    onClick: function () {
                                        onInstallDateChange([]);
                                    }
                                } }))),
                    React.createElement(Accordion.Item, { key: "renewalDate", value: "renewalDate" },
                        React.createElement(Accordion.Control, { ff: "lexend", fz: "14px", fw: 400, lh: "20px", c: "text-secondary.0" },
                            React.createElement(Group, { gap: 8 },
                                React.createElement(Text, { fz: "14px", lh: "18px", fw: "500" }, "Renewal Date"),
                                React.createElement(OrchestryFilterCount, { variant: "gray", count: renewalDateFilterCount }))),
                        React.createElement(Accordion.Panel, null,
                            React.createElement(OrchestryDatePickerInput, { maxDate: undefined, type: "range", clearable: true, placeholder: intl.formatMessage({ id: "controls.date_input.range_placeholder", defaultMessage: "Select Date Range" }), value: renewalDateFilter, onChange: onRenewalDateChange, closeOnChange: false, clearButtonProps: {
                                    onClick: function () {
                                        onRenewalDateChange([]);
                                    }
                                } }))),
                    React.createElement(Accordion.Item, { key: "insight-filters", value: "insight-filters" },
                        React.createElement(Accordion.Control, { ff: "lexend", fz: "14px", fw: 400, lh: "20px", c: "text-secondary.0" },
                            React.createElement(Group, { gap: 8 },
                                React.createElement(Text, { fz: "14px", lh: "18px", fw: "500" }, "Insight"),
                                React.createElement(OrchestryFilterCount, { variant: "gray", count: insightFilterSelectedCount }))),
                        React.createElement(Accordion.Panel, null,
                            React.createElement(OrchestryFilterItems, { hideGroupHeaders: true, filters: selectorInsightFilters, onClick: updateSelectorInsightFilterState, groups: insightFilterGroups })))),
                React.createElement("div", { className: "footer" },
                    React.createElement(OrchestryButton, { variant: "tertiary", onClick: function () {
                            licenseOnClearAllButtonClicked();
                            insightOnClearAllButtonClicked();
                            onClear();
                        } }, intl.formatMessage({ id: "controls.clear_all", defaultMessage: "Clear All" })),
                    React.createElement(OrchestryButton, { variant: "primary", onClick: function () {
                            onApply(selectorLicenseFilters, installDateFilter, renewalDateFilter, selectorInsightFilters);
                            setShowPopover(false);
                        } }, intl.formatMessage({ id: "controls.apply", defaultMessage: "Apply" })))))));
});
