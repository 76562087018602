import { Portlet, PortletBody } from "app/components/common/portlet";
import Subtitle from "app/components/common/subtitle";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryActionButtons } from "appv2/components/orchestry-action-buttons/orchestry-action-buttons.component";
import { OrchestryBackButton } from "appv2/components/orchestry-back-button/orchestry-back-button.component";
import { OrchestryButton } from "appv2/components/orchestry-button/orchestry-button.component";
import { OrchestryCancelButton } from "appv2/components/orchestry-cancel-button/orchestry-cancel-button.component";
import { OrchestryCombobox } from "appv2/components/orchestry-combobox/orchestry-combobox.component";
import { OrchestryDatePickerInput } from "appv2/components/orchestry-date-picker-input/orchestry-date-picker-input.component";
import { OrchestryDivider } from "appv2/components/orchestry-divider/orchestry-divider.component";
import { OrchestryFormErrorText } from "appv2/components/orchestry-form-error-text/orchestry-form-error-text.component";
import { OrchestryPageHeader } from "appv2/components/orchestry-page-header/orchestry-page-header.component";
import { OrchestryPageTitle } from "appv2/components/orchestry-page-title/orchestry-page-title.component";
import { OrchestrySettingsPageControl } from "appv2/components/orchestry-settings-page-control/orchestry-settings-page-control.component";
import { OrchestryTextInput } from "appv2/components/orchestry-text-input/orchestry-text-input.component";
import React from "react";
import { HealthCheckNameDescription, HealthCheckStartDateDescription } from "./generate-new-health-check-descriptions.component";
import "./generate-new-health-check-page.scss";
import { HealthCheckStartDate } from "./health-check-start-date.type";
import { useGenerateNewHealthCheckPage } from "./use-generate-new-health-check-page.hook";
export var GenerateNewHealthCheckPage = function () {
    var _a = useGenerateNewHealthCheckPage(), intl = _a.intl, pageTitle = _a.pageTitle, pageSubTitle = _a.pageSubTitle, onBackButtonClicked = _a.onBackButtonClicked, form = _a.form, isGenerateDisabled = _a.isGenerateDisabled, isPageLoading = _a.isPageLoading, onCancelButtonClicked = _a.onCancelButtonClicked, handleFormSubmit = _a.handleFormSubmit, startDate = _a.startDate, onStartDateChange = _a.onStartDateChange, customDateRange = _a.customDateRange, onDateRangeChange = _a.onDateRangeChange, canGenerateHealthCheck = _a.canGenerateHealthCheck, installationDate = _a.installationDate;
    if (!canGenerateHealthCheck) {
        return null;
    }
    return (React.createElement(Portlet, null,
        React.createElement(PortletBody, null,
            React.createElement("div", { className: "generate-new-health-check-page" },
                React.createElement("form", { onSubmit: form.onSubmit(function (values) { return handleFormSubmit(values); }) },
                    React.createElement(Subtitle, { title: pageTitle, subtitle: pageSubTitle },
                        React.createElement(OrchestryBackButton, { onClick: onBackButtonClicked }, intl.formatMessage({
                            id: "healthcheck.go_back_to_health_checks",
                            defaultMessage: "Go Back to Health Checks"
                        }))),
                    React.createElement(OrchestryPageHeader, null,
                        React.createElement(OrchestryPageTitle, { isLoading: isPageLoading, title: intl.formatMessage({ id: "page.generate_new_health_check.title", defaultMessage: "Generate New Health Check" }), subTitle: intl.formatMessage({
                                id: "page.generate_new_health_check.subtitle",
                                defaultMessage: "Process and create a new ad-hoc (one time) snapshot to capture the environment at a point in time."
                            }) }),
                        React.createElement(OrchestryActionButtons, null,
                            React.createElement(OrchestryCancelButton, { isLoading: isPageLoading, onClick: onCancelButtonClicked }, intl.formatMessage({ id: "controls.cancel", defaultMessage: "Cancel" })),
                            React.createElement(OrchestryButton, { disabled: isGenerateDisabled, type: "submit", isLoading: isPageLoading, skeletonHeight: 34, skeletonWidth: 102, iconType: "font", size: "md", iconLeft: ORCH_ICONS.fileCheck02, variant: "primary" }, intl.formatMessage({ id: "controls.generate", defaultMessage: "Generate" })))),
                    React.createElement(OrchestrySettingsPageControl, { title: intl.formatMessage({ id: "page.generate_new_health_check.name.title", defaultMessage: "Health Check Name" }), description: HealthCheckNameDescription(intl) },
                        React.createElement(OrchestryTextInput, { isLoading: isPageLoading, onChange: function (e) {
                                form.setFieldValue("Name", e.currentTarget.value);
                            }, value: form.getInputProps("Name").value, placeholder: intl.formatMessage({ id: "page.generate_new_health_check.name.control.placeholder", defaultMessage: "Enter Health Check Name" }), c: "text-primary.0", fw: "300", fz: "14px", lh: "20px", error: form.getInputProps("Name").error })),
                    React.createElement(OrchestryDivider, null),
                    React.createElement(OrchestrySettingsPageControl, { title: intl.formatMessage({ id: "page.generate_new_health_check.start_date.title", defaultMessage: "Select Start Date" }), description: HealthCheckStartDateDescription(intl, installationDate) },
                        React.createElement("div", { className: "combobox-wrapper" },
                            React.createElement(OrchestryCombobox, { placeholder: "", options: [
                                    {
                                        value: HealthCheckStartDate["30DaysAgo"],
                                        label: intl.formatMessage({ id: "page.generate_new_health_check.start_date.dropdown.30DaysAgo", defaultMessage: "30 Days ago" })
                                    },
                                    {
                                        value: HealthCheckStartDate["60DaysAgo"],
                                        label: intl.formatMessage({ id: "page.generate_new_health_check.start_date.dropdown.60DaysAgo", defaultMessage: "60 Days ago" })
                                    },
                                    {
                                        value: HealthCheckStartDate["90DaysAgo"],
                                        label: intl.formatMessage({ id: "page.generate_new_health_check.start_date.dropdown.90DaysAgo", defaultMessage: "90 Days ago" })
                                    },
                                    {
                                        value: HealthCheckStartDate["1YearAgo"],
                                        label: intl.formatMessage({ id: "page.generate_new_health_check.start_date.dropdown.1YearAgo", defaultMessage: "1 Year ago" })
                                    },
                                    { value: HealthCheckStartDate.Custom, label: intl.formatMessage({ id: "page.generate_new_health_check.start_date.dropdown.custom", defaultMessage: "Custom" }) }
                                ], iconClosed: ORCH_ICONS.chevronDown, iconOpened: ORCH_ICONS.chevronUp, defaultValue: "", variant: "selected-value", onChange: function (value) { return onStartDateChange(value); }, value: startDate, isLoading: isPageLoading, skeletonWidth: 150 })),
                        startDate === HealthCheckStartDate.Custom && (React.createElement(OrchestryDatePickerInput, { isLoading: isPageLoading, type: "range", placeholder: intl.formatMessage({ id: "controls.date_input.range_placeholder", defaultMessage: "Select Date Range" }), value: customDateRange, onChange: onDateRangeChange, mt: 16, excludeDate: function (date) { return date.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0); } })),
                        React.createElement(OrchestryFormErrorText, { error: form.getInputProps("StartDate").error })))))));
};
