import { useFeatureValue } from "@growthbook/growthbook-react";
import RequestMy from "app/components/requests/request-my";
import RequestWizard from "app/components/requests/request-wizard";
import TeamConfiguration from "app/components/teams-tabs/team-configuration";
import TeamDirectory from "app/components/teams-tabs/team-directory";
import TeamGuestRequestWorkflow from "app/components/teams-tabs/team-guest-request-workflow";
import TeamGuestReviewWorkflow from "app/components/teams-tabs/team-guest-review-workflow";
import TeamInformation from "app/components/teams-tabs/team-information";
import TeamPolicyArchivalWorkflow from "app/components/teams-tabs/team-policy-archival-workflow";
import TeamProvisioningReviewWorkflow from "app/components/teams-tabs/team-provisioning-review-workflow";
import { HealthCheckReadyTeamsPage } from "appv2/pages/beacon/health-check-teams/health-check-ready-teams-page.component";
import { HealthCheckReviewTeamsPage } from "appv2/pages/beacon/health-check-teams/health-check-review-teams-page.component";
import { PartnerHealthCheckReadyTeamsPage } from "appv2/pages/beacon/health-check-teams/partner-health-check-ready-teams-page.component";
import { PartnerHealthCheckReviewTeamsPage } from "appv2/pages/beacon/health-check-teams/partner-health-check-review-teams-page.component";
import { HealthCheckPage } from "appv2/pages/beacon/health-check/health-check/health-check-page.component";
import { OrchestryTasksTeamPage } from "appv2/pages/home/tabs/orchestry-tasks-tab/orchestry-tasks-team-page.component";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useCanSeeHealthChecks } from "../health-check-pages/use-can-see-health-checks.hook";
// All these routes that have a / in the path after /tabs/page-name
// are duplicated with %2fs in the path. This is because the %2f is the encoded value of the / character
// and on android devices, microsoft teams will encode the / character in the url to %2f
// and android devices do not encode it to / so we need to have both routes to handle both cases
export default function TeamsTabs() {
    var isInTeams = React.useState(document.OrchestryIsInTeams)[0];
    var isInSharepoint = React.useState(document.OrchestryIsInSharePoint)[0];
    var enableMyTasksFeature = useFeatureValue("OE-4205_enable_my_tasks_feature", false);
    var canSeeHealthChecks = useCanSeeHealthChecks();
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: "/tabs" }, enableMyTasksFeature ? React.createElement(Redirect, { to: "/tabs/my-tasks" }) : React.createElement(Redirect, { to: "/" })),
        React.createElement(Route, { exact: true, path: "/tabs/health-check-ready/:healthCheckGuid" }, isInTeams ? React.createElement(HealthCheckReadyTeamsPage, null) : React.createElement(Redirect, { to: "/" })),
        React.createElement(Route, { exact: true, path: "/tabs/health-check-ready%2f:healthCheckGuid" }, isInTeams ? React.createElement(HealthCheckReadyTeamsPage, null) : React.createElement(Redirect, { to: "/" })),
        React.createElement(Route, { exact: true, path: "/tabs/health-check-review/:healthCheckGuid" }, isInTeams ? React.createElement(HealthCheckReviewTeamsPage, null) : React.createElement(Redirect, { to: "/" })),
        React.createElement(Route, { exact: true, path: "/tabs/health-check-review%2f:healthCheckGuid" }, isInTeams ? React.createElement(HealthCheckReviewTeamsPage, null) : React.createElement(Redirect, { to: "/" })),
        React.createElement(Route, { exact: true, path: "/tabs/partners/health-check-ready/:tenantGuid/health-checks/:healthCheckGuid" }, isInTeams ? React.createElement(PartnerHealthCheckReadyTeamsPage, null) : React.createElement(Redirect, { to: "/" })),
        React.createElement(Route, { exact: true, path: "/tabs/partners%2fhealth-check-ready%2f:tenantGuid%2fhealth-checks%2f:healthCheckGuid" }, isInTeams ? React.createElement(PartnerHealthCheckReadyTeamsPage, null) : React.createElement(Redirect, { to: "/" })),
        React.createElement(Route, { exact: true, path: "/tabs/partners/health-check-review/:tenantGuid/health-checks/:healthCheckGuid" }, isInTeams ? React.createElement(PartnerHealthCheckReviewTeamsPage, null) : React.createElement(Redirect, { to: "/" })),
        React.createElement(Route, { exact: true, path: "/tabs/partners%2fhealth-check-review%2f:tenantGuid%2fhealth-checks%2f:healthCheckGuid" }, isInTeams ? React.createElement(PartnerHealthCheckReviewTeamsPage, null) : React.createElement(Redirect, { to: "/" })),
        enableMyTasksFeature && (React.createElement(Route, { exact: true, path: "/tabs/my-tasks" },
            React.createElement(OrchestryTasksTeamPage, null))),
        React.createElement(Route, { exact: true, path: "/tabs/team-information" },
            React.createElement(TeamInformation, null)),
        React.createElement(Route, { exact: true, path: "/tabs/team-configuration" }, isInTeams ? React.createElement(TeamConfiguration, null) : React.createElement(Redirect, { to: "/" })),
        React.createElement(Route, { exact: true, path: "/tabs/workspace-directory" }, isInTeams || isInSharepoint ? React.createElement(TeamDirectory, null) : React.createElement(Redirect, { to: "/" })),
        React.createElement(Route, { exact: true, path: "/tabs/workspace-request" }, isInTeams || isInSharepoint ? React.createElement(RequestWizard, null) : React.createElement(Redirect, { to: "/" })),
        React.createElement(Route, { exact: true, path: "/tabs/my-workspace-requests" }, isInTeams ? React.createElement(RequestMy, { loadInitialData: false }) : React.createElement(Redirect, { to: "/" })),
        React.createElement(Route, { exact: true, path: "/tabs/archival-workflow/:siteId/:action?", component: TeamPolicyArchivalWorkflow }),
        React.createElement(Route, { exact: true, path: "/tabs/guest-review-workflow/:siteId", component: TeamGuestReviewWorkflow }),
        React.createElement(Route, { exact: true, path: "/tabs/guest-request-workflow/:siteId", component: TeamGuestRequestWorkflow }),
        React.createElement(Route, { exact: true, path: "/tabs/requests/view/:guid", component: TeamProvisioningReviewWorkflow }),
        canSeeHealthChecks ? React.createElement(Route, { exact: true, path: "/tabs/health-checks/view/:healthCheckGuid", component: HealthCheckPage }) : React.createElement(Redirect, { to: "/" })));
}
