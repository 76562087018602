import { Card, Group, Stack } from "@mantine/core";
import { convertUtcDatetimeToLocal, getReadableDateFromNow } from "app/utils/dateUtils";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryAvatar } from "appv2/components/orchestry-avatar/orchestry-avatar.component";
import { OrchestryBadge } from "appv2/components/orchestry-badge/orchestry-badge.component";
import { OrchestryNewBadge } from "appv2/components/orchestry-badge/orchestry-new-badge.component";
import { OrchestryButton } from "appv2/components/orchestry-button/orchestry-button.component";
import { OrchestryHighlight } from "appv2/components/orchestry-highlight/orchestry-highlight.component";
import { OrchestryText } from "appv2/components/orchestry-text/orchestry-text.component";
import { OrchestryWorkspaceIcon } from "appv2/components/orchestry-workspace-icon/orchestry-workspace-icon.component";
import React from "react";
import { useOrchestryTaskCard } from "./use-orchestry-task-card";
export var OrchestryTaskCard = function (_a) {
    var _b;
    var task = _a.task, isLoading = _a.isLoading, highlightSearchWords = _a.highlightSearchWords;
    var _c = useOrchestryTaskCard(task), intl = _c.intl, label = _c.label, actionButtonText = _c.actionButtonText, badgeText = _c.badgeText, badgeIcon = _c.badgeIcon, badgeColor = _c.badgeColor, isNew = _c.isNew, onActionButtonClick = _c.onActionButtonClick;
    return (React.createElement(Card, { mih: "305px", style: { borderRadius: "12px" }, variant: "shadow-border", p: 24 },
        React.createElement(Stack, { gap: 16 },
            React.createElement(Stack, { gap: 8 },
                React.createElement(Group, { justify: "space-between" },
                    React.createElement(OrchestryBadge, { isLoading: isLoading, color: badgeColor, icon: badgeIcon }, badgeText),
                    isNew && React.createElement(OrchestryNewBadge, null)),
                React.createElement(OrchestryText, { mt: isLoading ? "8px" : 0, isLoading: isLoading, c: "var(--mantine-color-text-primary-0)", size: "16px", fw: 500, lh: "24px" }, label)),
            React.createElement(Stack, { gap: 8, h: "60px" },
                React.createElement(OrchestryText, { mt: isLoading ? "2px" : 0, isLoading: isLoading, c: "var(--mantine-color-text-tertiary-0)", size: "11px", fw: 400, lh: "11px" }, intl.formatMessage({ id: "pages.home.my_tasks.card.target", defaultMessage: "Target" })),
                React.createElement(Group, { gap: 8, wrap: "nowrap", align: "flex-start" },
                    React.createElement(OrchestryWorkspaceIcon, { customSize: "20px", borderRadius: "4px", svgSize: "md", isLoading: isLoading, variant: "20px", icon: task.TargetIcon, workspaceName: task.TargetName }),
                    React.createElement(OrchestryHighlight, { overFlowEllipsisNthLine: 2, isLoading: isLoading, c: "var(--mantine-color-text-primary-0)", size: "14px", fw: 500, lh: "20px", highlight: highlightSearchWords !== null && highlightSearchWords !== void 0 ? highlightSearchWords : "" }, task.TargetName))),
            React.createElement(Stack, { gap: 8, h: "50px" },
                React.createElement(OrchestryText, { mt: isLoading ? "2px" : 0, isLoading: isLoading, c: "var(--mantine-color-text-tertiary-0)", size: "11px", fw: 400, lh: "11px" }, intl.formatMessage({ id: "fields.requested_by", defaultMessage: "Requested By" })),
                React.createElement(Group, { gap: 4, mt: isLoading ? "2px" : 0, wrap: "nowrap", align: "flex-start" },
                    React.createElement(OrchestryAvatar, { isLoading: isLoading, variant: "20px", userDisplayName: task.RequestedByName, src: task.RequestedByUserPhoto }),
                    React.createElement(Group, { gap: 4, mt: "5px" },
                        React.createElement(OrchestryText, { maw: 160, overflowEllipsis: true, isLoading: isLoading, c: "var(--mantine-color-text-tertiary-0)", size: "11px", fw: 400, lh: "11px" }, task.RequestedByName),
                        React.createElement(OrchestryText, { isLoading: isLoading, c: "var(--mantine-color-gray-7)", size: "11px", fw: 500, lh: "11px" }, getReadableDateFromNow(convertUtcDatetimeToLocal((_b = task.CreatedDate) !== null && _b !== void 0 ? _b : "")))))),
            React.createElement(Group, { justify: "right" },
                React.createElement(OrchestryButton, { mt: isLoading ? "2px" : 0, skeletonHeight: 42, isLoading: isLoading, size: "md", iconType: "font", iconRight: ORCH_ICONS.arrowRight, onClick: onActionButtonClick, variant: "inverted-primary", pt: 10, pb: 10, pl: 16, pr: 16 }, actionButtonText)))));
};
