import { Stack } from "@mantine/core";
import { OrchestryDataTable } from "appv2/components/orchestry-data-table/orchestry-data-table.component";
import { OrchestryPagination } from "appv2/components/orchestry-pagination/orchestry-pagination.component";
import React, { useEffect } from "react";
import { useHomePage } from "../../use-home-page";
import { useAllRequestsTab } from "./use-all-requests-tab.hook";
export var AllRequestsTab = function () {
    var isAdmin = useHomePage().isAdmin;
    var _a = useAllRequestsTab(), table = _a.table, isTableLoading = _a.isTableLoading, totalCount = _a.totalCount, pageTotal = _a.pageTotal, resultsPerPage = _a.resultsPerPage, setResultsPerPage = _a.setResultsPerPage, setActivePage = _a.setActivePage, activePage = _a.activePage, pageOptions = _a.pageOptions, refetchAllRequests = _a.refetchAllRequests;
    // Refetch all requests when the tab is navigated away from and back to
    useEffect(function () {
        refetchAllRequests();
    }, [refetchAllRequests]);
    if (!isAdmin) {
        return null;
    }
    return (React.createElement("div", { className: "all-requests-page", "data-pw": "all-requests" },
        React.createElement(Stack, { gap: 0, mt: 16 },
            React.createElement(OrchestryDataTable, { table: table }),
            React.createElement(OrchestryPagination, { isLoading: isTableLoading, variant: "table", onResultsPerPageChange: function (value) {
                    setResultsPerPage(value);
                    localStorage.setItem("health-checks-resultsPerPage", value);
                }, resultsPerPage: resultsPerPage, activePage: activePage, setactivePage: setActivePage, totalCount: totalCount !== null && totalCount !== void 0 ? totalCount : 0, pageTotal: pageTotal !== null && pageTotal !== void 0 ? pageTotal : 0, resultOptions: pageOptions }))));
};
