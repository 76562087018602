export var HomePagePages;
(function (HomePagePages) {
    /**
     * Shows the list of all Orchestry requests.
     */
    HomePagePages["AllRequests"] = "AllRequests";
    /**
     * Shows the list of Orchestry requests that are assigned to the current user.
     */
    HomePagePages["MyRequests"] = "MyRequests";
    /**
     * Shows the list of Orchestry tasks.
     */
    HomePagePages["MyTasks"] = "MyTasks";
})(HomePagePages || (HomePagePages = {}));
