import { TaskStatus } from "appv2/api/orchestry-tasks/task-status.type";
import { OrchestryAllCaughtUp } from "appv2/components/orchestry-all-caught-up/orchestry-all-caught-up.component";
import { OrchestryNoRecordsFound } from "appv2/components/orchestry-no-records-found/orchestry-no-records-found.component";
import React from "react";
import { OrchestryTaskCompletedCards } from "./orchestry-task-completed-cards.component";
import { OrchestryTaskPendingCards } from "./orchestry-task-pending-cards.component";
export var OrchestryTaskCards = function (_a) {
    var selectedSection = _a.selectedSection, highlightSearchWords = _a.highlightSearchWords, showAllCaughtUp = _a.showAllCaughtUp, showNoRecordsFound = _a.showNoRecordsFound, tasks = _a.tasks;
    if (showAllCaughtUp) {
        return React.createElement(OrchestryAllCaughtUp, null);
    }
    if (showNoRecordsFound) {
        return React.createElement(OrchestryNoRecordsFound, null);
    }
    return selectedSection === TaskStatus.Completed ? (React.createElement(OrchestryTaskCompletedCards, { tasks: tasks, highlightSearchWords: highlightSearchWords })) : (React.createElement(OrchestryTaskPendingCards, { tasks: tasks, highlightSearchWords: highlightSearchWords }));
};
