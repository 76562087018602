var _a, _b, _c, _d, _e, _f, _g;
import { TaskType } from "appv2/api/orchestry-tasks/task-type.type";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
export var TYPE_TO_LOCALIZATION_ID_LABEL_MAPPING = (_a = {},
    _a[TaskType.GuestRequest] = "pages.home.my_tasks.card.label.approval_required",
    _a[TaskType.GuestReview] = "pages.home.my_tasks.card.label.approval_required",
    _a[TaskType.ArchivalApproval] = "pages.home.my_tasks.card.label.approval_required",
    _a[TaskType.HealthCheckReview] = "pages.home.my_tasks.card.label.review_required",
    _a[TaskType.WorkspaceReview] = "pages.home.my_tasks.card.label.review_required",
    _a[TaskType.ProvisioningApproval] = "pages.home.my_tasks.card.label.approval_required",
    _a);
export var TYPE_TO_COMPLETED_LOCALIZATION_ID_LABEL_MAPPING = (_b = {},
    _b[TaskType.GuestRequest] = "pages.home.my_tasks.card.label.approval_completed",
    _b[TaskType.GuestReview] = "pages.home.my_tasks.card.label.approval_completed",
    _b[TaskType.ArchivalApproval] = "pages.home.my_tasks.card.label.approval_completed",
    _b[TaskType.HealthCheckReview] = "pages.home.my_tasks.card.label.review_completed",
    _b[TaskType.WorkspaceReview] = "pages.home.my_tasks.card.label.review_completed",
    _b[TaskType.ProvisioningApproval] = "pages.home.my_tasks.card.label.approval_completed",
    _b);
export var TYPE_TO_LOCALIZATION_ID_ACTION_BUTTON_MAPPING = (_c = {},
    _c[TaskType.GuestRequest] = "pages.home.my_tasks.card.label.action_button.approve",
    _c[TaskType.GuestReview] = "pages.home.my_tasks.card.label.action_button.approve",
    _c[TaskType.ArchivalApproval] = "pages.home.my_tasks.card.label.action_button.approve",
    _c[TaskType.HealthCheckReview] = "pages.home.my_tasks.card.label.action_button.review",
    _c[TaskType.WorkspaceReview] = "pages.home.my_tasks.card.label.action_button.review",
    _c[TaskType.ProvisioningApproval] = "pages.home.my_tasks.card.label.action_button.approve",
    _c);
export var TYPE_TO_ICON_MAPPING = (_d = {},
    _d[TaskType.GuestRequest] = ORCH_ICONS.guest,
    _d[TaskType.GuestReview] = ORCH_ICONS.guest,
    _d[TaskType.ArchivalApproval] = ORCH_ICONS.archive,
    _d[TaskType.HealthCheckReview] = ORCH_ICONS.calendarHeart01,
    _d[TaskType.WorkspaceReview] = ORCH_ICONS.workspaceReview,
    _d[TaskType.ProvisioningApproval] = ORCH_ICONS.send01,
    _d);
export var TYPE_TO_COLOR_MAPPING = (_e = {},
    _e[TaskType.GuestRequest] = "var(--mantine-color-blue-7)",
    _e[TaskType.GuestReview] = "var(--mantine-color-blue-7)",
    _e[TaskType.ArchivalApproval] = "var(--mantine-color-purple-7)",
    _e[TaskType.HealthCheckReview] = "var(--mantine-color-primary-9)",
    _e[TaskType.WorkspaceReview] = "var(--mantine-color-orange-7)",
    _e[TaskType.ProvisioningApproval] = "var(--mantine-color-brown-7)",
    _e);
export var TYPE_TO_LINK_MAPPING = (_f = {},
    _f[TaskType.GuestRequest] = "/guest-workflow/request/:guid",
    _f[TaskType.GuestReview] = "/guest-workflow/review/:guid",
    _f[TaskType.ArchivalApproval] = "/policy-workflow/archival/:guid",
    _f[TaskType.HealthCheckReview] = "/health-checks/view/:guid",
    _f[TaskType.WorkspaceReview] = "/todo/",
    _f[TaskType.ProvisioningApproval] = "requests/view/:guid",
    _f);
export var TYPE_TO_TEAMS_TAB_LINK_MAPPING = (_g = {},
    _g[TaskType.GuestRequest] = "/tabs/guest-request-workflow/:guid",
    _g[TaskType.GuestReview] = "/tabs/guest-review-workflow/:guid",
    _g[TaskType.ArchivalApproval] = "/tabs/archival-workflow/:guid",
    _g[TaskType.HealthCheckReview] = "/tabs/health-checks/view/:guid",
    _g[TaskType.WorkspaceReview] = "/todo/",
    _g[TaskType.ProvisioningApproval] = "/tabs/requests/view/:guid",
    _g);
