import { Combobox, Input, InputBase, Skeleton, useCombobox } from "@mantine/core";
import React, { forwardRef, useEffect, useState } from "react";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryComboboxIcon } from "./orchestry-combobox-icon.component";
import { OrchestryFormErrorText } from "../orchestry-form-error-text/orchestry-form-error-text.component";
import styles from "./orchestry-combobox.module.scss";
/**
 * Renders a combo box component.
 */
export var OrchestryCombobox = forwardRef(function (_a, ref) {
    var _b;
    var options = _a.options, disabledOptions = _a.disabledOptions, iconClosed = _a.iconClosed, iconOpened = _a.iconOpened, variant = _a.variant, placeholder = _a.placeholder, value = _a.value, defaultValue = _a.defaultValue, canClearValue = _a.canClearValue, onChange = _a.onChange, onOptionClick = _a.onOptionClick, setTooltipVisible = _a.setTooltipVisible, forcePlaceholder = _a.forcePlaceholder, isLoading = _a.isLoading, skeletonWidth = _a.skeletonWidth, error = _a.error, withinPortal = _a.withinPortal;
    var _c = useState(value !== null && value !== void 0 ? value : defaultValue), inputValue = _c[0], setInputValue = _c[1];
    //Sometimes the combobox is initialized with a default value which is updated later
    useEffect(function () {
        if (value || canClearValue) {
            setInputValue(value);
        }
    }, [value, canClearValue]);
    var inputValueLabel = (_b = options.find(function (option) { return option.value === inputValue; })) === null || _b === void 0 ? void 0 : _b.label;
    var combobox = useCombobox({
        onDropdownClose: function () {
            combobox.resetSelectedOption();
            setTooltipVisible === null || setTooltipVisible === void 0 ? void 0 : setTooltipVisible(true);
        },
        onDropdownOpen: function (eventSource) {
            if (eventSource === "keyboard") {
                combobox.selectActiveOption();
            }
            else {
                combobox.updateSelectedOptionIndex("active");
            }
            setTooltipVisible === null || setTooltipVisible === void 0 ? void 0 : setTooltipVisible(false);
        }
    });
    var onComboboxClick = function (event) {
        combobox.toggleDropdown();
        event.preventDefault();
    };
    var openIcon = iconOpened !== null && iconOpened !== void 0 ? iconOpened : ORCH_ICONS.chevronsUpDown;
    var closedIcon = iconClosed !== null && iconClosed !== void 0 ? iconClosed : ORCH_ICONS.chevronsUpDown;
    var showPlaceholder = forcePlaceholder || !inputValue;
    if (isLoading) {
        return React.createElement(Skeleton, { height: 35, width: skeletonWidth !== null && skeletonWidth !== void 0 ? skeletonWidth : 150, radius: "sm" });
    }
    return (React.createElement("div", { ref: ref, className: styles.orchestry_combobox },
        React.createElement(Combobox, { withinPortal: withinPortal === undefined || withinPortal === null ? true : withinPortal, store: combobox, onOptionSubmit: function (val) {
                setInputValue(val);
                combobox.closeDropdown();
                combobox.updateSelectedOptionIndex("active");
                onChange === null || onChange === void 0 ? void 0 : onChange(val);
            } },
            React.createElement(Combobox.Target, null,
                React.createElement(InputBase, { variant: variant, defaultValue: defaultValue, component: "button", pointer: true, rightSection: React.createElement(OrchestryComboboxIcon, { isExpanded: combobox.dropdownOpened, closedIcon: closedIcon, openIcon: openIcon, onClick: onComboboxClick }), onClick: onComboboxClick }, showPlaceholder ? React.createElement(Input.Placeholder, { variant: variant }, placeholder) : inputValueLabel !== null && inputValueLabel !== void 0 ? inputValueLabel : inputValue)),
            React.createElement(Combobox.Dropdown, { variant: variant }, options.map(function (option) {
                var _a;
                var active = option.value === inputValue;
                var isDisabled = disabledOptions === null || disabledOptions === void 0 ? void 0 : disabledOptions.some(function (disabledOption) { return disabledOption.value === option.value; });
                return (React.createElement(Combobox.Option, { variant: variant, value: option.value, key: option.value, disabled: isDisabled, active: active, onClick: function (e) {
                        onOptionClick === null || onOptionClick === void 0 ? void 0 : onOptionClick(e, option);
                    } }, (_a = option.label) !== null && _a !== void 0 ? _a : option.value,
                    active && React.createElement("i", { className: ORCH_ICONS.check })));
            }))),
        React.createElement(OrchestryFormErrorText, { error: error })));
});
