var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { OrchRole } from "app/store/orchestry-context/orch-role-type";
import { useCurrentUserRoles } from "appv2/hooks/use-current-user-roles.hook";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { HomePagePages } from "./home-page-pages.enum";
export var useHomePage = function () {
    var intl = useIntl();
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var currentUserHasRole = useCurrentUserRoles().currentUserHasRole;
    var isAdmin = currentUserHasRole(OrchRole.Admin) || currentUserHasRole(OrchRole.InternalAdmin) || orchestryContext.IsCurrentUserAzureGlobalAdmin;
    var tabs = useMemo(function () {
        return __spreadArray(__spreadArray([], (isAdmin
            ? [
                {
                    text: intl.formatMessage({ id: "pages.home.tabs.all_requests", defaultMessage: "All Requests" }),
                    value: HomePagePages.AllRequests,
                    hideTabCount: true
                }
            ]
            : []), true), [
            {
                text: intl.formatMessage({ id: "pages.home.tabs.my_requests", defaultMessage: "My Requests" }),
                value: HomePagePages.MyRequests,
                hideTabCount: true
            },
            {
                text: intl.formatMessage({ id: "pages.home.tabs.my_tasks", defaultMessage: "My Tasks" }),
                value: HomePagePages.MyTasks
            }
        ], false);
    }, [intl, isAdmin]);
    var _a = useState(tabs[0].value), tab = _a[0], setTab = _a[1];
    var onTabChange = function (value) {
        setTab(value !== null && value !== void 0 ? value : HomePagePages.MyRequests);
    };
    return { isAdmin: isAdmin, tabs: tabs, tab: tab, onTabChange: onTabChange, intl: intl };
};
