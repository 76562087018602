var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OrchestryFilterItem } from "appv2/components/orchestry-filter-selector/orchestry-filter-items/orchestry-filter-item.component";
import { getFilter } from "appv2/components/orchestry-filter-selector/orchestry-filter-selector.utils";
import React from "react";
import { useMyRequestsFilters } from "./use-my-requests-filters.hook";
export var MyRequestsStatusPill = function (_a) {
    var status = _a.status, isLoading = _a.isLoading;
    var MY_REQUESTS_TABLE_STATUS_FILTERS_LIST = useMyRequestsFilters().MY_REQUESTS_TABLE_STATUS_FILTERS_LIST;
    var filter = getFilter(status !== null && status !== void 0 ? status : "", MY_REQUESTS_TABLE_STATUS_FILTERS_LIST);
    if (!filter) {
        return null;
    }
    return React.createElement(OrchestryFilterItem, { isLoading: isLoading, filterProps: __assign(__assign({}, filter), { isStatic: true, selected: true }) });
};
