var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import DynamicScrollbar from "app/components/common/dynamic-scollbar";
import { newDefaultTabConfigs } from "app/components/settings/settings-teams-app";
import ChromeLanguage from "app/components/teams-tabs/team-chrome/chrome-language";
import ChromeRequestNotifications from "app/components/teams-tabs/team-chrome/chrome-request-notifications";
import ChromeRequestWorkspace from "app/components/teams-tabs/team-chrome/chrome-request-workspace";
import ChromeTrialInformation from "app/components/teams-tabs/team-chrome/chrome-trial-information";
import { TenantLicenseStatus } from "appv2/api/partner-tools/tenant-license-status.type";
import { useHealthCheckRouteParams } from "appv2/pages/beacon/health-checks/use-health-check-route-params.hook";
import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import "./team-chrome.scss";
var NewTeamChrome = React.memo(function (props) {
    var _a;
    var intl = useIntl();
    var _b = React.useState(window.innerHeight), scollAreaHeight = _b[0], setScrollAreaHeight = _b[1];
    var _c = React.useState(window.innerHeight), scollAreaHeightWithoutNav = _c[0], setScrollAreaHeightWithoutNav = _c[1];
    var navigationEl = React.useRef();
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var _d = React.useState(false), initialRedirectComplete = _d[0], setInitialRedirectComplete = _d[1];
    var _e = React.useState([]), tabConfig = _e[0], setTabConfig = _e[1];
    var defaultTab = (_a = tabConfig === null || tabConfig === void 0 ? void 0 : tabConfig[0]) === null || _a === void 0 ? void 0 : _a.Identifier;
    var _f = React.useState(""), currentTab = _f[0], setCurrentTab = _f[1];
    var _g = React.useState(false), mobileMenuOpen = _g[0], setMobileMenuOpen = _g[1];
    var history = useHistory();
    var isInSharePoint = React.useState(document.OrchestryIsInSharePoint)[0];
    var healthCheckGuid = useHealthCheckRouteParams().healthCheckGuid;
    var tabs = isInSharePoint
        ? []
        : [
            {
                Title: intl.formatMessage({ id: "layout.teams_app.tabs.my_tasks", defaultMessage: "My Tasks" }),
                Url: "/tabs/my-tasks",
                ConfigIdentifier: "my-tasks"
            },
            {
                Title: intl.formatMessage({ id: "layout.teams_app.tabs.requests", defaultMessage: "Requests" }),
                Url: "/tabs/workspace-request",
                ConfigIdentifier: "requests"
            },
            {
                Title: intl.formatMessage({ id: "layout.teams_app.tabs.directory", defaultMessage: "Directory" }),
                Url: "/tabs/workspace-directory",
                ConfigIdentifier: "directory"
            },
            {
                Title: intl.formatMessage({ id: "layout.teams_app.tabs.policy_approval", defaultMessage: "Policy Approval" }),
                Url: "/tabs/archival-workflow",
                ConfigIdentifier: "archival-approval"
            },
            {
                Title: intl.formatMessage({ id: "layout.teams_app.tabs.guest_review_approval", defaultMessage: "Guest Review" }),
                Url: "/tabs/guest-review-workflow",
                ConfigIdentifier: "guest-review-workflow"
            },
            {
                Title: intl.formatMessage({ id: "layout.teams_app.tabs.guest_request_approval", defaultMessage: "Guest Requests" }),
                Url: "/tabs/guest-request-workflow",
                ConfigIdentifier: "guest-request-workflow"
            },
            {
                Title: intl.formatMessage({ id: "layout.teams_app.tabs.provisioning_review_approval", defaultMessage: "Provisioning Requests" }),
                Url: "/tabs/requests/view",
                ConfigIdentifier: "provisioning-review-workflow"
            },
            {
                Title: intl.formatMessage({ id: "layout.teams_app.tabs.health_checks", defaultMessage: "Action Required" }),
                Url: "/tabs/health-check-ready/".concat(healthCheckGuid),
                ConfigIdentifier: "health-check-ready"
            },
            {
                Title: intl.formatMessage({ id: "layout.teams_app.tabs.health_checks", defaultMessage: "Action Required" }),
                Url: "/tabs/health-check-review/".concat(healthCheckGuid),
                ConfigIdentifier: "health-check-review"
            },
            {
                Title: intl.formatMessage({ id: "layout.teams_app.tabs.health_checks", defaultMessage: "Action Required" }),
                Url: "/tabs/partners/health-check-ready/".concat(healthCheckGuid),
                ConfigIdentifier: "partner-health-check-ready"
            },
            {
                Title: intl.formatMessage({ id: "layout.teams_app.tabs.health_checks", defaultMessage: "Action Required" }),
                Url: "/tabs/partners/health-check-review/".concat(healthCheckGuid),
                ConfigIdentifier: "partner-health-check-review"
            },
            {
                Title: intl.formatMessage({ id: "layout.teams_app.tabs.orchestry_management", defaultMessage: "Orchestry Management" }),
                Url: "/orchestry-home",
                ConfigIdentifier: "management"
            },
            {
                Title: intl.formatMessage({ id: "layout.teams_app.tabs.health_checks", defaultMessage: "Action Required" }),
                Url: "/tabs/health-checks/view/".concat(healthCheckGuid),
                ConfigIdentifier: "health-checks"
            }
        ];
    React.useEffect(function () {
        var availableTabs = [];
        if (Array.isArray(orchestryContext.TeamsTabCofig) && orchestryContext.TeamsTabCofig.length > 0) {
            var hasMyTasksTab = orchestryContext.TeamsTabCofig.some(function (at) { return at.Identifier === "my-tasks"; });
            if (!hasMyTasksTab) {
                availableTabs = __spreadArray(__spreadArray([], orchestryContext.TeamsTabCofig, true), [
                    {
                        Name: intl.formatMessage({ id: "layout.teams_app.tabs.my_tasks", defaultMessage: "My Tasks" }),
                        Identifier: "my-tasks",
                        Picked: true
                    }
                ], false);
            }
            else {
                availableTabs = __spreadArray([], orchestryContext.TeamsTabCofig, true);
            }
        }
        else {
            availableTabs = __spreadArray([], newDefaultTabConfigs, true);
        }
        if (history.location.pathname.toLowerCase().indexOf("/tabs/archival-workflow") > -1) {
            var archivalApprovalIndentifier_1 = "archival-approval";
            if (!availableTabs.some(function (at) { return at.Identifier === archivalApprovalIndentifier_1; })) {
                availableTabs.push({
                    Name: intl.formatMessage({ id: "layout.teams_app.tabs.archival_approval", defaultMessage: "Archival Approval" }),
                    Identifier: archivalApprovalIndentifier_1,
                    Picked: true
                });
                setCurrentTab(archivalApprovalIndentifier_1);
                setInitialRedirectComplete(true);
            }
        }
        if (history.location.pathname.toLowerCase().indexOf("/tabs/health-check-ready") > -1) {
            var healthCheckIndentifier_1 = "partner-health-check-ready";
            if (!availableTabs.some(function (at) { return at.Identifier === healthCheckIndentifier_1; })) {
                availableTabs.push({
                    Name: intl.formatMessage({ id: "layout.teams_app.tabs.health_checks", defaultMessage: "Action Required" }),
                    Identifier: healthCheckIndentifier_1,
                    Picked: true
                });
                setCurrentTab(healthCheckIndentifier_1);
                setInitialRedirectComplete(true);
            }
        }
        if (history.location.pathname.toLowerCase().indexOf("/tabs/health-check-review") > -1) {
            var healthCheckIndentifier_2 = "partner-health-check-review";
            if (!availableTabs.some(function (at) { return at.Identifier === healthCheckIndentifier_2; })) {
                availableTabs.push({
                    Name: intl.formatMessage({ id: "layout.teams_app.tabs.health_checks", defaultMessage: "Action Required" }),
                    Identifier: healthCheckIndentifier_2,
                    Picked: true
                });
                setCurrentTab(healthCheckIndentifier_2);
                setInitialRedirectComplete(true);
            }
        }
        if (history.location.pathname.toLowerCase().indexOf("/tabs/partners/health-check-ready") > -1) {
            var healthCheckIndentifier_3 = "health-check-ready";
            if (!availableTabs.some(function (at) { return at.Identifier === healthCheckIndentifier_3; })) {
                availableTabs.push({
                    Name: intl.formatMessage({ id: "layout.teams_app.tabs.health_checks", defaultMessage: "Action Required" }),
                    Identifier: healthCheckIndentifier_3,
                    Picked: true
                });
                setCurrentTab(healthCheckIndentifier_3);
                setInitialRedirectComplete(true);
            }
        }
        if (history.location.pathname.toLowerCase().indexOf("/tabs/partners/health-check-review") > -1) {
            var healthCheckIndentifier_4 = "health-check-review";
            if (!availableTabs.some(function (at) { return at.Identifier === healthCheckIndentifier_4; })) {
                availableTabs.push({
                    Name: intl.formatMessage({ id: "layout.teams_app.tabs.health_checks", defaultMessage: "Action Required" }),
                    Identifier: healthCheckIndentifier_4,
                    Picked: true
                });
                setCurrentTab(healthCheckIndentifier_4);
                setInitialRedirectComplete(true);
            }
        }
        if (history.location.pathname.toLowerCase().indexOf("/tabs/guest-review-workflow") > -1) {
            var guestReviewIdentifier_1 = "guest-review-workflow";
            if (!availableTabs.some(function (at) { return at.Identifier === guestReviewIdentifier_1; })) {
                console.log("adding guest review tab");
                availableTabs.push({
                    Name: intl.formatMessage({ id: "layout.teams_app.tabs.guest_review_workflow", defaultMessage: "Review Guests" }),
                    Identifier: guestReviewIdentifier_1,
                    Picked: true
                });
                setCurrentTab(guestReviewIdentifier_1);
                setInitialRedirectComplete(true);
            }
        }
        if (history.location.pathname.toLowerCase().indexOf("/tabs/guest-request-workflow") > -1) {
            var guestRequestIdentifier_1 = "guest-request-workflow";
            if (!availableTabs.some(function (at) { return at.Identifier === guestRequestIdentifier_1; })) {
                console.log("adding guest review tab");
                availableTabs.push({
                    Name: intl.formatMessage({ id: "layout.teams_app.tabs.guest_request_workflow", defaultMessage: "Review Guest Requests" }),
                    Identifier: guestRequestIdentifier_1,
                    Picked: true
                });
                setCurrentTab(guestRequestIdentifier_1);
                setInitialRedirectComplete(true);
            }
        }
        if (history.location.pathname.toLowerCase().indexOf("/tabs/requests/view") > -1) {
            var provisioningReviewIdentifier_1 = "provisioning-review-workflow";
            if (!availableTabs.some(function (at) { return at.Identifier === provisioningReviewIdentifier_1; })) {
                console.log("adding provisioning review tab");
                availableTabs.push({
                    Name: intl.formatMessage({ id: "layout.teams_app.tabs.provisioning_review_workflow", defaultMessage: "Review Provisioning Requests" }),
                    Identifier: provisioningReviewIdentifier_1,
                    Picked: true
                });
                setCurrentTab(provisioningReviewIdentifier_1);
                setInitialRedirectComplete(true);
            }
        }
        if (history.location.pathname.toLowerCase().indexOf("/tabs/health-checks/view") > -1) {
            var healthCheckIndentifier_5 = "health-checks";
            if (!availableTabs.some(function (at) { return at.Identifier === healthCheckIndentifier_5; })) {
                availableTabs.push({
                    Name: intl.formatMessage({ id: "layout.teams_app.tabs.health_checks", defaultMessage: "Action Required" }),
                    Identifier: healthCheckIndentifier_5,
                    Picked: true
                });
                setCurrentTab(healthCheckIndentifier_5);
                setInitialRedirectComplete(true);
            }
        }
        if (orchestryContext.CanCurrentUserAccessRequests === false) {
            availableTabs = availableTabs.filter(function (t) { return t.Identifier !== "requests"; });
        }
        if (orchestryContext.LicenseStatus === TenantLicenseStatus.Beacon) {
            availableTabs = availableTabs.filter(function (t) { return t.Identifier !== "requests"; });
            availableTabs = availableTabs.filter(function (t) { return t.Identifier !== "directory"; });
            availableTabs = availableTabs.filter(function (t) { return t.Identifier !== "home"; });
        }
        setTabConfig(availableTabs);
    }, [history.location.pathname]);
    var updateScrollAreaHeight = function () {
        var newHeight = window.innerHeight;
        if (navigationEl.current !== undefined) {
            var contentVerticalPadding = 10;
            newHeight = newHeight - navigationEl.current.offsetHeight - contentVerticalPadding;
        }
        if (scollAreaHeightWithoutNav !== newHeight) {
            // console.log("setScrollAreaHeight", (window.innerHeight));
            // console.log("setScrollAreaHeightWithoutNav", (newHeight));
            setScrollAreaHeight(window.innerHeight);
            setScrollAreaHeightWithoutNav(newHeight);
        }
    };
    React.useEffect(function () {
        updateScrollAreaHeight();
        if (tabConfig.length > 0) {
            // console.log("SET CURRENT TAB", history.location.pathname);
            if (history.location.pathname === "/" && initialRedirectComplete === false) {
                console.log("defaultTab", defaultTab);
                goToTab(defaultTab);
                setInitialRedirectComplete(true);
            }
            //const findTab = tabs.find(t => t.ConfigIdentifier === tabConfig[0].Identifier);
        }
    }, [tabConfig, defaultTab]); //remove tabConfig?.[0]?.Identifier from arg list when enableMyTasksFeature removed
    var goToTab = function (identifier) {
        var findTab = tabs.find(function (t) { return t.ConfigIdentifier === identifier; });
        if (findTab !== undefined && findTab !== null) {
            //console.log("redirect",findTab.Url);
            history.push(findTab.Url);
            setCurrentTab(findTab.ConfigIdentifier);
        }
    };
    React.useEffect(function () {
        updateScrollAreaHeight();
        window.addEventListener("resize", updateScrollAreaHeight);
        return function () {
            window.removeEventListener("resize", updateScrollAreaHeight);
        };
    }, []);
    function RenderButton(_a) {
        var Identifier = _a.Identifier, Name = _a.Name, Picked = _a.Picked;
        var findTab = tabs.find(function (t) { return t.ConfigIdentifier === Identifier; });
        if (findTab !== undefined && findTab !== null && Picked === true) {
            return (React.createElement(TeamsTabButton, { title: findTab.Title, url: findTab.Url, currentTab: currentTab, identifier: findTab.ConfigIdentifier, onClick: function (tabIdent) {
                    setCurrentTab(tabIdent);
                    setMobileMenuOpen(false);
                } }));
        }
        else {
            return React.createElement(React.Fragment, null);
        }
    }
    // const RenderDyanmicButton = () => {
    //    if(history.location.pathname.indexOf("/tabs/archival-workflow")){
    //       return (
    //          <TeamsTabButton title={""} url={history.location.pathname} currentTab={currentTab} identifier={findTab.ConfigIdentifier} onClick={(tabIdent)=>setCurrentTab(tabIdent)}/>
    //       )
    //    }
    // }
    React.useEffect(function () {
        //console.log("URL changed", window.location.href.replace(window.location.origin,""));
        var matchedTab = tabs.find(function (t) { return t.Url === window.location.href.replace(window.location.origin, ""); });
        if (matchedTab !== undefined) {
            if (currentTab !== matchedTab.ConfigIdentifier)
                setCurrentTab(matchedTab.ConfigIdentifier);
        }
        if (window.location.href.indexOf("/tabs/") === -1) {
            setCurrentTab("management");
        }
    }, [window.location.href, orchestryContext.LicenseStatus]);
    var logoClick = function () {
        if (window.innerWidth < 768) {
            setMobileMenuOpen(!mobileMenuOpen);
        }
        else {
            document.location.reload();
        }
    };
    if (isInSharePoint) {
        return (React.createElement("div", { className: "chrome-content" },
            React.createElement("div", { className: "chrome-content-wrapper" },
                React.createElement(DynamicScrollbar, { shouldShow: true, maxHeight: scollAreaHeightWithoutNav }, props.children))));
    }
    return (React.createElement("div", { className: "team-chrome" + (currentTab === "management" ? " orchestry-mangement-open" : "") },
        React.createElement("div", { className: "chrome-navigation", ref: navigationEl },
            React.createElement("div", { className: "orchestry-logo", onClick: function () {
                    logoClick();
                } },
                React.createElement("img", { src: "/black-logo.svg", title: intl.formatMessage({ id: "layout.teams_app.tabs.refresh", defaultMessage: "Refresh" }) })),
            React.createElement("div", { className: "mobile-icon", onClick: function () {
                    logoClick();
                } },
                React.createElement("i", { className: "fa fa-bars" })),
            React.createElement("div", { className: "tabs" + (mobileMenuOpen === true ? " mobile-open" : "") },
                tabConfig.map(function (tc) { return (React.createElement(RenderButton, { Identifier: tc.Identifier, Name: tc.Name, Picked: tc.Picked, key: tc.Identifier })); }),
                React.createElement(RenderButton, { Identifier: "management", Name: "", Picked: orchestryContext.Role === "Admin" ? true : false })),
            React.createElement("div", { className: "center-spacer" }),
            React.createElement("div", { className: "chrome-right-side" },
                React.createElement(ChromeTrialInformation, null),
                React.createElement(ChromeLanguage, null),
                React.createElement(ChromeRequestNotifications, { isManagementOpen: currentTab === "management" }),
                React.createElement(ChromeRequestWorkspace, { scollAreaHeight: scollAreaHeight, currentTab: currentTab, setTab: function (tabIdent) {
                        goToTab(tabIdent);
                    }, allTabs: tabConfig }))),
        React.createElement("div", { className: "chrome-content" },
            React.createElement("div", { className: "chrome-content-wrapper" },
                React.createElement(DynamicScrollbar, { shouldShow: true, maxHeight: scollAreaHeightWithoutNav }, props.children)))));
});
export default NewTeamChrome;
var TeamsTabButton = function (props) {
    return (React.createElement(NavLink, { exact: true, to: props.url, className: "tab-button" + (props.identifier === props.currentTab ? " active" : " not-active"), onClick: function () { return props.onClick(props.identifier); } }, props.title));
};
