var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export var orchestryTasksApi = createApi({
    reducerPath: "orchestryTasksApi",
    baseQuery: fetchBaseQuery({ baseUrl: "/api/OrchestryTasks" }),
    tagTypes: ["OrchestryTasks"],
    endpoints: function (builder) { return ({
        getOrchestryTasks: builder.query({
            query: function (request) {
                return { url: "/GetTasks", params: __assign(__assign({}, request.queryParameters), { SearchText: request.searchText }) };
            },
            providesTags: ["OrchestryTasks"]
        })
    }); }
});
export var useGetOrchestryTasksQuery = orchestryTasksApi.useGetOrchestryTasksQuery;
