import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryChip } from "appv2/components/orchestry-chip/orchestry-chip.component";
import React from "react";
import "./orchestry-filter-count.scss";
export var OrchestryClosableFilterCount = function (_a) {
    var count = _a.count, onClick = _a.onClick;
    if (!count) {
        return null;
    }
    return React.createElement(OrchestryChip, { selected: true, value: count.toString(), icon: ORCH_ICONS.filterFunnel02, title: count.toString(), onClick: onClick, variantOverride: "filterCount" });
};
