import FormDropdownSelect from "app/components/common/form-dropdown-select";
import { FormValidationContext, FormValidator } from "app/components/common/form-validation";
import PortletAlert from "app/components/common/portlet-alert";
import * as TenantCurrentStore from "app/store/tenants/current";
import * as React from "react";
import { Col, Form, Row } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { InstallWizardContext } from "../..";
var InstallerStepInformation = React.memo(function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    var dispatch = useDispatch();
    var iwc = React.useContext(InstallWizardContext);
    var currentTenant = useSelector(function (state) { return state.tenantCurrentState.item; });
    var _m = React.useState(false), isPageValid = _m[0], setIsPageValid = _m[1];
    React.useEffect(function () {
        console.log("currentTenant step 1", currentTenant);
        // Check to see if granting can be skipped.
        // TenantService.tryGetTenantInfo().then((tenantInfo:any)=>{
        //     if(tenantInfo.exists === true){
        //         iwc.setTenantInformation({...iwc.tenantInformation,
        //             DisplayName:tenantInfo.DisplayName
        //         })
        //     }
        // })
        //iwc.setStepProgress(props.step.key, false);
    }, []);
    React.useEffect(function () {
        iwc.setStepProgress(props.step.key, isPageValid);
    }, [isPageValid]);
    return (React.createElement("div", { className: "installer-step-information fadein-500ms" },
        React.createElement(FormValidationContext, { UniqueIdentifier: "InstallerStep1", OnChangeIsValid: function (isValid) {
                setIsPageValid(isValid);
            } },
            React.createElement(Row, null,
                React.createElement(Col, { xs: 12, md: 12, lg: 10, xl: 8 },
                    React.createElement("div", { className: "kt-wizard-v2__content", "data-ktwizard-type": "step-content", "data-ktwizard-state": "current" },
                        React.createElement("div", { className: "kt-heading kt-heading--md" },
                            React.createElement("h2", null, "Enter your Account Details")),
                        React.createElement("div", { className: "kt-form__section kt-form__section--first" },
                            React.createElement("div", { className: "kt-wizard-v2__form" },
                                React.createElement(Row, null,
                                    React.createElement(Col, null,
                                        React.createElement(Form.Group, { className: "ob-form-group" },
                                            React.createElement(Form.Label, null,
                                                "First Name ",
                                                React.createElement("span", { className: "required-astrix" }, "*")),
                                            React.createElement(Form.Control
                                            //placeholder="First Name"
                                            //value={rwc.currentRequest.Name}
                                            , { 
                                                //placeholder="First Name"
                                                //value={rwc.currentRequest.Name}
                                                autoComplete: "off", value: (_a = currentTenant.TenantInformation) === null || _a === void 0 ? void 0 : _a.SignupFirstname, 
                                                //@ts-ignore
                                                onChange: function (event) { return dispatch(TenantCurrentStore.UpdateInformation({ SignupFirstname: event.currentTarget.value })); } }),
                                            React.createElement(FormValidator, { UniqueIdentifier: "first-name", Validators: ["string-not-empty"], FieldName: "First Name", Value: (_b = currentTenant.TenantInformation) === null || _b === void 0 ? void 0 : _b.SignupFirstname }))),
                                    React.createElement(Col, null,
                                        React.createElement(Form.Group, { className: "ob-form-group" },
                                            React.createElement(Form.Label, null,
                                                "Last Name ",
                                                React.createElement("span", { className: "required-astrix" }, "*")),
                                            React.createElement(Form.Control
                                            //placeholder="Last Name"
                                            //value={rwc.currentRequest.Name}
                                            , { 
                                                //placeholder="Last Name"
                                                //value={rwc.currentRequest.Name}
                                                value: (_c = currentTenant.TenantInformation) === null || _c === void 0 ? void 0 : _c.SignupLastName, autoComplete: "off", 
                                                //@ts-ignore
                                                onChange: function (event) { return dispatch(TenantCurrentStore.UpdateInformation({ SignupLastName: event.currentTarget.value })); } }),
                                            React.createElement(FormValidator, { UniqueIdentifier: "last-name", Validators: ["string-not-empty"], FieldName: "Last Name", Value: (_d = currentTenant.TenantInformation) === null || _d === void 0 ? void 0 : _d.SignupLastName })))),
                                React.createElement(Row, null,
                                    React.createElement(Col, null,
                                        React.createElement(Form.Group, { className: "ob-form-group" },
                                            React.createElement(Form.Label, null,
                                                "Phone Number ",
                                                React.createElement("span", { className: "required-astrix" }, "*")),
                                            React.createElement(PhoneInput, { country: "us", value: ((_e = currentTenant.TenantInformation) === null || _e === void 0 ? void 0 : _e.SignupPhoneNumber) != null ? (_f = currentTenant.TenantInformation) === null || _f === void 0 ? void 0 : _f.SignupPhoneNumber : "", onChange: function (phone) { return dispatch(TenantCurrentStore.UpdateInformation({ SignupPhoneNumber: phone })); } }),
                                            React.createElement(FormValidator, { UniqueIdentifier: "phone-number", Validators: ["string-not-empty"], FieldName: "Phone Number", Value: (_g = currentTenant.TenantInformation) === null || _g === void 0 ? void 0 : _g.SignupPhoneNumber }))),
                                    React.createElement(Col, null,
                                        React.createElement(Form.Group, { className: "ob-form-group" },
                                            React.createElement(Form.Label, null,
                                                "Job Title ",
                                                React.createElement("span", { className: "required-astrix" }, "*")),
                                            React.createElement(Form.Control
                                            //placeholder="Organization Name"
                                            , { 
                                                //placeholder="Organization Name"
                                                value: (_h = currentTenant.TenantInformation) === null || _h === void 0 ? void 0 : _h.SignupJobTitle, autoComplete: "off", 
                                                //@ts-ignore
                                                onChange: function (event) { return dispatch(TenantCurrentStore.UpdateInformation({ SignupJobTitle: event.currentTarget.value })); } }),
                                            React.createElement(FormValidator, { UniqueIdentifier: "job-title", Validators: ["string-not-empty"], FieldName: "Job Title", Value: (_j = currentTenant.TenantInformation) === null || _j === void 0 ? void 0 : _j.SignupJobTitle })))),
                                React.createElement(Row, null,
                                    React.createElement(Col, null,
                                        " ",
                                        React.createElement("div", { className: "form-group ob-form-group" },
                                            React.createElement("label", null,
                                                "Organization's Name ",
                                                React.createElement("span", { className: "required-astrix" }, "*")),
                                            React.createElement("input", { type: "text", className: "form-control", name: "organization-name", placeholder: "", value: currentTenant.DisplayName, onChange: function (event) {
                                                    dispatch(TenantCurrentStore.Update({ DisplayName: event.currentTarget.value }));
                                                } }),
                                            React.createElement(FormValidator, { UniqueIdentifier: "org-name", Validators: ["string-not-empty"], FieldName: "Organization Name", Value: currentTenant.DisplayName }))),
                                    React.createElement(Col, null,
                                        React.createElement(Form.Group, { controlId: "control-org-size", className: "ob-form-group" },
                                            React.createElement(Form.Label, null,
                                                "Number of user in your organization ",
                                                React.createElement("span", { className: "required-astrix" }, "*")),
                                            React.createElement(FormDropdownSelect, { list: [
                                                    {
                                                        id: "1",
                                                        title: "1 - 50",
                                                        value: "1 - 50"
                                                    },
                                                    {
                                                        id: "2",
                                                        title: "51 - 200",
                                                        value: "51 - 200"
                                                    },
                                                    {
                                                        id: "3",
                                                        title: "201 - 500",
                                                        value: "201 - 500"
                                                    },
                                                    {
                                                        id: "4",
                                                        title: "501 - 1000",
                                                        value: "501 - 1000"
                                                    },
                                                    {
                                                        id: "5",
                                                        title: "1001 - 3000",
                                                        value: "1001 - 3000"
                                                    },
                                                    {
                                                        id: "6",
                                                        title: "3001 - 5000",
                                                        value: "3001 - 5000"
                                                    },
                                                    {
                                                        id: "7",
                                                        title: "5000+",
                                                        value: "5000+"
                                                    }
                                                ], defaultValue: (_k = currentTenant.TenantInformation) === null || _k === void 0 ? void 0 : _k.SignupOrgNumberOfUsers, onChange: function (value) { return dispatch(TenantCurrentStore.UpdateInformation({ SignupOrgNumberOfUsers: value })); } }),
                                            React.createElement(FormValidator, { UniqueIdentifier: "org-size", Validators: ["dropdown-has-value"], FieldName: "Organization Size", Value: (_l = currentTenant.TenantInformation) === null || _l === void 0 ? void 0 : _l.SignupOrgNumberOfUsers })))),
                                React.createElement(PortletAlert, { icon: "info", outline: true, size: "small", type: "warning", title: "Thanks so much!", message: "We hope that you love Orchestry and if you have any questions please get in touch :)" })))))))));
});
export default InstallerStepInformation;
