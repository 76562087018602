var _a;
import * as PageHeader from "app/components/common/page-header";
import { Portlet, PortletBody } from "app/components/common/portlet";
import { InstallerMode } from "app/components/installer/installer-wizard";
import OrchestryCheckUpgrade from "app/components/orchestry-context/orchestry-check-upgrade";
import { OrchestryPageHeader } from "appv2/components/orchestry-page-header/orchestry-page-header.component";
import { OrchestryPageTitle } from "appv2/components/orchestry-page-title/orchestry-page-title.component";
import { OrchestryTabs } from "appv2/components/orchestry-tabs/orchestry-tabs.component";
import React from "react";
import { HomePagePages } from "./home-page-pages.enum";
import "./home.page.scss";
import { AllRequestsTab } from "./tabs/all-requests-tab/all-requests-tab.component";
import { MyRequestsTab } from "./tabs/my-requests-tab/my-requests-tab.component";
import { OrchestryTasksTab } from "./tabs/orchestry-tasks-tab/orchestry-tasks-tab.component";
import { useHomePage } from "./use-home-page";
var TAB_TO_PAGE_MAPPING = (_a = {},
    _a[HomePagePages.AllRequests] = function () { return React.createElement(AllRequestsTab, null); },
    _a[HomePagePages.MyRequests] = function () { return React.createElement(MyRequestsTab, null); },
    _a[HomePagePages.MyTasks] = function () { return React.createElement(OrchestryTasksTab, null); },
    _a);
export var HomePage = function () {
    var _a = useHomePage(), isAdmin = _a.isAdmin, tabs = _a.tabs, tab = _a.tab, onTabChange = _a.onTabChange, intl = _a.intl;
    return (React.createElement("div", { className: "home-page" },
        React.createElement(PageHeader.SetLeftContent, { title: intl.formatMessage({ id: "layout.menu.home", defaultMessage: "Home" }) }),
        React.createElement(Portlet, null,
            React.createElement(PortletBody, null,
                React.createElement(OrchestryPageHeader, null,
                    React.createElement(OrchestryPageTitle, { title: intl.formatMessage({ id: "layout.menu.home", defaultMessage: "Home" }) })),
                React.createElement(OrchestryTabs, { tabs: tabs, tab: tab, onTabChange: onTabChange }),
                isAdmin && React.createElement(OrchestryCheckUpgrade, { installMode: InstallerMode.Update }),
                TAB_TO_PAGE_MAPPING[tab !== null && tab !== void 0 ? tab : HomePagePages.MyRequests]()))));
};
