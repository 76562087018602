export var SelectedDate;
(function (SelectedDate) {
    /**
     * The selected date is within the last 30 days.
     */
    SelectedDate["Within30Days"] = "Within30Days";
    /**
     * The selected date is within the last 60 days.
     */
    SelectedDate["Within60Days"] = "Within60Days";
    /**
     * The selected date is within the last 90 days.
     */
    SelectedDate["Within90Days"] = "Within90Days";
    /**
     * The selected date is within the last year.
     */
    SelectedDate["Within1Year"] = "Within1Year";
    /**
     * The selected date is a custom date range.
     */
    SelectedDate["Custom"] = "Custom";
})(SelectedDate || (SelectedDate = {}));
