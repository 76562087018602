import { Center, Stack } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import React from "react";
import { useIntl } from "react-intl";
import { OrchestryFeaturedIcon } from "../orchestry-featured-icon/orchestry-featured-icon.component";
import { OrchestryText } from "../orchestry-text/orchestry-text.component";
export var OrchestryAllCaughtUp = function () {
    var intl = useIntl();
    return (React.createElement(Center, { mt: 40, mb: 48 },
        React.createElement(Stack, { gap: 0, align: "center", justify: "center" },
            React.createElement(OrchestryFeaturedIcon, { size: "lg", variant: "double-circle", colorVariant: "gray-6", iconClassName: ORCH_ICONS.confetti }),
            React.createElement(OrchestryText, { c: "text-primary.0", fz: "16px", fw: 400, lh: "24px", mt: 12 }, intl.formatMessage({
                id: "components.all_caught_up",
                defaultMessage: "All caught up!"
            })),
            React.createElement(OrchestryText, { mt: 4, c: "text-secondary.0", fz: "14px", fw: 300, lh: "20px", ta: "center" }, intl.formatMessage({
                id: "components.all_caught_up_subtitle",
                defaultMessage: "You are all caught up! There are no remaining actions to do here!"
            })))));
};
