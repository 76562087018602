import { Skeleton } from "@mantine/core";
import clsx from "clsx";
import React, { forwardRef } from "react";
import "./orchestry-svg-icon.scss";
var SIZE_TO_PX_MAPPING = {
    xxs: "12px",
    xs: "14px",
    sm: "16px",
    md: "18px",
    lg: "24px",
    xl: "32px",
    xxl: "40px"
};
/**
 * A component that displays an SVG icon, with different icons for selected and unselected states.
 */
export var OrchestrySVGIcon = forwardRef(function (_a, ref) {
    var selected = _a.selected, onClick = _a.onClick, iconNeutral = _a.iconNeutral, iconSelected = _a.iconSelected, size = _a.size, isLoading = _a.isLoading, borderRadius = _a.borderRadius, customSize = _a.customSize;
    if (isLoading) {
        var sizeToPX = customSize ? customSize : SIZE_TO_PX_MAPPING[size !== null && size !== void 0 ? size : "xs"];
        return React.createElement(Skeleton, { height: sizeToPX, width: sizeToPX, circle: true });
    }
    return (React.createElement(React.Fragment, null, selected !== undefined ? (React.createElement("div", { ref: ref, onClick: onClick, className: clsx("orchestry-svg-icon", "selected"), "data-size": size },
        iconNeutral && (React.createElement("img", { style: { display: selected ? "none" : "block", borderRadius: "10px", width: customSize ? customSize : undefined, height: customSize ? customSize : undefined }, src: iconNeutral, alt: "icon" })),
        iconSelected && (React.createElement("img", { style: { display: selected ? "block" : "none", borderRadius: "10px", width: customSize ? customSize : undefined, height: customSize ? customSize : undefined }, src: iconSelected, alt: "Selected icon" })))) : (!!iconNeutral && (React.createElement("div", { className: "orchestry-svg-icon", ref: ref, "data-size": size },
        React.createElement("img", { style: {
                borderRadius: borderRadius,
                width: customSize ? customSize : undefined,
                height: customSize ? customSize : undefined
            }, src: iconNeutral, alt: "icon" }))))));
});
