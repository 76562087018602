import { RequestsViewType } from "appv2/api/requests/requests-view.type";
import { useGetRequestsQuery } from "appv2/api/requests/requests.api";
import { defaultResultOptions } from "appv2/components/orchestry-pagination/orchestry-pagination.component";
import { useState } from "react";
import { useMyRequestsTable } from "./use-my-requests-table.hook";
export var useMyRequestsTab = function () {
    var _a, _b, _c, _d, _e;
    var pageOptions = defaultResultOptions;
    var _f = useState(1), activePage = _f[0], setActivePage = _f[1];
    var _g = useState(function () { var _a; return (_a = localStorage.getItem("orchestry-tasks-resultsPerPage")) !== null && _a !== void 0 ? _a : "25"; }), resultsPerPage = _g[0], setResultsPerPage = _g[1];
    var _h = useGetRequestsQuery({
        queryParameters: {
            CurrentPage: activePage,
            ResultsPerPage: Number(resultsPerPage)
        },
        view: RequestsViewType.MyRequests
    }), pagedRequests = _h.data, isLoading = _h.isLoading, isFetching = _h.isFetching, refetchMyRequests = _h.refetch;
    var requests = (_a = pagedRequests === null || pagedRequests === void 0 ? void 0 : pagedRequests.items) !== null && _a !== void 0 ? _a : [];
    var totalCount = (_c = (_b = pagedRequests === null || pagedRequests === void 0 ? void 0 : pagedRequests.items) === null || _b === void 0 ? void 0 : _b.length) !== null && _c !== void 0 ? _c : 0;
    var totalResults = (_e = (_d = pagedRequests === null || pagedRequests === void 0 ? void 0 : pagedRequests.paging) === null || _d === void 0 ? void 0 : _d.TotalResults) !== null && _e !== void 0 ? _e : 0;
    var pageTotal = totalResults > 0 ? Math.ceil(totalResults / Number(resultsPerPage)) : 0;
    var isPageLoading = isLoading;
    var isTableLoading = isPageLoading || isFetching;
    var table = useMyRequestsTable(requests, isLoading, isTableLoading).table;
    return {
        isTableLoading: isTableLoading,
        table: table,
        totalCount: totalCount,
        pageTotal: pageTotal,
        resultsPerPage: resultsPerPage,
        setResultsPerPage: setResultsPerPage,
        setActivePage: setActivePage,
        activePage: activePage,
        pageOptions: pageOptions,
        refetchMyRequests: refetchMyRequests
    };
};
