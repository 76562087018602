import { useFeatureValue } from "@growthbook/growthbook-react";
import RequestDetails from "app/components/requests/request-details";
import RequestList from "app/components/requests/request-list";
import RequestQueue from "app/components/requests/request-queue";
import { RequestWizardInsideOrchestry } from "app/components/requests/request-wizard";
import React from "react";
import { Route, Switch } from "react-router-dom";
export default function RequestPages() {
    var enableMyTasksFeature = useFeatureValue("OE-4205_enable_my_tasks_feature", false);
    return (React.createElement(Switch, null,
        enableMyTasksFeature ? (React.createElement(Route, { exact: true, path: "/requests" },
            React.createElement(RequestWizardInsideOrchestry, null))) : (React.createElement(Route, { exact: true, path: "/requests", component: RequestList })),
        React.createElement(Route, { exact: true, path: "/requests/new" },
            React.createElement(RequestWizardInsideOrchestry, null)),
        React.createElement(Route, { exact: true, path: "/requests/queue" },
            React.createElement(RequestQueue, null)),
        React.createElement(Route, { exact: true, path: "/requests/view/:guid/:action?", component: RequestDetails })));
}
