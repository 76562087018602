import { Stack } from "@mantine/core";
import { OrchestryDataTable } from "appv2/components/orchestry-data-table/orchestry-data-table.component";
import { OrchestryPagination } from "appv2/components/orchestry-pagination/orchestry-pagination.component";
import React, { useEffect } from "react";
import { useMyRequestsTab } from "./use-my-requests-tab.hook";
export var MyRequestsTab = function () {
    var _a = useMyRequestsTab(), table = _a.table, isTableLoading = _a.isTableLoading, totalCount = _a.totalCount, pageTotal = _a.pageTotal, resultsPerPage = _a.resultsPerPage, setResultsPerPage = _a.setResultsPerPage, setActivePage = _a.setActivePage, activePage = _a.activePage, pageOptions = _a.pageOptions, refetchMyRequests = _a.refetchMyRequests;
    // Refetch my requests when the tab is navigated away from and back to
    useEffect(function () {
        refetchMyRequests();
    }, [refetchMyRequests]);
    return (React.createElement("div", { className: "my-requests-page" },
        React.createElement(Stack, { gap: 0, mt: 16 },
            React.createElement(OrchestryDataTable, { table: table }),
            React.createElement(OrchestryPagination, { isLoading: isTableLoading, variant: "table", onResultsPerPageChange: function (value) {
                    setResultsPerPage(value);
                    localStorage.setItem("health-checks-resultsPerPage", value);
                }, resultsPerPage: resultsPerPage, activePage: activePage, setactivePage: setActivePage, totalCount: totalCount !== null && totalCount !== void 0 ? totalCount : 0, pageTotal: pageTotal !== null && pageTotal !== void 0 ? pageTotal : 0, resultOptions: pageOptions }))));
};
