import { Card, Group, Stack } from "@mantine/core";
import { convertUtcDatetimeToLocal, getReadableDateFromNow } from "app/utils/dateUtils";
import { OrchestryBadge } from "appv2/components/orchestry-badge/orchestry-badge.component";
import { OrchestryGreenCheckIcon } from "appv2/components/orchestry-green-check-icon/orchestry-green-check-icon.component";
import { OrchestryHighlight } from "appv2/components/orchestry-highlight/orchestry-highlight.component";
import { OrchestryText } from "appv2/components/orchestry-text/orchestry-text.component";
import { OrchestryWorkspaceIcon } from "appv2/components/orchestry-workspace-icon/orchestry-workspace-icon.component";
import React from "react";
import { useOrchestryTaskCard } from "./use-orchestry-task-card";
export var OrchestryCompletedTaskCard = function (_a) {
    var task = _a.task, isLoading = _a.isLoading, highlightSearchWords = _a.highlightSearchWords;
    var _b = useOrchestryTaskCard(task), intl = _b.intl, completedLabel = _b.completedLabel, badgeText = _b.badgeText, badgeIcon = _b.badgeIcon, badgeColor = _b.badgeColor, showDeletedHealthCheckLabel = _b.showDeletedHealthCheckLabel;
    return (React.createElement(Card, { mih: "175px", style: { borderRadius: "12px" }, variant: "shadow-border", p: 24 },
        React.createElement(Stack, { gap: 0 },
            React.createElement(Group, { justify: "space-between" },
                React.createElement(OrchestryBadge, { isLoading: isLoading, color: badgeColor, icon: badgeIcon }, badgeText),
                React.createElement(OrchestryGreenCheckIcon, { isLoading: isLoading, size: 24 })),
            React.createElement(OrchestryText, { mt: isLoading ? "8px" : 4, isLoading: isLoading, c: "var(--mantine-color-text-primary-0)", size: "16px", fw: 500, lh: "24px" }, completedLabel),
            React.createElement(Group, { gap: 8, wrap: "nowrap", align: "top", mt: 8, h: "40px" }, showDeletedHealthCheckLabel ? (React.createElement(OrchestryText, { c: "var(--mantine-color-text-tertiary-0)", size: "14px", fw: 500, lh: "20px" }, intl.formatMessage({ id: "pages.home.my_tasks.card.health_check_deleted", defaultMessage: "Health Check Deleted" }))) : (React.createElement(React.Fragment, null,
                React.createElement(OrchestryWorkspaceIcon, { customSize: "20px", borderRadius: "4px", svgSize: "md", isLoading: isLoading, variant: "20px", icon: task.TargetIcon, workspaceName: task.TargetName }),
                React.createElement(OrchestryHighlight, { overFlowEllipsisNthLine: 2, isLoading: isLoading, c: "var(--mantine-color-text-primary-0)", size: "14px", fw: 500, lh: "20px", highlight: highlightSearchWords !== null && highlightSearchWords !== void 0 ? highlightSearchWords : "" }, task.TargetName)))),
            React.createElement(Group, { gap: 4, mt: 10 },
                React.createElement(OrchestryText, { mt: isLoading ? "2px" : 0, isLoading: isLoading, c: "var(--mantine-color-text-tertiary-0)", size: "11px", fw: 400, lh: "11px" }, intl.formatMessage({ id: "fields.completed_on", defaultMessage: "Completed On" })),
                React.createElement(OrchestryText, { isLoading: isLoading, c: "var(--mantine-color-gray-7)", size: "11px", fw: 500, lh: "11px" }, getReadableDateFromNow(convertUtcDatetimeToLocal(task === null || task === void 0 ? void 0 : task.DateCompleted)))))));
};
