import { Card, Group, Stack } from "@mantine/core";
import React from "react";
import { OrchestryInformationBubbleTooltip } from "../orchestry-information-bubble-tooltip/orchestry-information-bubble-tooltip.component";
import { OrchestryText } from "../orchestry-text/orchestry-text.component";
export var OrchestrySettingsPageControl = function (_a) {
    var title = _a.title, description = _a.description, children = _a.children, titleTooltip = _a.titleTooltip, isLoading = _a.isLoading;
    return (React.createElement(Stack, { gap: 8 },
        React.createElement(Group, { gap: 8 },
            React.createElement(OrchestryText, { isLoading: isLoading, c: "text-primary.0", size: "16px", fw: 500, lh: "20px" }, title),
            titleTooltip && React.createElement(OrchestryInformationBubbleTooltip, { label: titleTooltip })),
        React.createElement(Group, { align: "flex-start", wrap: "nowrap", gap: 24, style: { flexBasis: "50%" } },
            React.createElement("div", { style: { flexBasis: "50%" } }, description),
            React.createElement(Card, { p: 0, pb: 16, style: { overflow: "visible", flexBasis: "50%" } }, children))));
};
