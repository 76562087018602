export var RequestStatus;
(function (RequestStatus) {
    RequestStatus["Created"] = "Created";
    RequestStatus["ApprovalRequired"] = "ApprovalRequired";
    RequestStatus["Approved"] = "Approved";
    RequestStatus["Rejected"] = "Rejected";
    RequestStatus["ReadyToProvision"] = "ReadyToProvision";
    RequestStatus["Provisioning"] = "Provisioning";
    RequestStatus["Provisioned"] = "Provisioned";
    RequestStatus["Failed"] = "Failed";
    RequestStatus["Archived"] = "Archived";
    RequestStatus["Retry"] = "Retry";
})(RequestStatus || (RequestStatus = {}));
