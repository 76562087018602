import { Flex } from "@mantine/core";
import { OrchestryText } from "appv2/components/orchestry-text/orchestry-text.component";
import { OrchestryTooltip } from "appv2/components/orchestry-tooltip/orchestry-tooltip.component";
import React from "react";
import { OrchestryFormErrorText } from "../../orchestry-form-error-text/orchestry-form-error-text.component";
import { OrchestryFilterItem } from "./orchestry-filter-item.component";
import { OrchestryFilterItemsWithOverflowIndicator } from "./orchestry-filter-items-with-overflow-indicator.component";
import "./orchestry-filter-items.scss";
/**
 * Renders a list of filter items, grouped by category if provided.
 */
export var OrchestryFilterItems = function (_a) {
    var onClick = _a.onClick, groups = _a.groups, filters = _a.filters, hideGroupHeaders = _a.hideGroupHeaders, groupErrors = _a.groupErrors, isLoading = _a.isLoading, maxItems = _a.maxItems;
    var shouldRenderGroups = groups && groups.length > 0;
    var shouldRenderOverflowIndicator = maxItems && filters.length > maxItems;
    return shouldRenderGroups ? (React.createElement(React.Fragment, null, groups === null || groups === void 0 ? void 0 : groups.map(function (groupHeader) {
        var groupError = groupErrors === null || groupErrors === void 0 ? void 0 : groupErrors[groupHeader];
        return (React.createElement("div", { key: groupHeader, className: "orchestry-filter-item-groups" },
            !hideGroupHeaders && (React.createElement(Flex, { align: "center", h: 28 },
                React.createElement(OrchestryText, { lh: "18px", fz: "14", fw: "500", c: "var(--mantine-color-text-secondary-0)" }, groupHeader))),
            React.createElement("div", { className: "filter-items" }, filters.map(function (filterProps) {
                if (filterProps.group === groupHeader) {
                    return (React.createElement(OrchestryTooltip, { key: filterProps.value, multiline: true, w: 300, label: filterProps.tooltip },
                        React.createElement(OrchestryFilterItem, { isLoading: isLoading, key: filterProps.value, onClick: onClick, filterProps: filterProps })));
                }
                else {
                    return null;
                }
            })),
            groupError && React.createElement(OrchestryFormErrorText, { error: groupError })));
    }))) : shouldRenderOverflowIndicator ? (React.createElement(OrchestryFilterItemsWithOverflowIndicator, { filters: filters, isLoading: isLoading, maxItems: maxItems })) : (React.createElement("div", { className: "orchestry-filter-item-list" }, filters.map(function (filterProps) {
        return (React.createElement(OrchestryTooltip, { key: filterProps.value, multiline: true, w: 300, label: filterProps.tooltip },
            React.createElement(OrchestryFilterItem, { isLoading: isLoading, key: filterProps.value, onClick: onClick, filterProps: filterProps })));
    })));
};
