/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */
import * as React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { useLastLocation } from "react-router-last-location";
// import HomePage from "_metronic/pages/home/HomePage";
import { initializeIcons } from "@uifabric/icons";
import { I18nProvider, LayoutContextProvider } from "_metronic";
import Layout from "_metronic/layout/Layout";
import ErrorsPage from "_metronic/pages/errors/ErrorsPage";
import Login from "app/components/authentication/login";
import Logout from "app/components/authentication/logout";
import HomeInstaller from "app/components/homes/home-installer";
import OrchestryContext from "app/components/orchestry-context";
import OrchestryCheckSubscribed from "app/components/orchestry-context/orchestry-check-subscribed";
import OrchestryWebSockets from "app/components/orchestry-context/orchestry-websockets";
import TeamChrome from "app/components/teams-tabs/team-chrome";
import TeamsTabs from "app/pages/teams-tabs";
import LocalSessionService from "app/services/local-session";
import * as routerHelpers from "../router/RouterHelpers";
//https://linguinecode.com/post/code-splitting-react-router-with-react-lazy-and-react-suspense
import { useFeatureValue } from "@growthbook/growthbook-react";
import NewTeamChrome from "app/components/teams-tabs/team-chrome/new-team-chrome.component";
import RootPage from "app/pages";
import getParameterByName from "app/utils/getQueryStringParam";
import { HealthCheckPdfPage } from "appv2/pages/beacon/health-checks/health-check-pdf-page.component";
export var OrchestryRouterContext = React.createContext({});
//export const  Routes: React.FC<IProps> = React.memo((props) => {
export var Routes = withRouter(function (_a) {
    var history = _a.history, location = _a.location;
    var lastLocation = useLastLocation();
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var inTeams = React.useState(document.getElementsByTagName("html")[0].classList.contains("in-teams"))[0];
    var inStaticTeamsApp = React.useState(document.getElementsByTagName("html")[0].classList.contains("static-teams-app"))[0];
    var _b = React.useState(getParameterByName("redirect-url")), teamsRedirectUrl = _b[0], setTeamsRedirectUrl = _b[1];
    var enableMyTasksFeature = useFeatureValue("OE-4205_enable_my_tasks_feature", false);
    React.useEffect(function () {
        // alert(window.location.href);
        initializeIcons();
        maintenance();
    }, []);
    var orchestryRouterContext = {
        TeamsRedirectUrl: teamsRedirectUrl,
        RedirectDone: function () { return setTeamsRedirectUrl(null); }
    };
    React.useEffect(function () {
        // console.log("Save Last Location", lastLocation);
        // if(lastLocation !== undefined && lastLocation !== null){
        //     LocalSessionService.SetObject("orchestry-last-location", lastLocation);
        // }
        var currentLocation = location.pathname;
        var previousLocation = lastLocation !== undefined && lastLocation !== null ? lastLocation.pathname : "";
        //console.log("Router", currentLocation, previousLocation);
        if (currentLocation !== previousLocation) {
            //@ts-ignore
            //window.Appcues.page();
        }
        routerHelpers.saveLastLocation(lastLocation);
    });
    //const { isAuthorized } = useSelector((state) => state.authState);
    var isAuthorized = useSelector(function (state) { return state.authState; }).isAuthorized;
    //@ts-ignore
    var _c = useSelector(
    //@ts-ignore
    function (_a) {
        var auth = _a.auth, urls = _a.urls, menuConfig = _a.builder.menuConfig;
        return ({
            menuConfig: menuConfig,
            //isAuthorized: auth.user != null,
            userLastLocation: routerHelpers.getLastLocation()
        });
    }, shallowEqual), menuConfig = _c.menuConfig, userLastLocation = _c.userLastLocation;
    var maintenance = function () {
        LocalSessionService.RemoveMatchingObjects("profile_pic");
        LocalSessionService.RemoveMatchingObjects("office_profile_pic");
    };
    return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    React.createElement(OrchestryRouterContext.Provider, { value: orchestryRouterContext },
        React.createElement(I18nProvider, null,
            React.createElement(LayoutContextProvider, { history: history, menuConfig: menuConfig, isInTeams: inTeams },
                React.createElement(Switch, null,
                    React.createElement(Route, { path: "/websockets", component: OrchestryWebSockets }),
                    !isAuthorized ? (
                    // Render auth page when user at `/auth` and not authorized.
                    React.createElement(React.Fragment, null,
                        React.createElement(Redirect, { from: "/logout", to: "/auth/login" }),
                        React.createElement(Login, null))) : (
                    // Otherwise redirect to root page (`/`)
                    React.createElement(Redirect, { from: "/auth/login", to: userLastLocation })),
                    React.createElement(Route, { path: "/install", component: HomeInstaller }),
                    React.createElement(Route, { path: "/error", component: ErrorsPage }),
                    React.createElement(Route, { path: "/logout", component: Logout }),
                    React.createElement(Route, { exact: true, path: "/health-checks/pdfs/view/:healthCheckGuid", component: HealthCheckPdfPage }),
                    React.createElement(Route, { exact: true, path: "/partner-tools/tenants/view/:tenantGuid/health-checks/pdfs/view/:healthCheckGuid", component: HealthCheckPdfPage }),
                    isAuthorized ? (React.createElement(OrchestryContext, null,
                        React.createElement(OrchestryCheckSubscribed, null, inTeams === true && inStaticTeamsApp === true ? (enableMyTasksFeature ? (React.createElement(NewTeamChrome, null,
                            React.createElement(Switch, null,
                                React.createElement(Route, { path: "/tabs", component: TeamsTabs }),
                                React.createElement(Route, null,
                                    React.createElement(Layout, null,
                                        React.createElement(RootPage, null)))))) : (React.createElement(TeamChrome, null,
                            React.createElement(Switch, null,
                                React.createElement(Route, { path: "/tabs", component: TeamsTabs }),
                                React.createElement(Route, null,
                                    React.createElement(Layout, null,
                                        React.createElement(RootPage, null))))))) : (
                        // <React.Suspense fallback={<LayoutSplashScreen visible={true} />}>
                        React.createElement(Switch, null,
                            React.createElement(Route, { path: "/tabs", component: TeamsTabs }),
                            React.createElement(Route, null,
                                React.createElement(Layout, null,
                                    React.createElement(RootPage, null)))))
                        // </React.Suspense>
                        ))) : null)))));
});
