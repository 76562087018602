import { RequestsViewType } from "appv2/api/requests/requests-view.type";
import { useGetRequestsQuery } from "appv2/api/requests/requests.api";
import { defaultResultOptions } from "appv2/components/orchestry-pagination/orchestry-pagination.component";
import { useState } from "react";
import { useAllRequestsTable } from "./use-all-requests-table.hook";
export var useAllRequestsTab = function () {
    var _a, _b, _c, _d, _e, _f;
    var pageOptions = defaultResultOptions;
    var _g = useState(1), activePage = _g[0], setActivePage = _g[1];
    var _h = useState((_a = localStorage.getItem("orchestry-tasks-resultsPerPage")) !== null && _a !== void 0 ? _a : "25"), resultsPerPage = _h[0], setResultsPerPage = _h[1];
    var _j = useGetRequestsQuery({
        queryParameters: {
            CurrentPage: activePage,
            ResultsPerPage: Number(resultsPerPage)
        },
        view: RequestsViewType.All
    }), pagedRequests = _j.data, isLoading = _j.isLoading, isFetching = _j.isFetching, refetchAllRequests = _j.refetch;
    var requests = (_b = pagedRequests === null || pagedRequests === void 0 ? void 0 : pagedRequests.items) !== null && _b !== void 0 ? _b : [];
    var totalCount = (_d = (_c = pagedRequests === null || pagedRequests === void 0 ? void 0 : pagedRequests.items) === null || _c === void 0 ? void 0 : _c.length) !== null && _d !== void 0 ? _d : 0;
    var totalResults = (_f = (_e = pagedRequests === null || pagedRequests === void 0 ? void 0 : pagedRequests.paging) === null || _e === void 0 ? void 0 : _e.TotalResults) !== null && _f !== void 0 ? _f : 0;
    var pageTotal = totalResults > 0 ? Math.ceil(totalResults / Number(resultsPerPage)) : 0;
    var isPageLoading = isLoading;
    var isTableLoading = isPageLoading || isFetching;
    var table = useAllRequestsTable(requests, isLoading, isTableLoading).table;
    return {
        isTableLoading: isTableLoading,
        table: table,
        totalCount: totalCount,
        pageTotal: pageTotal,
        resultsPerPage: resultsPerPage,
        setResultsPerPage: setResultsPerPage,
        setActivePage: setActivePage,
        activePage: activePage,
        pageOptions: pageOptions,
        refetchAllRequests: refetchAllRequests
    };
};
