var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { getReadableDateRange } from "app/utils/dateUtils";
import { TaskAction } from "appv2/api/orchestry-tasks/task-action.type";
import { TaskType } from "appv2/api/orchestry-tasks/task-type.type";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { SelectedDate } from "appv2/components/orchestry-date-filter/selected-date-type";
import { DATE_RANGE_FULLY_SELECTED_ITEMS_COUNT } from "appv2/components/orchestry-date-picker-input/orchestry-date-picker-input.component";
import moment from "moment";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { TYPE_TO_COLOR_MAPPING, TYPE_TO_ICON_MAPPING } from "./orchestry-task-card.utils";
import { TasksFilterGroup } from "./orchestry-tasks-filter-group.type";
export var useOrchestryTasksFilters = function () {
    var intl = useIntl();
    var ORCHESTRY_TASK_TYPE_FILTERS_LIST = useMemo(function () { return [
        {
            type: "selectable",
            title: intl.formatMessage({ id: "pages.home.my_tasks.card.badge.".concat(TaskType.GuestRequest), defaultMessage: "Guest Request" }),
            value: TaskType.GuestRequest,
            group: TasksFilterGroup.TaskType,
            selected: false,
            icon: TYPE_TO_ICON_MAPPING[TaskType.GuestRequest],
            color: TYPE_TO_COLOR_MAPPING[TaskType.GuestRequest]
        },
        {
            type: "selectable",
            title: intl.formatMessage({ id: "pages.home.my_tasks.card.badge.".concat(TaskType.GuestReview), defaultMessage: "Guest Review" }),
            value: TaskType.GuestReview,
            group: TasksFilterGroup.TaskType,
            selected: false,
            icon: TYPE_TO_ICON_MAPPING[TaskType.GuestReview],
            color: TYPE_TO_COLOR_MAPPING[TaskType.GuestReview]
        },
        {
            type: "selectable",
            title: intl.formatMessage({ id: "pages.home.my_tasks.card.badge.".concat(TaskType.ProvisioningApproval), defaultMessage: "Provisioning" }),
            value: TaskType.ProvisioningApproval,
            group: TasksFilterGroup.TaskType,
            selected: false,
            icon: TYPE_TO_ICON_MAPPING[TaskType.ProvisioningApproval],
            color: TYPE_TO_COLOR_MAPPING[TaskType.ProvisioningApproval]
        },
        {
            type: "selectable",
            title: intl.formatMessage({ id: "pages.home.my_tasks.card.badge.".concat(TaskType.HealthCheckReview), defaultMessage: "Health Check" }),
            value: TaskType.HealthCheckReview,
            group: TasksFilterGroup.TaskType,
            selected: false,
            icon: TYPE_TO_ICON_MAPPING[TaskType.HealthCheckReview],
            color: TYPE_TO_COLOR_MAPPING[TaskType.HealthCheckReview]
        },
        {
            type: "selectable",
            title: intl.formatMessage({ id: "pages.home.my_tasks.card.badge.".concat(TaskType.ArchivalApproval), defaultMessage: "Archival" }),
            value: TaskType.ArchivalApproval,
            group: TasksFilterGroup.TaskType,
            selected: false,
            icon: TYPE_TO_ICON_MAPPING[TaskType.ArchivalApproval],
            color: TYPE_TO_COLOR_MAPPING[TaskType.ArchivalApproval]
        }
    ]; }, [intl]);
    var ORCHESTRY_TASK_ACTION_FILTERS_LIST = useMemo(function () { return [
        {
            type: "selectable",
            title: intl.formatMessage({ id: "pages.home.my_tasks.card.label.approval_required", defaultMessage: "Approval Required" }),
            value: TaskAction.ApprovalRequired,
            group: TasksFilterGroup.Action,
            selected: false,
            icon: ORCH_ICONS.timer,
            color: "var(--mantine-color-error-9)"
        },
        {
            type: "selectable",
            title: intl.formatMessage({ id: "pages.home.my_tasks.card.label.review_required", defaultMessage: "Review Required" }),
            value: TaskAction.ReviewRequired,
            group: TasksFilterGroup.Action,
            selected: false,
            icon: ORCH_ICONS.reviewProcess,
            color: "var(--mantine-color-error-9)"
        }
    ]; }, [intl]);
    var _a = useState(ORCHESTRY_TASK_TYPE_FILTERS_LIST), taskTypeFilters = _a[0], setTaskTypeFilters = _a[1];
    var _b = useState(ORCHESTRY_TASK_ACTION_FILTERS_LIST), actionFilters = _b[0], setActionFilters = _b[1];
    var _c = useState(undefined), selectedCreatedDate = _c[0], setSelectedCreatedDate = _c[1];
    var _d = useState([null, null]), createdDateDateRange = _d[0], setCreatedDateDateRange = _d[1];
    var _e = useState([]), appliedFilters = _e[0], setAppliedFilters = _e[1];
    var onApplyFilters = function (taskTypeFilters, actionFilters) {
        setTaskTypeFilters(taskTypeFilters);
        setActionFilters(actionFilters);
        var isCreatedDateFilterEmpty = createdDateDateRange.filter(function (f) { return !!f; }).length < DATE_RANGE_FULLY_SELECTED_ITEMS_COUNT;
        //Use local time for these but start and end of day. This helps it so the days displayed in the query context are correct.
        var startDate = createdDateDateRange[0]
            ? moment(createdDateDateRange[0])
                .startOf("day")
                .toISOString()
            : "";
        var endDate = createdDateDateRange[1]
            ? moment(createdDateDateRange[1])
                .endOf("day")
                .toISOString()
            : "";
        var createdDateFilter = {
            icon: ORCH_ICONS.calendar,
            type: "input",
            inputFilterType: "date-range",
            value: [startDate, endDate],
            title: getReadableDateRange(startDate, endDate),
            group: TasksFilterGroup.CreatedDate,
            selected: !isCreatedDateFilterEmpty,
            color: "var(--mantine-color-gray-9)"
        };
        setAppliedFilters(__spreadArray(__spreadArray(__spreadArray([], taskTypeFilters, true), actionFilters, true), [createdDateFilter], false));
    };
    var clearAllFilters = function () {
        setTaskTypeFilters(function (filters) {
            return filters.map(function (filter) {
                return __assign(__assign({}, filter), { selected: false });
            });
        });
        setActionFilters(function (filters) {
            return filters.map(function (filter) {
                return __assign(__assign({}, filter), { selected: false });
            });
        });
        setCreatedDateDateRange([null, null]);
        setSelectedCreatedDate(undefined);
        setAppliedFilters([]);
    };
    var onClearActionFilters = function () {
        setActionFilters(function (filters) {
            return filters.map(function (filter) {
                return __assign(__assign({}, filter), { selected: false });
            });
        });
        setAppliedFilters(appliedFilters.filter(function (filter) { return filter.group !== TasksFilterGroup.Action; }));
    };
    var onClearTaskTypeFilters = function () {
        setTaskTypeFilters(function (filters) {
            return filters.map(function (filter) {
                return __assign(__assign({}, filter), { selected: false });
            });
        });
        setAppliedFilters(appliedFilters.filter(function (filter) { return filter.group !== TasksFilterGroup.TaskType; }));
    };
    var onClearCreatedDateFilter = function () {
        setCreatedDateDateRange([null, null]);
        setSelectedCreatedDate(undefined);
        setAppliedFilters(appliedFilters.filter(function (filter) { return filter.group !== TasksFilterGroup.CreatedDate; }));
    };
    var removeFilter = function (filter) {
        var newAppliedFilters = appliedFilters.map(function (appliedFilter) { return (appliedFilter.title === filter.title ? __assign(__assign({}, appliedFilter), { selected: false }) : appliedFilter); });
        setAppliedFilters(newAppliedFilters);
        var newTaskTypeFilters = taskTypeFilters.map(function (f) { return (f.title === filter.title ? __assign(__assign({}, f), { selected: false }) : f); });
        setTaskTypeFilters(newTaskTypeFilters);
        var newActionFilters = actionFilters.map(function (f) { return (f.title === filter.title ? __assign(__assign({}, f), { selected: false }) : f); });
        setActionFilters(newActionFilters);
        if (filter.group === TasksFilterGroup.CreatedDate) {
            setCreatedDateDateRange([null, null]);
            setSelectedCreatedDate(undefined);
        }
    };
    var onSelectedCreatedDateChange = function (startDate) {
        setSelectedCreatedDate(startDate);
        switch (startDate) {
            case SelectedDate.Within30Days:
                setCreatedDateDateRange([new Date(new Date().setDate(new Date().getDate() - 30)), new Date()]);
                break;
            case SelectedDate.Within60Days:
                setCreatedDateDateRange([new Date(new Date().setDate(new Date().getDate() - 60)), new Date()]);
                break;
            case SelectedDate.Within90Days:
                setCreatedDateDateRange([new Date(new Date().setDate(new Date().getDate() - 90)), new Date()]);
                break;
            case SelectedDate.Within1Year:
                setCreatedDateDateRange([new Date(new Date().setFullYear(new Date().getFullYear() - 1)), new Date()]);
                break;
            default:
                setCreatedDateDateRange([null, null]);
                break;
        }
    };
    var onCreatedDateDateRangePickerChange = function (value) {
        setCreatedDateDateRange(value);
    };
    var FILTER_GROUPS = [TasksFilterGroup.TaskType, TasksFilterGroup.Action, TasksFilterGroup.CreatedDate];
    var FILTER_GROUP_TITLES = [
        intl.formatMessage({ id: "pages.home.my_tasks.filters.type", defaultMessage: "Type" }),
        intl.formatMessage({ id: "pages.home.my_tasks.filters.action", defaultMessage: "Action" }),
        intl.formatMessage({ id: "pages.home.my_tasks.filters.date_created", defaultMessage: "Date Created" })
    ];
    return {
        ORCHESTRY_TASK_TYPE_FILTERS_LIST: ORCHESTRY_TASK_TYPE_FILTERS_LIST,
        ORCHESTRY_TASK_ACTION_FILTERS_LIST: ORCHESTRY_TASK_ACTION_FILTERS_LIST,
        taskTypeFilters: taskTypeFilters,
        setTaskTypeFilters: setTaskTypeFilters,
        actionFilters: actionFilters,
        setActionFilters: setActionFilters,
        appliedFilters: appliedFilters,
        setAppliedFilters: setAppliedFilters,
        clearAllFilters: clearAllFilters,
        removeFilter: removeFilter,
        onApplyFilters: onApplyFilters,
        FILTER_GROUPS: FILTER_GROUPS,
        FILTER_GROUP_TITLES: FILTER_GROUP_TITLES,
        selectedCreatedDate: selectedCreatedDate,
        createdDateDateRange: createdDateDateRange,
        onSelectedCreatedDateChange: onSelectedCreatedDateChange,
        onCreatedDateDateRangePickerChange: onCreatedDateDateRangePickerChange,
        onClearActionFilters: onClearActionFilters,
        onClearTaskTypeFilters: onClearTaskTypeFilters,
        onClearCreatedDateFilter: onClearCreatedDateFilter
    };
};
