/**
 * Task Status
 */
export var TaskStatus;
(function (TaskStatus) {
    /**
     * The task is pending.
     */
    TaskStatus["Pending"] = "Pending";
    /**
     * The task has been completed.
     */
    TaskStatus["Completed"] = "Completed";
})(TaskStatus || (TaskStatus = {}));
