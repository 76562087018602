import { Group } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryFontIcon } from "appv2/components/orchestry-font-icon/orchestry-font-icon.component";
import { OrchestryHighlight } from "appv2/components/orchestry-highlight/orchestry-highlight.component";
import { OrchestryText } from "appv2/components/orchestry-text/orchestry-text.component";
import { OrchestryWorkspaceIcon } from "appv2/components/orchestry-workspace-icon/orchestry-workspace-icon.component";
import React from "react";
import { MyRequestsStatusPill } from "./my-requests-status-pill.component";
import { MyRequestsTypePill } from "./my-requests-type-pill.component";
export var MyRequestsTableNameColumn = function (row, highlightSearchWords) {
    var name = row.getValue("Name");
    var icon = row.getValue("Image");
    return (React.createElement(Group, { gap: 12, wrap: "nowrap" },
        React.createElement(OrchestryWorkspaceIcon, { customSize: "40px", borderRadius: "4px", svgSize: "xxl", variant: "40px", icon: icon, workspaceName: name }),
        React.createElement(OrchestryHighlight, { overFlowEllipsisNthLine: 2, c: "text-primary.0", size: "14px", fw: 500, lh: "20px", highlight: highlightSearchWords !== null && highlightSearchWords !== void 0 ? highlightSearchWords : "" }, name)));
};
export var MyRequestsTableTemplatesColumn = function (Template) {
    if (!Template) {
        return null;
    }
    return (React.createElement(Group, { gap: 4, wrap: "nowrap" },
        React.createElement(OrchestryFontIcon, { color: "var(--mantine-color-gray-5)", fontSize: "16px", lineHeight: "16px", iconClassName: ORCH_ICONS.layoutTemplate }),
        React.createElement(OrchestryText, { overFlowEllipsisNthLine: 2, c: "text-primary.0", size: "14px", fw: 400, lh: "20px" }, Template.Title)));
};
export var MyRequestsTableStatusColumn = function (value) {
    return React.createElement(MyRequestsStatusPill, { status: value });
};
export var MyRequestsTableTypeColumn = function (row) {
    var type = row.getValue("RequestType");
    var templateType = row.getValue("SharePointTemplateType");
    if (type === "SharePoint") {
        return templateType === "ModernCommunications" ? React.createElement(MyRequestsTypePill, { type: "CommunicationSite" }) : React.createElement(MyRequestsTypePill, { type: "TeamSite" });
    }
    return React.createElement(MyRequestsTypePill, { type: type !== null && type !== void 0 ? type : "" });
};
