/**
 * Task Type
 */
export var TaskType;
(function (TaskType) {
    /**
     * A Workspace Review task.
     */
    TaskType["WorkspaceReview"] = "WorkspaceReview";
    /**
     * A Guest Request task.
     */
    TaskType["GuestRequest"] = "GuestRequest";
    /**
     * A Guest Review task.
     */
    TaskType["GuestReview"] = "GuestReview";
    /**
     * An Archival Approval task.
     */
    TaskType["ArchivalApproval"] = "ArchivalApproval";
    /**
     * A Provisioning Approval task.
     */
    TaskType["ProvisioningApproval"] = "ProvisioningApproval";
    /**
     * A Health Check Review task.
     */
    TaskType["HealthCheckReview"] = "HealthCheckReview";
})(TaskType || (TaskType = {}));
