import { Skeleton } from "@mantine/core";
import React from "react";
import { OrchestryAvatar } from "../orchestry-avatar/orchestry-avatar.component";
import { OrchestrySVGIcon } from "../orchestry-svg-icon/orchestry-svg-icon.component";
export var OrchestryWorkspaceIcon = function (_a) {
    var icon = _a.icon, workspaceName = _a.workspaceName, variant = _a.variant, isLoading = _a.isLoading, svgSize = _a.svgSize, customSize = _a.customSize, borderRadius = _a.borderRadius;
    var isSquare = !icon;
    if (isLoading) {
        //Note: this should copy SIZE_TO_PX_MAPPING logic in OrchestrySVGIcon
        return React.createElement(Skeleton, { circle: !isSquare, width: customSize ? customSize : 16, height: customSize ? customSize : 16 });
    }
    return icon ? (React.createElement(OrchestrySVGIcon, { customSize: customSize, borderRadius: borderRadius, isLoading: isLoading, iconNeutral: icon, size: svgSize !== null && svgSize !== void 0 ? svgSize : "sm" })) : (React.createElement(OrchestryAvatar, { src: icon, isSquare: !icon, variant: variant, userDisplayName: workspaceName }));
};
