import { dateOccuredInLastXHours } from "app/utils/dateUtils";
import isInTeamsApp from "app/utils/isInTeams";
import { TaskType } from "appv2/api/orchestry-tasks/task-type.type";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { TYPE_TO_COLOR_MAPPING, TYPE_TO_COMPLETED_LOCALIZATION_ID_LABEL_MAPPING, TYPE_TO_ICON_MAPPING, TYPE_TO_LINK_MAPPING, TYPE_TO_LOCALIZATION_ID_ACTION_BUTTON_MAPPING, TYPE_TO_LOCALIZATION_ID_LABEL_MAPPING, TYPE_TO_TEAMS_TAB_LINK_MAPPING } from "./orchestry-task-card.utils";
export var useOrchestryTaskCard = function (task) {
    var _a, _b, _c, _d;
    var intl = useIntl();
    var isInTeams = isInTeamsApp();
    var history = useHistory();
    var label = intl.formatMessage({ id: (_a = TYPE_TO_LOCALIZATION_ID_LABEL_MAPPING[task.TaskType]) !== null && _a !== void 0 ? _a : "undefined", defaultMessage: "Undefined" });
    var completedLabel = intl.formatMessage({ id: (_b = TYPE_TO_COMPLETED_LOCALIZATION_ID_LABEL_MAPPING[task.TaskType]) !== null && _b !== void 0 ? _b : "undefined", defaultMessage: "Review Completed" });
    var actionButtonText = intl.formatMessage({ id: (_c = TYPE_TO_LOCALIZATION_ID_ACTION_BUTTON_MAPPING[task.TaskType]) !== null && _c !== void 0 ? _c : "undefined", defaultMessage: "Undefined" });
    var actionButtonLinkTemplate = isInTeamsApp() ? TYPE_TO_TEAMS_TAB_LINK_MAPPING[task.TaskType] : TYPE_TO_LINK_MAPPING[task.TaskType];
    var showDeletedHealthCheckLabel = (task === null || task === void 0 ? void 0 : task.TaskType) === TaskType.HealthCheckReview && (task === null || task === void 0 ? void 0 : task.TargetName) == null;
    var actionButtonLink = actionButtonLinkTemplate === null || actionButtonLinkTemplate === void 0 ? void 0 : actionButtonLinkTemplate.replace(":guid", task.ResourceUniqueId);
    var badgeText = intl.formatMessage({ id: "pages.home.my_tasks.card.badge.".concat(task.TaskType), defaultMessage: "Undefined" });
    var badgeIcon = TYPE_TO_ICON_MAPPING[task.TaskType];
    var badgeColor = TYPE_TO_COLOR_MAPPING[task.TaskType];
    var taskWasCreatedInLast48Hours = dateOccuredInLastXHours(new Date((_d = task.CreatedDate) !== null && _d !== void 0 ? _d : ""), 24);
    var onActionButtonClick = useCallback(function () {
        isInTeams ? history.push(actionButtonLink) : window.open(actionButtonLink, "_self");
    }, [history, actionButtonLink, isInTeams]);
    return {
        intl: intl,
        label: label,
        completedLabel: completedLabel,
        actionButtonText: actionButtonText,
        badgeText: badgeText,
        badgeIcon: badgeIcon,
        badgeColor: badgeColor,
        isNew: taskWasCreatedInLast48Hours,
        actionButtonLink: actionButtonLink,
        showDeletedHealthCheckLabel: showDeletedHealthCheckLabel,
        onActionButtonClick: onActionButtonClick
    };
};
