import { Group } from "@mantine/core";
import { getReadableDate } from "app/utils/dateUtils";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import React from "react";
import { useIntl } from "react-intl";
import { OrchestryFontIcon } from "../orchestry-font-icon/orchestry-font-icon.component";
import { OrchestryText } from "../orchestry-text/orchestry-text.component";
/**
 * Renders a date column for the OrchestryDataTable
 *
 * @param date The date to display
 * @param isLoading The loading state of the date
 */
export var OrchestryDataTableDateColumn = function (date, isLoading) {
    if (!date) {
        return null;
    }
    return (React.createElement(Group, { gap: 4 },
        React.createElement(OrchestryFontIcon, { color: "var(--mantine-color-gray-6)", fontSize: "16px", lineHeight: "16px", iconClassName: ORCH_ICONS.calendar }),
        React.createElement(OrchestryText, { isLoading: isLoading, c: "text-secondary.0", size: "14px", fw: 400, lh: "20px" }, getReadableDate(date))));
};
/**
 * Renders a date column for the OrchestryDataTable
 *
 * @param date The date to display
 * @param isLoading The loading state of the date
 */
export var OrchestryDataTableDateRangeColumn = function (startDate, endDate, isLoading) {
    var intl = useIntl();
    if (!startDate || !endDate) {
        return null;
    }
    var text = intl.formatMessage({ id: "date_to_date", defaultMessage: "{startDate} to {endDate}" }, { startDate: getReadableDate(startDate), endDate: getReadableDate(endDate) });
    return (React.createElement(OrchestryText, { isLoading: isLoading, c: "text-secondary.0", size: "14px", fw: 400, lh: "20px" }, text));
};
