/**
 * Returns true if there is more data to load. Calculates this based on the current page item length, the page size, and the total count.
 * @param data The data to check.
 */
export var hasMoreData = function (data) {
    var _a;
    if (!data) {
        return false;
    }
    var currentCount = data.PageSize * (data.Page - 1) + ((_a = data.Items) === null || _a === void 0 ? void 0 : _a.length);
    return data.TotalCount > currentCount;
};
/**
 * Groups filters by their group and calls generateFilterExpression for each group.
 *
 * @param filters The filters to generate an expression for.
 */
export var generateGroupedFilterExpression = function (filters) {
    var groupedFilters = filters.reduce(function (groups, filter) {
        var key = filter.group;
        if (!groups[key]) {
            groups[key] = [];
        }
        groups[key].push(filter);
        return groups;
    }, {});
    return Object.entries(groupedFilters)
        .map(function (_a) {
        var group = _a[0], filters = _a[1];
        return generateFilterExpression(filters, group);
    })
        .filter(function (expression) { return expression.trim() !== ""; }) // remove empty expressions that are generated when no filters have values
        .join(" && ");
};
/**
 * Generates an expression for the given group of filters.
 *
 * @param filters The filters to generate an expression for.
 * @param group The group to generate the expression for.
 */
var groupFilters = function (filters) {
    return filters.reduce(function (groups, filter) {
        var key = filter.group;
        if (!groups[key]) {
            groups[key] = [];
        }
        groups[key].push(filter);
        return groups;
    }, {});
};
/**
 * Converts the given date to an ISO string if it is a Date object. Needed because sometimes the date is a string
 * and sometimes it is a Date object.
 *
 * @param date The date to convert to an ISO string if it is a Date object.
 */
var getDateIsoString = function (date) {
    return date instanceof Date ? date.toISOString().split("T")[0] : date;
};
/**
 * Generates an expression for the given group of filters.
 *
 * @param filters The filters to generate an expression for.
 * @param group The group to generate the expression for.
 *
 */
var generateExpression = function (group, filters, groupName) {
    var expressions = filters.map(function (filter) {
        var _a, _b, _c, _d;
        if (filter.type === "input" && filter.inputFilterType === "date-range" && filter.selected && group === groupName) {
            if (!filter.value || filter.value.length === 0) {
                return ""; //empty filters should not be included in the expression
            }
            var startDate = ((_a = filter.value) === null || _a === void 0 ? void 0 : _a[0]) ? getDateIsoString((_b = filter.value) === null || _b === void 0 ? void 0 : _b[0]) : "";
            var endDate = ((_c = filter.value) === null || _c === void 0 ? void 0 : _c[1]) ? getDateIsoString((_d = filter.value) === null || _d === void 0 ? void 0 : _d[1]) : "";
            return "".concat(groupName, " >= \"").concat(startDate, "\" && ").concat(groupName, " <= \"").concat(endDate, "\"");
        }
        if (filter.value && filter.selected && group === groupName && filter.isInArray) {
            return "".concat(groupName, ".Any(it == \"").concat(filter.value, "\")");
        }
        if (filter.value && filter.selected && group === groupName) {
            return "".concat(groupName, " == \"").concat(filter.value, "\"");
        }
        return "";
    });
    var finalExpressions = expressions.filter(function (value) { return value.length > 0; });
    return finalExpressions.length > 0 ? "(".concat(finalExpressions.join(" || "), ")") : "";
};
/**
 * Generates a filter expression from the given filters. The expression is a string that can be used to filter data.
 * It is generated by grouping the filters by group and then generating an expression for each group.
 *
 * @param filters The filters to generate an expression for.
 * @param groupName The group name to generate the expression for.
 */
export var generateFilterExpression = function (filters, groupName) {
    var groupedFilters = groupFilters(filters);
    var groupExpressions = Object.entries(groupedFilters).map(function (_a) {
        var group = _a[0], filters = _a[1];
        return generateExpression(group, filters, groupName);
    });
    var finalGroupExpressions = groupExpressions.filter(function (groupExpression) { return groupExpression.length > 0; });
    var finalExpression = finalGroupExpressions.join(" && ");
    return finalExpression;
};
/**
 * Combines the given string filters into a single string.
 *
 * @param stringFilters The string filters to combine.
 */
export var combineFilters = function (stringFilters) {
    return stringFilters.filter(function (filter) { return filter.length > 0; }).join(" && ");
};
/**
 * Gets the order by expression from the given sorting object.
 *
 * @param sorting The sorting object to get the expression from.
 */
export var getOrderByExpression = function (sortingState) {
    if (!sortingState || sortingState.length === 0) {
        return "";
    }
    return sortingState.map(function (sortObj) { return "".concat(sortObj.id, " ").concat(sortObj.desc ? "desc" : "asc"); }).join(", ");
};
