import { OrchestryDataTableDateColumn } from "appv2/components/orchestry-data-table/orchestry-data-table-date-column.component";
import { useCustomMantineReactTable } from "appv2/components/orchestry-data-table/use-custom-mantine-react-table.hook";
import { useMapLocaleToMRTLocalization } from "appv2/components/orchestry-data-table/use-map-local-to-MRT-localization";
import { OrchestryNoRecordsFound } from "appv2/components/orchestry-no-records-found/orchestry-no-records-found.component";
import { useIsMobile } from "appv2/hooks/use-is-mobile.hook";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { MyRequestsTableNameColumn, MyRequestsTableStatusColumn, MyRequestsTableTemplatesColumn, MyRequestsTableTypeColumn } from "./my-requests-table-columns";
export var useMyRequestsTable = function (pagedRequests, isPageLoading, isTableLoading) {
    //highlightSearchWords: string[]
    var intl = useIntl();
    var isMobile = useIsMobile();
    var localization = useMapLocaleToMRTLocalization();
    var history = useHistory();
    var onMyRequestClicked = useCallback(function (uniqueId) {
        if (!isPageLoading && !isTableLoading) {
            history.push("/requests/view/".concat(uniqueId));
        }
    }, [isPageLoading, isTableLoading, history]);
    var columns = useMemo(function () { return [
        {
            accessorKey: "Name",
            header: intl.formatMessage({ id: "page.home.tabs.my_requests.table.header.name", defaultMessage: "Name" }),
            enableSorting: false,
            Cell: function (_a) {
                var row = _a.row;
                return MyRequestsTableNameColumn(row);
            },
            grow: true,
            minSize: isMobile ? undefined : 300
        },
        {
            accessorKey: "RequestedDate",
            header: intl.formatMessage({ id: "page.home.tabs.my_requests.table.header.date_requested", defaultMessage: "Date Requested" }),
            enableSorting: false,
            Cell: function (_a) {
                var cell = _a.cell;
                return OrchestryDataTableDateColumn(cell.getValue());
            },
            grow: true,
            minSize: isMobile ? undefined : 150
        },
        {
            accessorKey: "RequestType",
            header: intl.formatMessage({ id: "page.home.tabs.my_requests.table.header.type", defaultMessage: "Type" }),
            enableSorting: false,
            Cell: function (_a) {
                var row = _a.row;
                return MyRequestsTableTypeColumn(row);
            },
            grow: true,
            minSize: isMobile ? undefined : 180
        },
        {
            accessorKey: "Template",
            header: intl.formatMessage({ id: "page.home.tabs.my_requests.table.header.templates", defaultMessage: "Templates" }),
            enableSorting: false,
            Cell: function (_a) {
                var cell = _a.cell;
                return MyRequestsTableTemplatesColumn(cell.getValue());
            },
            grow: true,
            minSize: isMobile ? undefined : 300
        },
        {
            accessorKey: "Status",
            header: intl.formatMessage({ id: "page.home.tabs.my_requests.table.header.status", defaultMessage: "Status" }),
            enableSorting: false,
            Cell: function (_a) {
                var cell = _a.cell;
                return MyRequestsTableStatusColumn(cell.getValue());
            },
            grow: true,
            minSize: isMobile ? undefined : 150
        },
        {
            accessorKey: "Image",
            header: ""
        },
        {
            accessorKey: "RequestorPhoto",
            header: ""
        },
        {
            accessorKey: "GUID",
            header: ""
        },
        {
            accessorKey: "SharePointTemplateType",
            header: ""
        }
    ]; }, [intl, isMobile]);
    var _a = useState({
        Image: false,
        RequestorPhoto: false,
        GUID: false,
        SharePointTemplateType: false
    }), columnVisibility = _a[0], setColumnVisibility = _a[1];
    useEffect(function () {
        if (isMobile) {
            setColumnVisibility({
                Name: true,
                RequestedDate: false,
                RequestType: false,
                Template: false,
                // Requestor: false,
                Status: true,
                Image: false,
                RequestorPhoto: false,
                GUID: false,
                SharePointTemplateType: false
            });
        }
        else {
            setColumnVisibility({
                Image: false,
                RequestorPhoto: false,
                GUID: false,
                SharePointTemplateType: false
            });
        }
    }, [isMobile]);
    var onColumnVisibilityChange = function (newVisibility) {
        setColumnVisibility(newVisibility);
    };
    var onRowClicked = useCallback(function (row) {
        onMyRequestClicked(row.getValue("GUID"));
    }, [onMyRequestClicked]);
    var getRowStyle = useCallback(function (row) {
        return { cursor: "pointer" };
    }, []);
    var table = useCustomMantineReactTable({
        layoutMode: "grid",
        onColumnVisibilityChange: onColumnVisibilityChange,
        initialState: {
            columnVisibility: columnVisibility,
            isLoading: true
        },
        mantineLoadingOverlayProps: {
            visible: false
        },
        state: { columnVisibility: columnVisibility, isLoading: isTableLoading },
        columns: columns,
        data: pagedRequests !== null && pagedRequests !== void 0 ? pagedRequests : [],
        renderEmptyRowsFallback: OrchestryNoRecordsFound,
        mantineTableContainerProps: {
            style: { maxHeight: "630px" }
        },
        mantineTableBodyRowProps: function (_a) {
            var row = _a.row;
            return ({
                onClick: function () { return onRowClicked(row); },
                style: getRowStyle(row)
            });
        },
        localization: localization
    }, isPageLoading ? undefined : !(pagedRequests === null || pagedRequests === void 0 ? void 0 : pagedRequests.length));
    return {
        table: table
    };
};
