import { Collapse, Group, Stack } from "@mantine/core";
import { OrchestryPagination } from "appv2/components/orchestry-pagination/orchestry-pagination.component";
import { OrchestryQueryContext } from "appv2/components/orchestry-query-context/orchestry-query-context.component";
import { OrchestrySearchBox } from "appv2/components/orchestry-searchbox/orchestry-searchbox.component";
import { OrchestryTabs } from "appv2/components/orchestry-tabs/orchestry-tabs.component";
import React from "react";
import { OrchestryTaskCardsSkeletons } from "./orchestry-task-cards-skeletons";
import { OrchestryTaskCards } from "./orchestry-task-cards.component";
import { OrchestryTasksFilterSelector } from "./orchestry-tasks-filter-selector.component";
import { useOrchestryTasksTab } from "./use-orchestry-tasks-tab.hook";
export var OrchestryTasksTab = function () {
    var _a = useOrchestryTasksTab(), intl = _a.intl, isLoading = _a.isLoading, isCardsLoading = _a.isCardsLoading, tasks = _a.tasks, totalCount = _a.totalCount, pagedTotal = _a.pagedTotal, searchTextInputValue = _a.searchTextInputValue, highlightSearchWords = _a.highlightSearchWords, onSearchBoxChange = _a.onSearchBoxChange, onSearchBoxKeyDown = _a.onSearchBoxKeyDown, onSearchBoxXClicked = _a.onSearchBoxXClicked, activePage = _a.activePage, setActivePage = _a.setActivePage, resultsPerPage = _a.resultsPerPage, setResultsPerPage = _a.setResultsPerPage, pageOptions = _a.pageOptions, taskTypeFilters = _a.taskTypeFilters, actionFilters = _a.actionFilters, appliedFilters = _a.appliedFilters, clearAllFilters = _a.clearAllFilters, removeFilter = _a.removeFilter, onApplyFilters = _a.onApplyFilters, FILTER_GROUPS = _a.FILTER_GROUPS, FILTER_GROUP_TITLES = _a.FILTER_GROUP_TITLES, selectedCreatedDate = _a.selectedCreatedDate, createdDateDateRange = _a.createdDateDateRange, onSelectedCreatedDateChange = _a.onSelectedCreatedDateChange, onCreatedDateDateRangePickerChange = _a.onCreatedDateDateRangePickerChange, onClearActionFilters = _a.onClearActionFilters, onClearTaskTypeFilters = _a.onClearTaskTypeFilters, onClearCreatedDateFilter = _a.onClearCreatedDateFilter, sections = _a.sections, section = _a.section, onSectionChange = _a.onSectionChange, showAllCaughtUp = _a.showAllCaughtUp, showNoRecordsFound = _a.showNoRecordsFound;
    return (React.createElement(Stack, { gap: 0 },
        React.createElement(Stack, { gap: 16 },
            React.createElement(Group, { gap: 0, mt: 24, justify: "space-between" },
                React.createElement(OrchestryTabs, { variant: "default", tabCountVariant: "pills", isLoading: isLoading, showCount: true, tabs: sections, tab: section, onTabChange: onSectionChange }),
                React.createElement(Group, { gap: 8, justify: "right" },
                    React.createElement(OrchestrySearchBox, { skeletonWidth: 320, isLoading: isLoading, placeholder: intl.formatMessage({ id: "pages.home.my_tasks.search_placeholder", defaultMessage: "Search for a Task" }), tooltipLabel: intl.formatMessage({ id: "pages.home.my_tasks.search_tooltip", defaultMessage: "Click to perform a search within your Tasks" }), onXClicked: onSearchBoxXClicked, onChange: onSearchBoxChange, value: searchTextInputValue, onKeyDown: onSearchBoxKeyDown, radius: "6px" }),
                    React.createElement(OrchestryTasksFilterSelector, { isLoading: isLoading, taskTypeFilters: taskTypeFilters, actionFilters: actionFilters, onApply: onApplyFilters, onClear: clearAllFilters, selectedDate: selectedCreatedDate, onSelectedDateChange: onSelectedCreatedDateChange, customDateRange: createdDateDateRange, onDateRangePickerChange: onCreatedDateDateRangePickerChange, onClearActionFilters: onClearActionFilters, onClearTaskTypeFilters: onClearTaskTypeFilters, onClearCreatedDateFilter: onClearCreatedDateFilter }))),
            React.createElement(Collapse, { transitionDuration: 250, transitionTimingFunction: "ease-in", in: appliedFilters.some(function (filter) { return filter.selected; }) },
                React.createElement(OrchestryQueryContext, { filterGroups: FILTER_GROUPS, filterGroupTitles: FILTER_GROUP_TITLES, appliedFilters: appliedFilters, totalResults: totalCount !== null && totalCount !== void 0 ? totalCount : 0, removeFilter: removeFilter, clearAllFilters: clearAllFilters }))),
        isCardsLoading ? (React.createElement(OrchestryTaskCardsSkeletons, { selectedSection: section })) : (React.createElement(OrchestryTaskCards, { selectedSection: section, highlightSearchWords: highlightSearchWords, showAllCaughtUp: showAllCaughtUp, showNoRecordsFound: showNoRecordsFound, tasks: tasks })),
        React.createElement(OrchestryPagination, { onResultsPerPageChange: function (value) {
                setResultsPerPage(value);
                localStorage.setItem("orchestry-tasks-resultsPerPage", value);
            }, resultsPerPage: resultsPerPage, activePage: activePage, setactivePage: setActivePage, totalCount: totalCount, pageTotal: pagedTotal, resultOptions: pageOptions, isLoading: isLoading })));
};
