var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AsyncWait } from "app/components/common/async";
import DynamicModal from "app/components/common/dynamic-modal";
import * as PageHeader from "app/components/common/page-header";
import { Portlet, PortletBody } from "app/components/common/portlet";
import ProgressReport from "app/components/common/progress-report";
import { SubtitleButton } from "app/components/common/subtitle";
import TeamLink from "app/components/common/team-link";
import RequestStatus from "app/components/requests/request-status";
import JobService from "app/services/jobs";
import { AsyncCallType, AsyncStatus } from "app/store/async";
import * as RequestCurrentStore from "app/store/requests/current";
import * as RequestListStore from "app/store/requests/list";
import * as TemplateCurrentStore from "app/store/templates/current";
import * as TenantConfigurationCurrentStore from "app/store/tenant-configuration/current";
import * as UserNotificationListStore from "app/store/user-notifications/list";
import * as React from "react";
import { Col, Collapse, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import RequestDetailsApproval from "./request-details-approval";
import RequestDetailsChannels from "./request-details-channels";
import RequestDetailsDocumentTemplates from "./request-details-document-templates";
import RequestDetailsFeatures from "./request-details-features";
import RequestDetailsHeader from "./request-details-header";
import RequestDetailsHistory from "./request-details-history";
import RequestDetailsLifecycle from "./request-details-lifecycle";
import RequestDetailsMetadata from "./request-details-metadata";
import RequestDetailsStepProgress from "./request-details-step-progress";
import RequestDetailsTemplateBaseConfig from "./request-details-template-base-config";
import RequestDetailsTemplateGroupConfig from "./request-details-template-group-config";
import RequestDetailsTemplatePermissionsConfig from "./request-details-template-permissions-config";
import RequestDetailsTemplateSPConfig from "./request-details-template-sp-config";
import RequestDetailsTemplateTeamConfig from "./request-details-template-team-config";
import RequestDetailsUsers from "./request-details-users";
import "./request-details.scss";
import { SkeletonLoaderFakeBox, SkeletonLoaderFakeUserItem, SkeletonLoaderPortlet } from "app/components/common/skeleton-loaders";
import { OrchRole } from "app/store/orchestry-context/orch-role-type";
import { useCurrentUserRoles } from "appv2/hooks/use-current-user-roles.hook";
var requestRefreshInterval = undefined;
var RequestDetails = React.memo(function (props) {
    //console.log("Request Param Guid", props.match.params.guid);
    var intl = useIntl();
    var dispatch = useDispatch();
    var currentRequest = useSelector(function (state) { return state.requestCurrentState.item; });
    var currentTemplate = useSelector(function (state) { return state.templateCurrentState.item; });
    var currentUserHasRole = useCurrentUserRoles().currentUserHasRole;
    var _a = React.useState(), ownersCount = _a[0], setOwnersCount = _a[1];
    var _b = React.useState(), membersCount = _b[0], setMembersCount = _b[1];
    var _c = React.useState(), channelsCount = _c[0], setChannelsCount = _c[1];
    var _d = React.useState([]), finishedLog = _d[0], setFinishedLog = _d[1];
    var _e = React.useState(false), editMode = _e[0], setEditMode = _e[1];
    React.useEffect(function () {
        dispatch(TemplateCurrentStore.Clear());
        return function () {
            dispatch(RequestCurrentStore.Clear());
            dispatch(TemplateCurrentStore.Clear());
        };
    }, []);
    React.useEffect(function () {
        // Clear the request
        dispatch(RequestCurrentStore.Clear());
        dispatch(TemplateCurrentStore.Clear());
        dispatch(TenantConfigurationCurrentStore.Load());
        if (props.match.params.guid === undefined) {
            //   setErrorMessage("Cannot find request ID");
        }
        else {
            // Load the /view/guid load.
            console.log("Guid to load", props.match.params.guid, currentRequest.GUID);
            // if(currentRequest.GUID === undefined){
            dispatch(RequestCurrentStore.Load({ GUID: props.match.params.guid }));
            // }
        }
    }, [props.match.params.guid, props.match.params.action]);
    React.useEffect(function () {
        dispatch(UserNotificationListStore.Refresh());
        if (currentRequest.Template !== undefined && currentRequest.Template !== null) {
            if (currentRequest.Template.GUID !== undefined && currentTemplate.GUID === undefined) {
                dispatch(TemplateCurrentStore.Load({ GUID: currentRequest.Template.GUID }));
            }
        }
    }, [currentRequest.Template]);
    React.useEffect(function () {
        if (currentRequest.Status === "ReadyToProvision") {
            if (requestRefreshInterval === undefined) {
                console.log("ReadyToProvision waiting");
                requestRefreshInterval = setTimeout(function () {
                    dispatch(RequestCurrentStore.Load({ GUID: props.match.params.guid }));
                    requestRefreshInterval = undefined;
                }, 2000);
            }
            // setTimeout(() => {
            //    dispatch(RequestCurrentStore.Load({ GUID: props.match.params.guid }));
            // }, 2000)
        }
        else {
            requestRefreshInterval = undefined;
        }
    }, [currentRequest]);
    var onProvisioningComplete = function () {
        dispatch(RequestCurrentStore.Load({ GUID: props.match.params.guid }));
        dispatch(UserNotificationListStore.Refresh());
    };
    var getLog = function () {
        if (currentRequest.GUID !== undefined) {
            JobService.GetCompleteProgressLogsFromRequest(currentRequest.GUID).then(function (logs) {
                logs = logs.map(function (l) {
                    var status = "";
                    if (l.Status === 0) {
                        status = "Start";
                    }
                    if (l.Status === 1) {
                        status = "Complete";
                    }
                    if (l.Status === 2) {
                        status = "Message";
                    }
                    if (l.Status === 3) {
                        status = "Warning";
                    }
                    if (l.Status === 4) {
                        status = "Error";
                    }
                    return __assign(__assign({}, l), { StatusType: status });
                });
                setFinishedLog(logs);
            });
        }
    };
    var saveAndAddModifyRequest = function () {
        var asyncCallType = AsyncCallType.Update;
        dispatch(RequestListStore.UpdateItem(currentRequest));
        setEditMode(false);
        AsyncWait(undefined, AsyncStatus.Succeeded, currentRequest.GUID).then(function () {
            console.log("Reload Request");
            dispatch(RequestCurrentStore.Load({ GUID: currentRequest.GUID }));
        });
    };
    var cancelEdit = function () {
        setEditMode(false);
        dispatch(RequestCurrentStore.Clear());
        dispatch(RequestCurrentStore.Load({ GUID: props.match.params.guid }));
    };
    var canRequestBeEdited = function () {
        if (currentRequest.Status === "ApprovalRequired" || currentRequest.Status === "Failed" || currentRequest.Status === "Rejected") {
            return true;
        }
        return false;
    };
    return (React.createElement("div", { className: "request-details fadein-500ms" + (editMode ? " edit-mode-on" : " edit-mode-off") },
        React.createElement(PageHeader.SetLeftContent, { title: intl.formatMessage({ id: "page.request_details.workspace_request_label", defaultMessage: "Workspace Requests" }), subTitle: intl.formatMessage({ id: "general.label.details", defaultMessage: "Details" }), titleLink: "/requests" }),
        React.createElement(PageHeader.SetRightContent, null,
            React.createElement(NavLink, { to: "/orchestry-home" },
                React.createElement(SubtitleButton, { title: intl.formatMessage({ id: "general.label.back", defaultMessage: "Back" }), faIcon: "arrow-left", variant: "secondary" })),
            canRequestBeEdited() ? (editMode === false ? (React.createElement(React.Fragment, null, currentUserHasRole(OrchRole.Admin) ? (React.createElement(SubtitleButton, { title: intl.formatMessage({ id: "general.label.edit", defaultMessage: "Edit" }), variant: "primary", laIcon: "edit", onClick: function () { return setEditMode(true); } })) : null)) : (React.createElement(React.Fragment, null,
                React.createElement(SubtitleButton, { title: intl.formatMessage({ id: "general.label.cancel", defaultMessage: "Cancel" }), variant: "outline-info", onClick: function () { return cancelEdit(); } }),
                React.createElement(SubtitleButton, { title: intl.formatMessage({ id: "general.label.save", defaultMessage: "Save" }), variant: "success", onClick: function () { return saveAndAddModifyRequest(); } })))) : null),
        currentRequest.Name !== undefined && currentRequest.Name !== null ? (React.createElement(React.Fragment, null,
            React.createElement(Collapse, { appear: true, in: currentRequest.ProvisioningJobId !== undefined &&
                    currentRequest.ProvisioningJobId !== null &&
                    currentRequest.ProvisioningJobId !== "" &&
                    (currentRequest.Status === "Provisioning" || currentRequest.Status === "ReadyToProvision") },
                React.createElement(Row, null,
                    React.createElement(Col, { md: 12, lg: 12 },
                        React.createElement(Portlet, null,
                            React.createElement(PortletBody, null, currentRequest.ProvisioningJobId !== undefined &&
                                currentRequest.ProvisioningJobId !== null &&
                                currentRequest.ProvisioningJobId !== "" &&
                                (currentRequest.Status === "Provisioning" || currentRequest.Status === "ReadyToProvision") ? (React.createElement(ProgressReport, { jobId: currentRequest.ProvisioningJobId, onComplete: function () { return onProvisioningComplete(); }, turnOffAfter: false })) : null))))),
            React.createElement(Row, null,
                React.createElement(Col, { lg: 4 },
                    React.createElement(Portlet, { className: "current-status" },
                        React.createElement(PortletBody, null,
                            React.createElement(RequestStatus, { request: currentRequest }),
                            currentRequest.Status === "Provisioned" || currentRequest.Status === "Failed" ? (React.createElement(DynamicModal, { button: React.createElement("div", { className: "view-log", onClick: function () { return getLog(); } },
                                    React.createElement("i", { className: "las la-terminal" }),
                                    " ",
                                    React.createElement(FormattedMessage, { id: "page.request_details.view_log_label", defaultMessage: "View Log" })), size: "lg" },
                                React.createElement(ProgressReport, { logs: finishedLog }))) : null)),
                    React.createElement(RequestDetailsStepProgress, null),
                    React.createElement(RequestDetailsApproval, { ParamAction: props.match.params.action }),
                    React.createElement(RequestDetailsHistory, null)),
                React.createElement(Col, { lg: 8 },
                    React.createElement(RequestDetailsHeader, { OwnerCount: ownersCount, MemberCount: membersCount, ChannelCount: channelsCount, EditModeOn: editMode }),
                    React.createElement(Row, null,
                        React.createElement(Col, { md: 6 },
                            React.createElement(RequestDetailsMetadata, null)),
                        React.createElement(Col, { md: 6 },
                            React.createElement(RequestDetailsUsers, { SetOwnerCount: function (count) { return setOwnersCount(count); }, SetMembersCount: function (count) { return setMembersCount(count); }, EditModeOn: editMode }))),
                    React.createElement(Row, null,
                        React.createElement(Col, { md: 6 },
                            React.createElement(RequestDetailsFeatures, null)),
                        React.createElement(Col, { md: 6 },
                            React.createElement(RequestDetailsDocumentTemplates, null))),
                    React.createElement(Row, null,
                        React.createElement(Col, { md: 6 },
                            React.createElement(RequestDetailsChannels, { SetChannelsCount: function (count) { return setChannelsCount(count); } })),
                        React.createElement(Col, { md: 6 },
                            React.createElement(RequestDetailsLifecycle, null))),
                    React.createElement("h5", null,
                        React.createElement(FormattedMessage, { id: "page.request_details.template_config.title", defaultMessage: "Template Configuration" })),
                    currentRequest.Template !== null ? (React.createElement(React.Fragment, null,
                        React.createElement(Row, null,
                            React.createElement(Col, { md: 6 },
                                React.createElement(RequestDetailsTemplateBaseConfig, null)),
                            React.createElement(Col, { md: 6 },
                                React.createElement(RequestDetailsTemplatePermissionsConfig, null))),
                        React.createElement(Row, null,
                            React.createElement(Col, { md: 6 },
                                React.createElement(RequestDetailsTemplateGroupConfig, null)),
                            React.createElement(Col, { md: 6 },
                                React.createElement(RequestDetailsTemplateSPConfig, null))),
                        React.createElement(Row, null,
                            React.createElement(Col, { md: 6 },
                                React.createElement(RequestDetailsTemplateTeamConfig, null)),
                            React.createElement(Col, { md: 6 })))) : null)))) : (React.createElement(React.Fragment, null,
            React.createElement(Row, null,
                React.createElement(Col, { lg: 4 },
                    React.createElement(SkeletonLoaderPortlet, { showHeader: false },
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 30 })),
                    React.createElement(SkeletonLoaderPortlet, { showHeader: false },
                        React.createElement("div", { style: { display: "flex" } },
                            React.createElement("div", { style: { flexGrow: 0, width: "10px", marginRight: "10px" }, className: "fake-box skeleton-loader-animated-wave" }),
                            React.createElement("div", { style: { flex: 1 } },
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20 }),
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20, marginTop: 10 }),
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20, marginTop: 10 }),
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20, marginTop: 10 })))),
                    React.createElement(SkeletonLoaderPortlet, null,
                        React.createElement(SkeletonLoaderFakeUserItem, null),
                        React.createElement(SkeletonLoaderFakeUserItem, { marginTop: 10 }),
                        React.createElement(SkeletonLoaderFakeUserItem, { marginTop: 10 }))),
                React.createElement(Col, { lg: 8 },
                    React.createElement(SkeletonLoaderPortlet, null,
                        React.createElement("div", { style: { display: "flex" } },
                            React.createElement("div", { style: { flexGrow: 0, marginRight: "20px" } },
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 80, bodyWidth: 80 })),
                            React.createElement("div", { style: { flex: 1 } },
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 30 }),
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20, marginTop: 15 }),
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20, marginTop: 10 }),
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20, marginTop: 10 }),
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20, marginTop: 10 }),
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20, marginTop: 10 }))),
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 3, marginTop: 30 }),
                        React.createElement("div", { style: { display: "flex", marginTop: "25px" } },
                            React.createElement("div", { style: { flex: 1 } },
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35 })),
                            React.createElement("div", { style: { flex: 1, marginLeft: "20px" } },
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35 })),
                            React.createElement("div", { style: { flex: 1, marginLeft: "20px" } },
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35 })),
                            React.createElement("div", { style: { flex: 1, marginLeft: "20px" } },
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35 })),
                            React.createElement("div", { style: { flex: 1, marginLeft: "20px" } },
                                React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 35 })))),
                    React.createElement(Row, null,
                        React.createElement(Col, { lg: 6 },
                            React.createElement(SkeletonLoaderPortlet, { bodyHeight: 300 })),
                        React.createElement(Col, { lg: 6 },
                            React.createElement(SkeletonLoaderPortlet, { bodyHeight: 300 }))),
                    React.createElement(Row, null,
                        React.createElement(Col, { lg: 6 },
                            React.createElement(SkeletonLoaderPortlet, { bodyHeight: 100 })),
                        React.createElement(Col, { lg: 6 },
                            React.createElement(SkeletonLoaderPortlet, { bodyHeight: 100 }))),
                    React.createElement(Row, null,
                        React.createElement(Col, { lg: 6 },
                            React.createElement(SkeletonLoaderPortlet, { bodyHeight: 100 })),
                        React.createElement(Col, { lg: 6 },
                            React.createElement(SkeletonLoaderPortlet, { bodyHeight: 100 }))),
                    React.createElement(SkeletonLoaderPortlet, { bodyHeight: 200 })))))
    // <AnimatedLoader size={"small"} text="Loading.." />
    ));
});
export default RequestDetails;
export var RenderWithLinkIfCan = function (props) {
    return (React.createElement(React.Fragment, null, (props.currentRequest.Status === "Provisioned" || props.currentRequest.Status === "Archived") && props.disabled === undefined ? (React.createElement(React.Fragment, null, props.currentRequest.RequestType === "SharePoint" ? (React.createElement("a", { href: props.currentRequest.ProvisionedSitecollectionUrl, target: "_blank", rel: "noopener noreferrer" },
        React.createElement(React.Fragment, null, props.children))) : props.currentRequest.RequestType === "VivaEngageCommunity" ? (React.createElement("div", null, props.children)) : (React.createElement(React.Fragment, null, props.currentRequest.ProvisionedGroupGuidId !== undefined ? (React.createElement(TeamLink, { teamGuid: props.currentRequest.ProvisionedGroupGuidId, openInTeamsIfPossible: props.openInTeamsIfPossible },
        React.createElement(React.Fragment, null, props.children))) : (React.createElement(React.Fragment, null, props.children)))))) : (React.createElement(React.Fragment, null, props.children))));
};
