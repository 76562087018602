import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import React from "react";
import { useIntl } from "react-intl";
import { OrchestryCombobox } from "../orchestry-combobox/orchestry-combobox.component";
import { OrchestryDatePickerInput } from "../orchestry-date-picker-input/orchestry-date-picker-input.component";
import { SelectedDate } from "./selected-date-type";
export var OrchestryDateFilter = function (_a) {
    var selectedDate = _a.selectedDate, onSelectedDateChange = _a.onSelectedDateChange, customDateRange = _a.customDateRange, onDateRangePickerChange = _a.onDateRangePickerChange, isLoading = _a.isLoading, skeletonWidth = _a.skeletonWidth;
    var intl = useIntl();
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "combobox-wrapper" },
            React.createElement(OrchestryCombobox, { withinPortal: false, placeholder: intl.formatMessage({ id: "controls.date_input.placeholder", defaultMessage: "Please select a date" }), options: [
                    {
                        value: SelectedDate["Within30Days"],
                        label: intl.formatMessage({ id: "controls.date_input.options.within_30_days", defaultMessage: "Within 30 Days" })
                    },
                    {
                        value: SelectedDate["Within60Days"],
                        label: intl.formatMessage({ id: "controls.date_input.options.within_60_days", defaultMessage: "Within 60 Days" })
                    },
                    {
                        value: SelectedDate["Within90Days"],
                        label: intl.formatMessage({ id: "controls.date_input.options.within_90_days", defaultMessage: "Within 90 Days" })
                    },
                    {
                        value: SelectedDate["Within1Year"],
                        label: intl.formatMessage({ id: "controls.date_input.options.within_1_year", defaultMessage: "Within 1 Year" })
                    },
                    { value: SelectedDate.Custom, label: intl.formatMessage({ id: "controls.date_input.options.custom", defaultMessage: "Custom" }) }
                ], iconClosed: ORCH_ICONS.chevronDown, iconOpened: ORCH_ICONS.chevronUp, defaultValue: "", canClearValue: true, variant: "selected-value", onChange: function (value) { return onSelectedDateChange(value); }, value: selectedDate !== null && selectedDate !== void 0 ? selectedDate : "", isLoading: isLoading, skeletonWidth: skeletonWidth })),
        selectedDate === SelectedDate.Custom && (React.createElement(OrchestryDatePickerInput, { isLoading: isLoading, type: "range", placeholder: intl.formatMessage({ id: "controls.date_input.range_placeholder", defaultMessage: "Select Date Range" }), value: customDateRange, onChange: function (value) { return onDateRangePickerChange(value); }, mt: 16 }))));
};
