/**
 * Enum for Requests View Type.
 */
export var RequestsViewType;
(function (RequestsViewType) {
    /**
     * All requests (that are not archived)
     */
    RequestsViewType["All"] = "all";
    /**
     * Requests that are being provisioned or ready to provision
     */
    RequestsViewType["Queue"] = "queue";
    /**
     * Current user's requests
     */
    RequestsViewType["MyRequests"] = "my-requests";
    /**
     * Requests that require approval by the current user
     */
    RequestsViewType["MyApprovals"] = "my-approvals";
    /**
     * All requests that require approval
     */
    RequestsViewType["AllApprovals"] = "all-approvals";
    /**
     * Approved requests that required approval by the current user
     */
    RequestsViewType["MyApprovedRequests"] = "my-approved-requests";
    /**
     * Requests that have been archived
     */
    RequestsViewType["Archived"] = "archived";
    /**
     * Requests that have been provisioned
     */
    RequestsViewType["Provisioned"] = "provisioned";
    /**
     * Requests that have been rejected
     */
    RequestsViewType["Rejected"] = "rejected";
})(RequestsViewType || (RequestsViewType = {}));
