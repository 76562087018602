import { Text } from "@mantine/core";
import { useUpdateRecommendationVisibilityMutation } from "appv2/api/recommendations/recommendations.api";
import { clearUpdateVisibilityRecommendationGuid } from "appv2/api/recommendations/recommendations.slice";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { AnimatePresence, LazyMotion, domAnimation, m } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Virtuoso } from "react-virtuoso";
import { RecommendationCardSkeletons } from "../recommendation-card/recommendation-card-skeleton/recommendation-card-skeletons.component";
import { RecommendationCard } from "../recommendation-card/recommendation-card.component";
import { RecommendationsNoResults } from "./recommendations-list-no-results.component";
import "./recommendations-list.scss";
var variants = {
    cardExit: { opacity: 0, height: 0, marginBottom: 0, borderTopWidth: 0, borderBottomWidth: 0, overflow: "hidden", transition: { duration: 0.5 } }
};
export var RecommendationsList = React.memo(function (_a) {
    var recommendations = _a.recommendations, badgeOnClick = _a.badgeOnClick, priorityOnClick = _a.priorityOnClick, isLoading = _a.isLoading, currentTab = _a.currentTab, searchString = _a.searchString, highlightSearchWords = _a.highlightSearchWords;
    var _b = useUpdateRecommendationVisibilityMutation({
        fixedCacheKey: "update-recommendation-visibility"
    }), updateRecommendationVisibility = _b[0], _c = _b[1], visibilityMutationLoading = _c.isLoading, resetVisibilityMutationCache = _c.reset;
    var dispatch = useDispatch();
    var updatedRecommendationGuid = useSelector(function (state) { return state.recommendationsPage.updateVisibilityRecommendationGuid; });
    var _d = useState(false), playExitAnimation = _d[0], setplayExitAnimation = _d[1];
    var isInTeams = useState(document.OrchestryIsInTeams)[0];
    useEffect(function () {
        if (visibilityMutationLoading) {
            setplayExitAnimation(true);
        }
    }, [visibilityMutationLoading]);
    return (React.createElement(LazyMotion, { features: domAnimation },
        React.createElement("div", { className: "recommendations-list" },
            React.createElement("div", { className: "list-header" },
                React.createElement("div", { className: "recommendation-label" },
                    React.createElement(Text, { fz: "14px", fw: "500", c: "text-primary.0" }, "Recommendation")),
                React.createElement("div", { className: "right-section" },
                    React.createElement("div", { className: "source-section" },
                        React.createElement(Text, { fz: "14px", fw: "500", c: "text-primary.0" }, "Source")),
                    React.createElement("div", { onClick: priorityOnClick, className: "priority-section" },
                        React.createElement(Text, { fz: "14px", fw: "500", c: "text-primary.0" }, "Priority"),
                        React.createElement("i", { className: ORCH_ICONS.sortDesc })))),
            isLoading ? (React.createElement(RecommendationCardSkeletons, null)) : recommendations && recommendations.length > 0 ? (isInTeams ? (
            //Teams does not support virtualized lists due to using a DynamicScrollbar component in a parent component.
            React.createElement(AnimatePresence, { initial: false },
                React.createElement("div", { className: "card-list" }, recommendations === null || recommendations === void 0 ? void 0 : recommendations.map(function (recommendation) { return (React.createElement(m.div, { className: "card-list-item", key: recommendation.RecommendationRecord.Id, onAnimationComplete: function () {
                        setplayExitAnimation(false);
                        resetVisibilityMutationCache();
                        dispatch(clearUpdateVisibilityRecommendationGuid());
                    }, style: { opacity: updatedRecommendationGuid === recommendation.RecommendationRecord.RecommendationGUID && visibilityMutationLoading ? 0.5 : 1 }, exit: playExitAnimation
                        ? { opacity: 0, height: 0, marginBottom: 0, borderTopWidth: 0, borderBottomWidth: 0, overflow: "hidden", transition: { duration: 0.5 } }
                        : undefined },
                    React.createElement(RecommendationCard, { currentTab: currentTab, badgeOnClick: badgeOnClick, key: recommendation.RecommendationRecord.Id, recommendation: recommendation, highlightSearchWords: highlightSearchWords, isExpandable: true }))); })))) : (React.createElement(Virtuoso, { data: recommendations, useWindowScroll: true, className: "card-list", itemContent: function (index, recommendation) { return (React.createElement(AnimatePresence, null,
                    React.createElement(m.div, { className: "card-list-item", key: recommendation.RecommendationRecord.Id, variants: variants, onAnimationComplete: function () {
                            setplayExitAnimation(false);
                            resetVisibilityMutationCache();
                            dispatch(clearUpdateVisibilityRecommendationGuid());
                        }, style: { opacity: updatedRecommendationGuid === recommendation.RecommendationRecord.RecommendationGUID && visibilityMutationLoading ? 0.5 : 1 }, exit: playExitAnimation ? "cardExit" : undefined },
                        React.createElement(RecommendationCard, { currentTab: currentTab, badgeOnClick: badgeOnClick, key: recommendation.RecommendationRecord.Id, recommendation: recommendation, highlightSearchWords: highlightSearchWords, isExpandable: true })))); } }))) : (React.createElement(RecommendationsNoResults, { searchString: searchString, currentTab: currentTab })))));
}, function (prevProps, nextProps) {
    return prevProps.highlightSearchWords === nextProps.highlightSearchWords &&
        prevProps.isLoading === nextProps.isLoading &&
        prevProps.recommendations === nextProps.recommendations &&
        prevProps.currentTab === nextProps.currentTab;
});
