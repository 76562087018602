var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { SimpleGrid } from "@mantine/core";
import React from "react";
export var OrchestryTaskCardGrid = function (_a) {
    var _b = _a.minWidth, minWidth = _b === void 0 ? 241 : _b, maxWidth = _a.maxWidth, simpleGridProps = __rest(_a, ["minWidth", "maxWidth"]);
    return (React.createElement(SimpleGrid, __assign({ style: minWidth ? { gridTemplateColumns: "repeat(auto-fit, minmax(".concat(minWidth, "px, ").concat(maxWidth ? "".concat(maxWidth, "px") : "1fr", "))") } : undefined }, simpleGridProps)));
};
