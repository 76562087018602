var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useFeatureValue } from "@growthbook/growthbook-react";
import { AsyncWait } from "app/components/common/async";
import DisableArea from "app/components/common/disable-area";
import FormImageUploadBox from "app/components/common/form-imageuploadbox";
import Picklist from "app/components/common/form-picklist";
import FormToggleControl from "app/components/common/form-toggle-control";
import FormValidationContext, { FormValidator, FormValidatorWrappingElement } from "app/components/common/form-validation";
import * as PageHeader from "app/components/common/page-header";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import { SkeletonLoaderPortlet } from "app/components/common/skeleton-loaders";
import { SubtitleButton } from "app/components/common/subtitle";
import { AsyncStatus } from "app/store/async";
import * as TenantConfigurationCurrentStore from "app/store/tenant-configuration/current";
import * as TenantCurrentStore from "app/store/tenants/current";
import * as React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "react-tagsinput/react-tagsinput.css";
import "./settings-teams-app.scss";
export var newDefaultTabConfigs = [
    {
        Name: "Workspace Requests",
        Identifier: "requests",
        Picked: true
    },
    {
        Name: "Workspace Directory",
        Identifier: "directory",
        Picked: true
    },
    {
        Name: "My Tasks",
        Identifier: "my-tasks",
        Picked: true
    }
];
export var defaultTabConfigs = [
    {
        Name: "Workspace Requests",
        Identifier: "requests",
        Picked: true
    },
    {
        Name: "Workspace Directory",
        Identifier: "directory",
        Picked: true
    }
];
var SettingsTeamsApp = React.memo(function (props) {
    var enableMyTasksFeature = useFeatureValue("OE-4205_enable_my_tasks_feature", false);
    var dispatch = useDispatch();
    var currentTenantConfiguration = useSelector(function (state) { return state.tenantConfigurationCurrentState.item; });
    var _a = React.useState({}), currentTenantConfigInternalState = _a[0], setCurrentTenantConfigInternalState = _a[1];
    var _b = React.useState([]), tabConfiguration = _b[0], setTabConfiguration = _b[1];
    var _c = React.useState(false), allowEditMetadata = _c[0], setAllowEditMetadata = _c[1];
    var _d = React.useState(enableMyTasksFeature ? true : false), formValid = _d[0], setFormValid = _d[1]; //allow to save with no changes if my tasks feature is enabled
    var _e = React.useState(false), tenantConfigLoaded = _e[0], setTenantConfigLoaded = _e[1];
    var _f = React.useState(false), enableCustomTeamsAppConfig = _f[0], setEnableCustomTeamsAppConfig = _f[1];
    var _g = React.useState(currentTenantConfiguration.AppName), appName = _g[0], setAppName = _g[1];
    var _h = React.useState(currentTenantConfiguration.AppDesc), appDesc = _h[0], setAppDesc = _h[1];
    var _j = React.useState(""), appIconOutline = _j[0], setAppIconOutline = _j[1];
    var _k = React.useState(""), appIconOutlineFileName = _k[0], setAppIconOutlineFileName = _k[1];
    var _l = React.useState(""), appIconColour = _l[0], setAppIconColour = _l[1];
    var _m = React.useState(""), appIconColourFileName = _m[0], setAppIconColourFileName = _m[1];
    var tabConfigs = enableMyTasksFeature ? newDefaultTabConfigs : defaultTabConfigs;
    // On first load, Load the data from the server.
    React.useEffect(function () {
        AsyncWait(undefined, AsyncStatus.Succeeded, undefined, undefined, "TenantConfiguration_Current_Load").then(function () {
            //console.log("tenant config loaded");
            setTenantConfigLoaded(true);
        });
        dispatch(TenantConfigurationCurrentStore.Load());
    }, []);
    React.useEffect(function () {
        if (tenantConfigLoaded) {
            var tempTabConfig_1 = [];
            if (currentTenantConfiguration !== null && currentTenantConfiguration !== undefined && currentTenantConfiguration.TeamTabsConfigJson !== undefined) {
                if (currentTenantConfiguration.TeamTabsConfigJson === "" || currentTenantConfiguration.TeamTabsConfigJson === "null" || currentTenantConfiguration.TeamTabsConfigJson === null) {
                    tempTabConfig_1 = __spreadArray([], tabConfigs, true);
                }
                else {
                    tempTabConfig_1 = JSON.parse(currentTenantConfiguration.TeamTabsConfigJson);
                    tempTabConfig_1 = tempTabConfig_1.filter(function (tabConfig) {
                        if (tabConfigs.some(function (tc) { return tc.Identifier === tabConfig.Identifier; }))
                            return true;
                        return false;
                    });
                    if (enableMyTasksFeature) {
                        //Because My Tasks tab is not in the default tab configs originally, we need to add it to the list
                        //to line up with how in the Teams app where we have the my tasks tab as the last tab if it's not in the list
                        //for clients who previously configured their tabs
                        if (tempTabConfig_1.some(function (tc) { return tc.Identifier === "my-tasks"; }) === false) {
                            tempTabConfig_1.push({
                                Name: "My Tasks",
                                Identifier: "my-tasks",
                                Picked: true
                            });
                        }
                        var tabConfigsWithoutMyTasks = tabConfigs.filter(function (tc) { return tc.Identifier !== "my-tasks"; });
                        tabConfigsWithoutMyTasks.forEach(function (dtg) {
                            if (tempTabConfig_1.some(function (tc) { return tc.Identifier === dtg.Identifier; }) === false) {
                                tempTabConfig_1.push({
                                    Name: dtg.Name,
                                    Identifier: dtg.Identifier,
                                    Picked: true
                                });
                            }
                        });
                    }
                    else {
                        tabConfigs.forEach(function (dtg) {
                            if (tempTabConfig_1.some(function (tc) { return tc.Identifier === dtg.Identifier; }) === false) {
                                tempTabConfig_1.push({
                                    Name: dtg.Name,
                                    Identifier: dtg.Identifier,
                                    Picked: true
                                });
                            }
                        });
                    }
                }
            }
            setTabConfiguration(tempTabConfig_1);
            setAllowEditMetadata(currentTenantConfiguration.AllowEditMetadata ? true : false);
            setAppName(currentTenantConfiguration.AppName ? currentTenantConfiguration.AppName : "");
            setAppDesc(currentTenantConfiguration.AppDesc ? currentTenantConfiguration.AppDesc : "");
            setAppIconColour(currentTenantConfiguration.AppIconColour ? currentTenantConfiguration.AppIconColour : "");
            setAppIconColourFileName(currentTenantConfiguration.AppIconColourFileName ? currentTenantConfiguration.AppIconColourFileName : "");
            setAppIconOutline(currentTenantConfiguration.AppIconOutline ? currentTenantConfiguration.AppIconOutline : "");
            setAppIconOutlineFileName(currentTenantConfiguration.AppIconOutlineFileName ? currentTenantConfiguration.AppIconOutlineFileName : "");
            setEnableCustomTeamsAppConfig(currentTenantConfiguration.EnableCustomTeamsAppConfig ? true : false);
            var currentTenantConfigCopy = __assign({}, currentTenantConfiguration);
            setCurrentTenantConfigInternalState(currentTenantConfigCopy);
        }
    }, [tenantConfigLoaded, tabConfigs]);
    // JP: it's a bit of a concern that this effect calls setCurrentTenantConfigInternalState and so does the one above. This could
    // lead to race condiiotns. It would be better to have a single effect that sets the state based on the currentTenantConfiguration
    // Going to leave it though as we have future plans to rewrite this component.
    React.useEffect(function () {
        var tabConfigsJson = JSON.stringify(tabConfiguration);
        setCurrentTenantConfigInternalState(function (prevState) { return (__assign(__assign({}, prevState), { AllowEditMetadata: allowEditMetadata, AppName: appName, AppDesc: appDesc, AppIconColour: appIconColour, AppIconColourFileName: appIconColourFileName, AppIconOutline: appIconOutline, AppIconOutlineFileName: appIconOutlineFileName, EnableCustomTeamsAppConfig: enableCustomTeamsAppConfig, TeamTabsConfigJson: tabConfigsJson })); });
    }, [allowEditMetadata, appName, appDesc, appIconColour, appIconOutline, appIconColourFileName, appIconOutlineFileName, enableCustomTeamsAppConfig, tabConfiguration]);
    var toggleCustomTeamsAppConfig = function (checked) {
        if (!checked) {
            setAppName("");
            setAppDesc("");
            setAppIconColour("");
            setAppIconColourFileName("");
            setAppIconOutline("");
            setAppIconOutlineFileName("");
        }
        setEnableCustomTeamsAppConfig(checked);
    };
    // On save, save to server and set save button back to non-edited.
    var save = React.useCallback(function () {
        dispatch(TenantConfigurationCurrentStore.UpdateTeamConfig(__assign({}, currentTenantConfigInternalState)));
        if (enableCustomTeamsAppConfig) {
            dispatch(TenantCurrentStore.UpdateSilent({ SkipTeamsAppInstall: true }));
        }
    }, [currentTenantConfigInternalState, enableCustomTeamsAppConfig, dispatch]);
    return (React.createElement("div", { className: "teams-app-config fadein-500ms" },
        React.createElement(PageHeader.SetRightContent, null, formValid === true ? (React.createElement(SubtitleButton, { title: "Save", variant: "success", onClick: function () {
                if (formValid)
                    save();
            } })) : (React.createElement(SubtitleButton, { title: "Save", variant: "secondary" }))),
        tenantConfigLoaded === true ? (React.createElement(React.Fragment, null, currentTenantConfigInternalState.GUID !== "" ? (React.createElement(FormValidationContext, { OnChangeIsValid: function (isValid) {
                setFormValid(isValid);
            }, UniqueIdentifier: "teams-app-context" },
            React.createElement(Row, { className: "teams-app-config" },
                React.createElement(Col, { md: 6 },
                    React.createElement(Portlet, { className: "indexing-portlet" },
                        React.createElement(PortletHeader, { title: "Teams App Configuration" }),
                        React.createElement(PortletBody, null,
                            React.createElement(Picklist, { data: tabConfiguration, onChange: function (data) { return setTabConfiguration(data); } }))),
                    React.createElement(Portlet, { className: "indexing-portlet" },
                        React.createElement(PortletHeader, { title: "Team Information Tab" }),
                        React.createElement(PortletBody, null,
                            React.createElement(FormToggleControl, { border: true, checked: allowEditMetadata, label: "Allow Metadata to be Edited?", handleChange: function (checked) { return setAllowEditMetadata(checked); } })))),
                React.createElement(Col, { md: 6 },
                    React.createElement(Portlet, null,
                        React.createElement(PortletHeader, { title: "Custom Teams App Configuration" }),
                        React.createElement(PortletBody, { className: "teams-app-customization" },
                            React.createElement("p", null, "Allows you to customize the name and logo that is displayed to your end users for the Orchestry application."),
                            React.createElement(FormToggleControl, { border: true, checked: enableCustomTeamsAppConfig, label: "Enable Custom Teams App configuration", handleChange: function (checked) { return toggleCustomTeamsAppConfig(checked); } }),
                            React.createElement(DisableArea, { isDisabled: !enableCustomTeamsAppConfig, useOverlay: true },
                                React.createElement(Form.Group, { className: "form-group", controlId: "control-app-name" },
                                    React.createElement(Form.Label, null,
                                        "App Name ",
                                        React.createElement("span", { className: "required-astrix" }, "*")),
                                    React.createElement(FormValidatorWrappingElement, { UniqueIdentifier: "app_name" },
                                        React.createElement(Form.Control, { placeholder: "App Name", autoComplete: "off", defaultValue: appName, onChange: function (event) {
                                                return setAppName(function () {
                                                    return event.target.value;
                                                });
                                            } })),
                                    React.createElement(FormValidator, { UniqueIdentifier: "app_name", FieldName: "App Name", Enabled: enableCustomTeamsAppConfig, Validators: ["string-not-empty", "string-is-more-than-2", "string-is-less-than-30"], ShowFirst: true, Value: appName })),
                                React.createElement(Form.Group, { className: "form-group", controlId: "control-app-description" },
                                    React.createElement(Form.Label, null,
                                        "App Description ",
                                        React.createElement("span", { className: "required-astrix" }, "*")),
                                    React.createElement(FormValidatorWrappingElement, { UniqueIdentifier: "app_desc" },
                                        React.createElement(Form.Control, { placeholder: "App Description", autoComplete: "off", defaultValue: appDesc, onChange: function (event) {
                                                return setAppDesc(function () {
                                                    return event.target.value;
                                                });
                                            } })),
                                    React.createElement(FormValidator, { UniqueIdentifier: "app_desc", FieldName: "App Desc", Validators: ["string-not-empty", "string-is-more-than-2", "string-is-less-than-80"], ShowFirst: true, Enabled: enableCustomTeamsAppConfig, Value: appDesc })),
                                React.createElement(Form.Group, { className: "form-group", controlId: "control-app-icon-outline" },
                                    React.createElement(Form.Label, null,
                                        "App Icon Outline ",
                                        React.createElement("span", { className: "required-astrix" }, "*")),
                                    React.createElement("div", { className: "outline-upload" },
                                        React.createElement(FormImageUploadBox, { defaultBase64Image: currentTenantConfigInternalState.AppIconOutline, 
                                            // text="Upload or drop an image here"
                                            height: 32, width: 32, dimensionsExact: true, 
                                            // disablePreview={true}
                                            allowedFiletype: "png", 
                                            // fileName={currentTenantConfigInternalState.AppIconOutlineFileName}
                                            onDrop: function (file, filename) {
                                                setAppIconOutline(file);
                                                // setAppIconOutlineFileName(filename);
                                            } }),
                                        React.createElement(FormValidator, { UniqueIdentifier: "app_icon_outline", Validators: ["string-not-empty"], ShowAsInfo: true, ShowFirst: false, Enabled: enableCustomTeamsAppConfig, ErrorMessage: "Please attach an image", Value: currentTenantConfigInternalState.AppIconOutline }),
                                        React.createElement("p", null, "Transparent png icon - 32px x 32px"))),
                                React.createElement(Form.Group, { className: "form-group", controlId: "control-app-icon-color" },
                                    React.createElement(Form.Label, null,
                                        "App Icon Colour ",
                                        React.createElement("span", { className: "required-astrix" }, "*")),
                                    React.createElement("div", { className: "colour-upload" },
                                        React.createElement(FormImageUploadBox, { defaultBase64Image: currentTenantConfigInternalState.AppIconColour, text: "Upload or drop an image here", height: 192, width: 192, dimensionsExact: true, 
                                            // disablePreview={true}
                                            allowedFiletype: "png", 
                                            // fileName={currentTenantConfigInternalState.AppIconColourFileName}
                                            onDrop: function (file, filename) {
                                                setAppIconColour(file);
                                                // setAppIconColourFileName(filename);
                                            } }),
                                        React.createElement(FormValidator, { UniqueIdentifier: "app_icon_colour", Validators: ["string-not-empty"], ShowAsInfo: true, ShowFirst: false, Enabled: enableCustomTeamsAppConfig, ErrorMessage: "Please attach an image", Value: currentTenantConfigInternalState.AppIconColour }),
                                        React.createElement("p", null, "Full colour png icon - 192px x 192px")))))))))) : null)) : (React.createElement(React.Fragment, null,
            React.createElement(Row, { className: "" },
                React.createElement(Col, { md: 6 },
                    React.createElement(SkeletonLoaderPortlet, { bodyHeight: 400 })),
                React.createElement(Col, { md: 6 },
                    React.createElement(SkeletonLoaderPortlet, { bodyHeight: 400 }))),
            React.createElement(Row, { className: "" },
                React.createElement(Col, { md: 6 },
                    React.createElement(SkeletonLoaderPortlet, { bodyHeight: 120 })))))));
});
export default SettingsTeamsApp;
