import { useFeatureValue } from "@growthbook/growthbook-react";
import { filterMenuConfig } from "_metronic/layout/LayoutInitializer";
import MenuConfig from "_metronic/layout/MenuConfig";
import * as React from "react";
import { useSelector } from "react-redux";
import MenuItem from "./MenuItem";
import MenuItemSeparator from "./MenuItemSeparator";
import MenuSection from "./MenuSection";

// export default class MenuList extends React.Component {
//   render() {
//     const { currentUrl, menuConfig, layoutConfig } = this.props;

//     return menuConfig.aside.items.map((child, index) => {
//       return (
//           <React.Fragment key={`menuList${index}`}>
//             {child.section && <MenuSection item={child} />}
//             {child.separator && <MenuItemSeparator item={child} />}
//             {child.title && (
//                 <MenuItem
//                     item={child}
//                     currentUrl={currentUrl}
//                     layoutConfig={layoutConfig}
//                 />
//             )}
//           </React.Fragment>
//       );
//     });
//   }
// }

const MenuList = React.memo(({ currentUrl, menuConfig, layoutConfig }) => {
   const [internalMenuConfig, setInternalMenuConfig] = React.useState(menuConfig);
   const orchestryContext = useSelector(state => state.orchestryContextCurrentState.item);
   const enableMyTasksFeature = useFeatureValue("OE-4205_enable_my_tasks_feature", false);

   // const [inTeams] = React.useState(document.getElementsByTagName('html')[0].classList.contains("in-teams"));

   // React.useEffect(()=>{
   //    if(inTeams === true){
   //       // layoutConfig.aside.self.minimize.default = true;
   //       //console.log("layoutConfig",layoutConfig);
   //    }
   // },[layoutConfig])

   React.useEffect(() => {
      setInternalMenuConfig(filterMenuConfig(orchestryContext, MenuConfig));
   }, [orchestryContext]);

   React.useEffect(() => {
      //console.log("MenuConfig Effect", internalMenuConfig, orchestryContext, MenuConfig);
   }, [internalMenuConfig]);

   return (
      <>
         {/* { console.log("MenuConfig Render", internalMenuConfig, orchestryContext, MenuConfig)} */}
         {internalMenuConfig.aside.items.map((child, index) => {
            if (enableMyTasksFeature) {
               if (child.title === "Home" && child.page === "") {
                  child.page = "orchestry-home";
               }
            }

            return (
               <React.Fragment key={index}>
                  {child.section && <MenuSection item={child} />}
                  {child.separator && <MenuItemSeparator item={child} />}
                  {child.title && <MenuItem item={child} currentUrl={currentUrl} layoutConfig={layoutConfig} />}
               </React.Fragment>
            );
         })}
      </>
   );
});
export default MenuList;
