/**
 * The task action type.
 */
export var TaskAction;
(function (TaskAction) {
    /**
     * The task is pending.
     */
    TaskAction["ApprovalRequired"] = "ApprovalRequired";
    /**
     * The task has been completed.
     */
    TaskAction["ReviewRequired"] = "ReviewRequired";
})(TaskAction || (TaskAction = {}));
