import { OrchRole } from "app/store/orchestry-context/orch-role-type";
import { useCurrentUserRoles } from "appv2/hooks/use-current-user-roles.hook";
import { HealthCheckPage } from "appv2/pages/beacon/health-check/health-check/health-check-page.component";
import { GenerateNewHealthCheckPage } from "appv2/pages/beacon/health-checks/genererate-new-health-check/generate-new-health-check-page.component";
import { TenantHealthChecksPage } from "appv2/pages/beacon/health-checks/tenant-health-checks.component";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { useCanSeeHealthChecks } from "./use-can-see-health-checks.hook";
export default function HealthCheckPages() {
    var currentUserHasRole = useCurrentUserRoles().currentUserHasRole;
    var canSeeHealthChecks = useCanSeeHealthChecks().canSeeHealthChecks;
    var canGenerateHealthCheck = currentUserHasRole(OrchRole.Admin) || currentUserHasRole(OrchRole.BeaconAdmin);
    return (React.createElement(Switch, null,
        canSeeHealthChecks && React.createElement(Route, { exact: true, path: "/health-checks", component: TenantHealthChecksPage }),
        canSeeHealthChecks && React.createElement(Route, { exact: true, path: "/health-checks/view/:healthCheckGuid", component: HealthCheckPage }),
        canGenerateHealthCheck && React.createElement(Route, { exact: true, path: "/health-checks/new", component: GenerateNewHealthCheckPage }),
        canGenerateHealthCheck && React.createElement(Route, { exact: true, path: "/health-checks/new", component: GenerateNewHealthCheckPage })));
}
