var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export var requestsApi = createApi({
    reducerPath: "requestsApi",
    baseQuery: fetchBaseQuery({ baseUrl: "/api/Requests" }),
    tagTypes: ["Requests"],
    endpoints: function (builder) { return ({
        getRequests: builder.query({
            query: function (request) {
                return { url: "/page", params: __assign(__assign({}, request.queryParameters), { View: request.view }) };
            },
            providesTags: ["Requests"]
        })
    }); }
});
export var useGetRequestsQuery = requestsApi.useGetRequestsQuery;
