var _a;
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { metronic } from "_metronic";
import { approvalWorkflowCurrentReducer } from "app/store/approval-workflows/current";
import { approvalWorkflowListReducer } from "app/store/approval-workflows/list";
import { asyncReducer } from "app/store/async";
import { authReducer } from "app/store/auth";
import { bulkEditReducer } from "app/store/bulk-edit/carts";
import { channelCurrentReducer } from "app/store/channels/current";
import { channelListReducer } from "app/store/channels/list";
import { directorySearchReducer } from "app/store/directory/search";
import { documentTemplateCurrentReducer } from "app/store/document-templates/current";
import { documentTemplateListReducer } from "app/store/document-templates/list";
import { featureCurrentReducer } from "app/store/features/current";
import { featureListReducer } from "app/store/features/list";
import { libraryChannelCurrentReducer } from "app/store/library-channels/current";
import { libraryChannelListReducer } from "app/store/library-channels/list";
import { libraryConfigurationCurrentReducer } from "app/store/library-configuration/current";
import { libraryDocumentTemplateCurrentReducer } from "app/store/library-document-templates/current";
import { libraryDocumentTemplateListReducer } from "app/store/library-document-templates/list";
import { libraryFeatureCurrentReducer } from "app/store/library-features/current";
import { libraryFeatureListReducer } from "app/store/library-features/list";
import { libraryTemplateCurrentReducer } from "app/store/library-templates/current";
import { libraryTemplateListReducer } from "app/store/library-templates/list";
import { lifecycleGuestReducer } from "app/store/lifecycle-guest/current";
import { lifecyclePoliciesCurrentReducer } from "app/store/lifecycle-policies/current";
import { lifecyclePoliciesListReducer } from "app/store/lifecycle-policies/list";
import { lifecyclePolicyWorkspacesCurrentReducer } from "app/store/lifecycle-policy-workspaces/current";
import { lifecycleReportReducer } from "app/store/lifecycle-report/search";
import { metadataCurrentReducer } from "app/store/metadata/current";
import { metadataListReducer } from "app/store/metadata/list";
import { Middleware } from "app/store/middleware";
import { namingPolicyCurrentReducer } from "app/store/naming-policies/current";
import { namingPolicyListReducer } from "app/store/naming-policies/list";
import { orchestryContextCurrentReducer } from "app/store/orchestry-context/current";
import { profileImageListReducer } from "app/store/profile-images/list";
import { requestCurrentReducer } from "app/store/requests/current";
import { requestListReducer } from "app/store/requests/list";
import { systemReducer } from "app/store/system/reducers";
import { teamInformationReducer } from "app/store/team-information/current";
import { templateCurrentReducer } from "app/store/templates/current";
import { templateListReducer } from "app/store/templates/list";
import { tenantConfigurationCurrentReducer } from "app/store/tenant-configuration/current";
import { tenantCurrentReducer } from "app/store/tenants/current";
import { tenantListReducer } from "app/store/tenants/list";
import { userNotificationListReducer } from "app/store/user-notifications/list";
import { userCurrentReducer } from "app/store/users/current";
import { usersListReducer } from "app/store/users/list";
import { healthChecksSettingsApi } from "appv2/api/health-checks-settings/health-checks-settings.api";
import { healthChecksApi } from "appv2/api/health-checks/health-checks.api";
import { tenantEventsApi } from "appv2/api/orchestry-admin-tools/tenant-event-browser/tenant-event-browser.api";
import { orchestryTasksApi } from "appv2/api/orchestry-tasks/orchestry-tasks.api";
import { partnerSettingsApi } from "appv2/api/partner-tools/partner-settings.api";
import { partnersApi } from "appv2/api/partner-tools/partners.api";
import { partnersSettingsApi } from "appv2/api/partners-settings/partners-settings.api";
import { recommendationSettingsApi } from "appv2/api/recommendations-settings/recommendations-settings.api";
import { recommendationsApi } from "appv2/api/recommendations/recommendations.api";
import { recommendationsSlice } from "appv2/api/recommendations/recommendations.slice";
import { requestsApi } from "appv2/api/requests/requests.api";
import { lifecycleGuestPoliciesCurrentReducer } from "./lifecycle-guest-policies/current";
import { lifecycleGuestPoliciesListReducer } from "./lifecycle-guest-policies/list";
import { directoryWebPartReducer } from "./webparts/directory-webpart.reducer";
import { workspaceInformationWebPartReducer } from "./webparts/workspace-information-webpart.reducer";
var Store = configureStore({
    middleware: function (getDefaultMiddleware) {
        return getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }).concat(recommendationsApi.middleware, healthChecksApi.middleware, healthChecksSettingsApi.middleware, recommendationSettingsApi.middleware, partnerSettingsApi.middleware, partnersSettingsApi.middleware, partnersApi.middleware, requestsApi.middleware, tenantEventsApi.middleware, orchestryTasksApi.middleware, Middleware.Dispatch);
    },
    devTools: {
        trace: true,
        traceLimit: 25
    },
    reducer: (_a = {},
        _a[recommendationSettingsApi.reducerPath] = recommendationSettingsApi.reducer,
        _a[healthChecksApi.reducerPath] = healthChecksApi.reducer,
        _a[healthChecksSettingsApi.reducerPath] = healthChecksSettingsApi.reducer,
        _a[partnerSettingsApi.reducerPath] = partnerSettingsApi.reducer,
        _a[partnersSettingsApi.reducerPath] = partnersSettingsApi.reducer,
        _a[partnersApi.reducerPath] = partnersApi.reducer,
        _a[recommendationsApi.reducerPath] = recommendationsApi.reducer,
        _a[requestsApi.reducerPath] = requestsApi.reducer,
        _a[tenantEventsApi.reducerPath] = tenantEventsApi.reducer,
        _a[orchestryTasksApi.reducerPath] = orchestryTasksApi.reducer,
        _a.recommendationsPage = recommendationsSlice.reducer,
        // Legacy reducers
        _a.authState = authReducer,
        _a.i18n = metronic.i18n.reducer,
        _a.builder = metronic.builder.reducer,
        _a.systemState = systemReducer,
        _a.asyncState = asyncReducer,
        _a.templateListState = templateListReducer,
        _a.templateCurrentState = templateCurrentReducer,
        _a.featureListState = featureListReducer,
        _a.featureCurrentState = featureCurrentReducer,
        _a.channelListState = channelListReducer,
        _a.channelCurrentState = channelCurrentReducer,
        _a.documentTemplateListState = documentTemplateListReducer,
        _a.documentTemplateCurrentState = documentTemplateCurrentReducer,
        _a.userCurrentState = userCurrentReducer,
        _a.metadataListState = metadataListReducer,
        _a.metadataCurrentState = metadataCurrentReducer,
        _a.tenantConfigurationCurrentState = tenantConfigurationCurrentReducer,
        _a.requestListState = requestListReducer,
        _a.requestCurrentState = requestCurrentReducer,
        _a.approvalWorkflowListState = approvalWorkflowListReducer,
        _a.approvalWorkflowCurrentState = approvalWorkflowCurrentReducer,
        _a.namingPolicyListState = namingPolicyListReducer,
        _a.namingPolicyCurrentState = namingPolicyCurrentReducer,
        _a.libraryTemplateListState = libraryTemplateListReducer,
        _a.libraryTemplateCurrentState = libraryTemplateCurrentReducer,
        _a.libraryFeatureListState = libraryFeatureListReducer,
        _a.libraryFeatureCurrentState = libraryFeatureCurrentReducer,
        _a.libraryChannelListState = libraryChannelListReducer,
        _a.libraryChannelCurrentState = libraryChannelCurrentReducer,
        _a.libraryDocumentTemplateListState = libraryDocumentTemplateListReducer,
        _a.libraryDocumentTemplateCurrentState = libraryDocumentTemplateCurrentReducer,
        _a.libraryConfigurationCurrentState = libraryConfigurationCurrentReducer,
        _a.lifecyclePoliciesListState = lifecyclePoliciesListReducer,
        _a.lifecyclePoliciesCurrentState = lifecyclePoliciesCurrentReducer,
        _a.orchestryContextCurrentState = orchestryContextCurrentReducer,
        _a.tenantListState = tenantListReducer,
        _a.tenantCurrentState = tenantCurrentReducer,
        _a.directorySearchState = directorySearchReducer,
        _a.userNotificationListState = userNotificationListReducer,
        _a.lifecycleReportState = lifecycleReportReducer,
        _a.lifecyclePolicyWorkspacesCurrentState = lifecyclePolicyWorkspacesCurrentReducer,
        _a.bulkEditState = bulkEditReducer,
        _a.lifecycleGuestPoliciesCurrentState = lifecycleGuestPoliciesCurrentReducer,
        _a.lifecycleGuestPoliciesListState = lifecycleGuestPoliciesListReducer,
        _a.lifecycleGuestState = lifecycleGuestReducer,
        _a.profileImageListState = profileImageListReducer,
        _a.teamInformationState = teamInformationReducer,
        _a.teamInformationWebPartState = workspaceInformationWebPartReducer,
        _a.teamDirectoryWebPartState = directoryWebPartReducer,
        _a.usersListState = usersListReducer,
        _a)
});
setupListeners(Store.dispatch);
export default Store;
