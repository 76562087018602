import { OrchRole } from "app/store/orchestry-context/orch-role-type";
import { useCurrentUserRoles } from "appv2/hooks/use-current-user-roles.hook";
export var useCanSeeHealthChecks = function () {
    var currentUserHasRole = useCurrentUserRoles().currentUserHasRole;
    var canSeeHealthChecks = currentUserHasRole(OrchRole.Admin) ||
        currentUserHasRole(OrchRole.BeaconAdmin) ||
        currentUserHasRole(OrchRole.HealthCheckViewer) ||
        currentUserHasRole(OrchRole.HealthCheckReviewer) ||
        currentUserHasRole(OrchRole.OrchestrySelfAuthenticator);
    return { canSeeHealthChecks: canSeeHealthChecks };
};
