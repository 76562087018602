/**
 * Task filter groups
 */
export var TasksFilterGroup;
(function (TasksFilterGroup) {
    /**
     * Filters grouped by task type
     */
    TasksFilterGroup["TaskType"] = "TaskType";
    /**
     * Filters grouped by task action
     */
    TasksFilterGroup["Action"] = "Action";
    /**
     * Filters grouped by created date
     */
    TasksFilterGroup["CreatedDate"] = "CreatedDate";
})(TasksFilterGroup || (TasksFilterGroup = {}));
