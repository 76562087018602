import { Group, Stack } from "@mantine/core";
import React from "react";
import { OrchestryAvatar } from "../orchestry-avatar/orchestry-avatar.component";
import { OrchestryText } from "../orchestry-text/orchestry-text.component";
export var OrchestryDataTableUserColumn = function (_a) {
    var displayName = _a.displayName, userEmail = _a.userEmail, userPhoto = _a.userPhoto, maxTextWidth = _a.maxTextWidth;
    if (!displayName) {
        return null;
    }
    return (React.createElement(Group, { gap: 12, wrap: "nowrap" },
        React.createElement(OrchestryAvatar, { src: userPhoto, userDisplayName: displayName, variant: "40px" }),
        React.createElement(Stack, { gap: 0 },
            React.createElement(OrchestryText, { w: maxTextWidth, overflowEllipsis: true, c: "var(--mantine-color-gray-7)", size: "14px", fw: 400, lh: "20px" }, displayName),
            React.createElement(OrchestryText, { w: maxTextWidth, overflowEllipsis: true, c: "text-tertiary.0", size: "12px", fw: 300, lh: "18px" }, userEmail))));
};
