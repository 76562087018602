var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useGetOrchestryTasksQuery } from "appv2/api/orchestry-tasks/orchestry-tasks.api";
import { TaskStatus } from "appv2/api/orchestry-tasks/task-status.type";
import { defaultResultOptions } from "appv2/components/orchestry-pagination/orchestry-pagination.component";
import { usePageSearch } from "appv2/hooks/use-page-search.hook";
import { generateGroupedFilterExpression } from "appv2/utils/paging.utils";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useOrchestryTasksFilters } from "./use-orchestry-tasks-fillters.hook";
export var useOrchestryTasksTab = function () {
    var _a, _b;
    var intl = useIntl();
    var _c = useOrchestryTasksFilters(), taskTypeFilters = _c.taskTypeFilters, actionFilters = _c.actionFilters, appliedFilters = _c.appliedFilters, clearAllFilters = _c.clearAllFilters, removeFilter = _c.removeFilter, onApplyFilters = _c.onApplyFilters, FILTER_GROUPS = _c.FILTER_GROUPS, FILTER_GROUP_TITLES = _c.FILTER_GROUP_TITLES, selectedCreatedDate = _c.selectedCreatedDate, createdDateDateRange = _c.createdDateDateRange, onSelectedCreatedDateChange = _c.onSelectedCreatedDateChange, onCreatedDateDateRangePickerChange = _c.onCreatedDateDateRangePickerChange, onClearActionFilters = _c.onClearActionFilters, onClearTaskTypeFilters = _c.onClearTaskTypeFilters, onClearCreatedDateFilter = _c.onClearCreatedDateFilter;
    var pageOptions = defaultResultOptions;
    var _d = useState(1), activePage = _d[0], setActivePage = _d[1];
    var _e = useState(function () { var _a; return (_a = localStorage.getItem("orchestry-tasks-resultsPerPage")) !== null && _a !== void 0 ? _a : "25"; }), resultsPerPage = _e[0], setResultsPerPage = _e[1];
    var _f = usePageSearch(setActivePage), querySearchText = _f.querySearchText, highlightSearchWords = _f.highlightSearchWords, searchTextInputValue = _f.searchTextInputValue, onSearchBoxChange = _f.onSearchBoxChange, onSearchBoxKeyDown = _f.onSearchBoxKeyDown, onSearchBoxXClicked = _f.onSearchBoxXClicked;
    var _g = useState({ type: "selectable", group: "Status", value: TaskStatus.Pending, selected: true }), taskStatusFilter = _g[0], setTaskStatusFilter = _g[1];
    var allFilters = __spreadArray(__spreadArray([], appliedFilters, true), [taskStatusFilter], false);
    var combinedFilters = generateGroupedFilterExpression(allFilters);
    var _h = useGetOrchestryTasksQuery({
        queryParameters: {
            filter: combinedFilters,
            orderBy: "CreatedDate desc",
            page: activePage,
            pageSize: Number(resultsPerPage)
        },
        searchText: querySearchText
    }), pagedOrchestryTasks = _h.data, tasksIsLoading = _h.isLoading, isFetching = _h.isFetching;
    // Retrieve Pending Tasks to get total pending tabs count
    var _j = useGetOrchestryTasksQuery({
        queryParameters: {
            filter: generateGroupedFilterExpression([{ type: "selectable", group: "Status", value: TaskStatus.Pending, selected: true }]),
            orderBy: "",
            page: 1,
            pageSize: 1
        },
        searchText: querySearchText
    }), pagedOrchestryPendingTasks = _j.data, isPendingTasksLoading = _j.isLoading;
    // Retrieve Completed Tasks to get total pending tabs count
    var _k = useGetOrchestryTasksQuery({
        queryParameters: {
            filter: generateGroupedFilterExpression([{ type: "selectable", group: "Status", value: TaskStatus.Completed, selected: true }]),
            orderBy: "",
            page: 1,
            pageSize: 1
        },
        searchText: querySearchText
    }), pagedOrchestryCompletedTasks = _k.data, isCompletedTasksLoading = _k.isLoading;
    var isLoading = tasksIsLoading || isPendingTasksLoading || isCompletedTasksLoading;
    var tasks = (_a = pagedOrchestryTasks === null || pagedOrchestryTasks === void 0 ? void 0 : pagedOrchestryTasks.Items) !== null && _a !== void 0 ? _a : [];
    var totalCount = (pagedOrchestryTasks === null || pagedOrchestryTasks === void 0 ? void 0 : pagedOrchestryTasks.TotalCount) || 0;
    var pagedTotal = (pagedOrchestryTasks === null || pagedOrchestryTasks === void 0 ? void 0 : pagedOrchestryTasks.PageTotal) || 0;
    //To do: replace with section component if logic changes
    var section = taskStatusFilter.value;
    var sections = useMemo(function () {
        return [
            {
                text: intl.formatMessage({ id: "pages.home.tabs.my_tasks.pending", defaultMessage: "Pending" }),
                value: TaskStatus.Pending,
                count: pagedOrchestryPendingTasks === null || pagedOrchestryPendingTasks === void 0 ? void 0 : pagedOrchestryPendingTasks.TotalCount
            },
            {
                text: intl.formatMessage({ id: "pages.home.tabs.my_tasks.completed", defaultMessage: "Completed" }),
                value: TaskStatus.Completed,
                count: pagedOrchestryCompletedTasks === null || pagedOrchestryCompletedTasks === void 0 ? void 0 : pagedOrchestryCompletedTasks.TotalCount
            }
        ];
    }, [intl, pagedOrchestryPendingTasks, pagedOrchestryCompletedTasks]);
    var onSectionChange = function (value) {
        setTaskStatusFilter({ type: "selectable", group: "Status", value: value !== null && value !== void 0 ? value : TaskStatus.Pending, selected: true });
        setActivePage(1);
    };
    var tasksLength = (_b = tasks === null || tasks === void 0 ? void 0 : tasks.length) !== null && _b !== void 0 ? _b : 0;
    var showAllCaughtUp = useMemo(function () { return tasksLength === 0 && !isLoading && !isFetching && appliedFilters.length === 0 && searchTextInputValue === "" && section === TaskStatus.Pending; }, [
        tasksLength,
        isLoading,
        isFetching,
        appliedFilters,
        searchTextInputValue,
        section
    ]);
    var showNoRecordsFound = !showAllCaughtUp && tasks.length === 0 && !isLoading && !isFetching;
    return {
        intl: intl,
        isLoading: isLoading,
        isCardsLoading: isFetching || isLoading,
        tasks: tasks,
        totalCount: totalCount,
        pagedTotal: pagedTotal,
        searchTextInputValue: searchTextInputValue,
        highlightSearchWords: highlightSearchWords,
        onSearchBoxChange: onSearchBoxChange,
        onSearchBoxKeyDown: onSearchBoxKeyDown,
        onSearchBoxXClicked: onSearchBoxXClicked,
        activePage: activePage,
        setActivePage: setActivePage,
        resultsPerPage: resultsPerPage,
        setResultsPerPage: setResultsPerPage,
        pageOptions: pageOptions,
        taskTypeFilters: taskTypeFilters,
        actionFilters: actionFilters,
        appliedFilters: appliedFilters,
        clearAllFilters: clearAllFilters,
        removeFilter: removeFilter,
        onApplyFilters: onApplyFilters,
        FILTER_GROUPS: FILTER_GROUPS,
        FILTER_GROUP_TITLES: FILTER_GROUP_TITLES,
        selectedCreatedDate: selectedCreatedDate,
        createdDateDateRange: createdDateDateRange,
        onSelectedCreatedDateChange: onSelectedCreatedDateChange,
        onCreatedDateDateRangePickerChange: onCreatedDateDateRangePickerChange,
        onClearActionFilters: onClearActionFilters,
        onClearTaskTypeFilters: onClearTaskTypeFilters,
        onClearCreatedDateFilter: onClearCreatedDateFilter,
        sections: sections,
        section: section,
        onSectionChange: onSectionChange,
        showAllCaughtUp: showAllCaughtUp,
        showNoRecordsFound: showNoRecordsFound
    };
};
