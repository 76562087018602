import { Skeleton } from "@mantine/core";
import ORCH_SVG_ICONS from "appv2/assets/icons/orchestry-svgs/svg-icons";
import React from "react";
export var OrchestryGreenCheckIcon = function (_a) {
    var size = _a.size, isLoading = _a.isLoading;
    if (isLoading) {
        return React.createElement(Skeleton, { circle: true, height: size, width: size });
    }
    return React.createElement("img", { className: "icon", style: { cursor: "default" }, src: ORCH_SVG_ICONS.CheckIcon, height: size, width: size, alt: "Check Icon" });
};
