import { RequestStatus } from "app/store/requests";
import MICROSOFT_SVG_ICONS from "appv2/assets/icons/microsoft-svgs/microsoft-svg-icons";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { useMemo } from "react";
import { useIntl } from "react-intl";
/**
 * We don't have filters yet, but I am future proofing by still following the filter logic for adding the
 * status filters and using them in the table mappnig
 */
export var useAllRequestsFilters = function () {
    var intl = useIntl();
    var ALL_REQUESTS_TABLE_STATUS_FILTERS_LIST = useMemo(function () { return [
        {
            type: "selectable",
            title: intl.formatMessage({ id: "requests.status.approval_required", defaultMessage: "Approval Required" }),
            value: RequestStatus.ApprovalRequired,
            group: intl.formatMessage({ id: "requests.status", defaultMessage: "Status" }),
            selected: false,
            icon: ORCH_ICONS.timer,
            color: "var(--mantine-color-orange-7)"
        },
        {
            type: "selectable",
            title: intl.formatMessage({ id: "requests.status.rejected", defaultMessage: "Rejected" }),
            value: RequestStatus.Rejected,
            group: intl.formatMessage({ id: "requests.status", defaultMessage: "Status" }),
            selected: false,
            icon: ORCH_ICONS.xClose,
            color: "var(--mantine-color-warning-7)"
        },
        {
            type: "selectable",
            title: intl.formatMessage({ id: "requests.status.provisioned", defaultMessage: "Provisioned" }),
            value: RequestStatus.Provisioned,
            group: intl.formatMessage({ id: "requests.status", defaultMessage: "Status" }),
            selected: false,
            icon: ORCH_ICONS.check,
            color: "var(--mantine-color-success-7)"
        },
        {
            type: "selectable",
            title: intl.formatMessage({ id: "requests.status.archived", defaultMessage: "Archived" }),
            value: RequestStatus.Archived,
            group: intl.formatMessage({ id: "requests.status", defaultMessage: "Status" }),
            selected: false,
            icon: ORCH_ICONS.archive,
            color: "var(--mantine-color-blue-7)"
        },
        {
            type: "selectable",
            title: intl.formatMessage({ id: "requests.status.failed", defaultMessage: "Failed" }),
            value: RequestStatus.Failed,
            group: intl.formatMessage({ id: "requests.status", defaultMessage: "Status" }),
            selected: false,
            icon: ORCH_ICONS.alertTriangle,
            color: "var(--mantine-color-error-7)"
        },
        {
            type: "selectable",
            title: intl.formatMessage({ id: "requests.status.retry", defaultMessage: "Retry" }),
            value: RequestStatus.Retry,
            group: intl.formatMessage({ id: "requests.status", defaultMessage: "Status" }),
            selected: false,
            icon: ORCH_ICONS.refreshCcw,
            color: "var(--mantine-color-gray-9)"
        },
        {
            type: "selectable",
            title: intl.formatMessage({ id: "requests.status.provisioning", defaultMessage: "Provisioning" }),
            value: RequestStatus.Provisioning,
            group: intl.formatMessage({ id: "requests.status", defaultMessage: "Status" }),
            selected: false,
            icon: ORCH_ICONS.loading02,
            color: "var(--mantine-color-indigo-7)"
        },
        {
            type: "selectable",
            title: intl.formatMessage({ id: "requests.status.ready_to_provision", defaultMessage: "Ready to Provision" }),
            value: RequestStatus.ReadyToProvision,
            group: intl.formatMessage({ id: "requests.status", defaultMessage: "Status" }),
            selected: false,
            icon: ORCH_ICONS.checkCircle,
            color: "var(--mantine-color-brown-7)"
        },
        {
            type: "selectable",
            title: intl.formatMessage({ id: "requests.status.created", defaultMessage: "Created" }),
            value: RequestStatus.Created,
            group: intl.formatMessage({ id: "requests.status", defaultMessage: "Status" }),
            selected: false,
            icon: ORCH_ICONS.filePlus01,
            color: "var(--mantine-color-purple-7)"
        },
        {
            type: "selectable",
            title: intl.formatMessage({ id: "requests.status.approved", defaultMessage: "Approved" }),
            value: RequestStatus.Approved,
            group: intl.formatMessage({ id: "requests.status", defaultMessage: "Status" }),
            selected: false,
            icon: ORCH_ICONS.thumbsUp,
            color: "var(--mantine-color-success-7)"
        }
    ]; }, [intl]);
    var ALL_REQUESTS_TABLE_TYPE_FILTER_LIST = useMemo(function () { return [
        {
            type: "selectable",
            title: intl.formatMessage({ id: "microsoft.types.team_site", defaultMessage: "Team Site" }),
            value: "TeamSite",
            group: "Product",
            svgIcon: { iconNeutral: MICROSOFT_SVG_ICONS.SharepointGray, iconSelected: MICROSOFT_SVG_ICONS.Sharepoint },
            selected: false,
            color: "var(--sharepoint-text)"
        },
        {
            type: "selectable",
            title: intl.formatMessage({ id: "microsoft.types.communication_site", defaultMessage: "Communication Site" }),
            value: "CommunicationSite",
            group: "Product",
            svgIcon: { iconNeutral: MICROSOFT_SVG_ICONS.SharepointGray, iconSelected: MICROSOFT_SVG_ICONS.Sharepoint },
            selected: false,
            color: "var(--sharepoint-text)"
        },
        {
            type: "selectable",
            title: intl.formatMessage({ id: "microsoft.types.team", defaultMessage: "Team" }),
            value: "Team",
            group: "Product",
            svgIcon: { iconNeutral: MICROSOFT_SVG_ICONS.TeamsGray, iconSelected: MICROSOFT_SVG_ICONS.Teams },
            selected: false,
            color: "var(--teams-text)"
        },
        {
            type: "selectable",
            title: intl.formatMessage({ id: "microsoft.types.viva_engage", defaultMessage: "Viva Engage" }),
            value: "VivaEngageCommunity",
            group: "Product",
            svgIcon: { iconNeutral: MICROSOFT_SVG_ICONS.VivaEngageGray, iconSelected: MICROSOFT_SVG_ICONS.VivaEngage },
            selected: false,
            color: "var(--viva-engage-text)"
        }
    ]; }, [intl]);
    return { ALL_REQUESTS_TABLE_STATUS_FILTERS_LIST: ALL_REQUESTS_TABLE_STATUS_FILTERS_LIST, ALL_REQUESTS_TABLE_TYPE_FILTER_LIST: ALL_REQUESTS_TABLE_TYPE_FILTER_LIST };
};
